import { Button, Container } from "react-bootstrap";
import { auth } from "../../firebase";

const OrganisationDeactivatedMsgPage = () => {
  return (
    <Container className="p-5 d-flex flex-column align-items-center">
      <h1 className="text-center">Organisation Error</h1>
      <p className="text-center">Your gym organisation is currently deactivated.</p>
      <Button
        onClick={async () => {
          await auth.signOut();
          window.location.href = "/login";
        }}
      >
        Log Out
      </Button>
    </Container>
  );
};

export default OrganisationDeactivatedMsgPage;
