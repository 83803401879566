import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ModalTitle from "react-bootstrap/ModalTitle";
import ConfirmationModal from "../../modal/ConfirmationModal";

const ProgramModal = (props) => {
  const { show, info, handleCancel, handleSubmit, submitLabel, handleDelete, handleEdit, children, size, cancelLabel } =
    props;
  const [showDelete, setShowDelete] = useState(false);

  return (
    <Modal show={show} onHide={handleCancel} dialogClassName={size} scrollable={true}>
      <ConfirmationModal
        show={showDelete}
        title="Confirm Training Program Delete"
        body="This action cannot be reversed and will delete this training program from database."
        handleCancel={() => setShowDelete(false)}
        handleConfirmation={() => {
          handleDelete();
          setShowDelete(false);
        }}
      />
      <Modal.Header closeButton style={{ padding: "4px 10px" }}>
        <ModalTitle as={"h5"}>Program Summary</ModalTitle>
      </Modal.Header>
      {info && (
        <>
          <div className="program-info">
            <span className="label">Name:</span>
            <span className="info">{info.name} </span>
            {info.goal && (
              <>
                <span className="label">Goal:</span>
                <span className="info">{info.goal} </span>
              </>
            )}
            {info.level && (
              <>
                <span className="label">Level:</span>
                <span className="info">{info.level} </span>
              </>
            )}
            <span className="label">Duration:</span>
            <span className="info">{info.duration} Microcycles</span>
            <span className="label">Sessions:</span>
            <span className="info">{info.sessions} per micro</span>
            <span className="label">Created by:</span>
            <span className="info">{info.createdBy}</span>
          </div>
          <hr />
        </>
      )}
      {children}
      <Modal.Footer className="modal-footer">
        {handleSubmit !== undefined && (
          <Button className="modal-btn" onClick={handleSubmit}>
            {submitLabel}
          </Button>
        )}
        {handleEdit !== undefined && handleEdit}
        {handleDelete !== undefined && (
          <Button className="modal-btn" onClick={() => setShowDelete(true)}>
            DELETE PLAN
          </Button>
        )}
        {handleCancel !== undefined && (
          <Button className="modal-btn" onClick={handleCancel}>
            {cancelLabel}
          </Button>
        )}{" "}
      </Modal.Footer>
    </Modal>
  );
};

export default ProgramModal;
