import { toast } from "react-toastify";

import config from "../config";
import { auth } from "../firebase";

const request = async (method, path, body, showLoading, isAuthorization) => {
    const url = config.apiUrl + path;

    const headers = {
        "Content-Type": "application/json",
    };

    const settings = {
        method: method,
        headers: headers,
    };

    if (method !== "GET") {
        settings.body = JSON.stringify(body);
    }

    showLoading = showLoading ?? true;
    let currentToast = null;
    if (showLoading) {
        currentToast = toast.loading("Loading..");
    }

    isAuthorization = isAuthorization ?? true;

    if (isAuthorization) {
        await auth.currentUser
            .getIdToken(true)
            .then((token) => (settings.headers["Authorization"] = `Bearer ${token}`))
            .catch((err) => {
                // Rare case if firebase is down
                console.log("There was a problem talking to firebase");
                window.location.href = "/login"; // Redirect back to login page
                return;
            });
    }

    return fetch(url, settings)
        .then(async (response) => {
            const results = await response.json();

            if (!response.ok) {
                throw results;
            }

            if (method === "GET") {
                toast.dismiss(currentToast);
            } else {
                toast.update(currentToast, {
                    render: "Success",
                    type: "success",
                    isLoading: false,
                    autoClose: 1000,
                    hideProgressBar: true,
                });
            }

            return results;
        })
        .catch(async (err) => {
            if (showLoading) {
                toast.update(currentToast, {
                    render: err.message || "Unknown Error",
                    type: "error",
                    isLoading: false,
                    autoClose: 1000,
                    hideProgressBar: true,
                });
            }

            if (err.statusCode) {
                if (err.statusCode === 401) {
                    await auth.signOut(); // Incase they're signed in to prevent infinite loop
                    window.location.href = "/login";
                }
            }

            throw err;
        });
};

export const get = (path, showLoading, isAuthorization) => {
    return request("GET", path, {}, showLoading, isAuthorization);
};

export const create = (path, body, showLoading, isAuthorization) => {
    return request("POST", path, body, showLoading, isAuthorization);
};

export const update = (path, body, showLoading, isAuthorization) => {
    return request("PUT", path, body, showLoading, isAuthorization);
};

export const remove = (path, body, showLoading, isAuthorization) => {
    return request("DELETE", path, body, showLoading, isAuthorization);
};
