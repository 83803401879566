import React, { useState } from "react";
import { useParams } from "react-router";
import { questionnaireSchema } from "../../../schemas/schemas";
import { get, update } from "../../../utilities/api";
import FormModal from "../../modal/FormModal";
import { Button } from "react-bootstrap";

const Questionnaire = () => {
  const { user_id, training_plan_id, microcycle_id } = useParams();
  const [showQuestionnaireForm, setShowQuestionnaireForm] = useState(false);
  const [questionnaireFormSchema, setQuestionnaireFormSchema] = useState([]);

  const handleModalCancel = () => {
    setShowQuestionnaireForm(false);
  };

  const questionnaireFormDisplayHandler = () => {
    get(`/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}`, false)
      .then((res) => {
        setQuestionnaireFormSchema(questionnaireSchema(res.questionnaire));
        setShowQuestionnaireForm(true);
      })
      .catch((err) => {
        console.log("There was a problem getting questionnaire", err);
      });
  };

  const handleQuestionnaireSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const questions = [];
    Object.values(values).forEach((val) => {
      questions.push(val);
    });

    update(`/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}`, { questionnaire: questions })
      .then(() => {
        setShowQuestionnaireForm(false);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <FormModal
        formInputsSchema={questionnaireFormSchema}
        show={showQuestionnaireForm}
        title="Questionnaire"
        submitLabel="Confirm"
        handleCancel={handleModalCancel}
        handleSubmit={handleQuestionnaireSubmit}
      />
      <Button
        variant="secondary"
        className="microcycle-header-group header-btn-col"
        onClick={() => questionnaireFormDisplayHandler()}>
        Questionnaire
      </Button>
    </>
  );
};

export default Questionnaire;
