import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from "react-bootstrap";
import NameCard from "./NameCard";
import PlanHeader from "./PlanHeader";
import useGetFetch from "../../utilities/useFetch";
import { create, get, update, remove } from "../../utilities/api";
import { addMicroFormSchema, addPhaseFormSchema, editPhaseFormSchema } from "../../schemas/schemas";
import FormModal from "../modal/FormModal";
import { Link } from "react-router-dom";
import { DashLg, PlusLg } from "react-bootstrap-icons";
import ConfirmationModal from "../modal/ConfirmationModal";
import TableRow from "./TableRow";
import DynamicSelectForm from "../dynamic-select-form/DynamicSelectForm";
import SavePlanForm from "./SavePlanForm";
import { useDispatch } from "react-redux";
import MicroSummaryTable from "../micro-summary-table/MicroSummaryTable";
import DaySummaryTable from "../training-plan/microcycle/DaySummaryTable";
import { calculateIntensity, findE1rm, findVolumnLoad } from "../../utilities/math";
import { saveActiveMicro, saveActivePhase, saveNextDay } from "../../redux/clientSlice";

const TrainingPlanBoard = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();

  const {
    data: phases,
    isPending: phasesPending,
    setRefresh: setPhasesRefresh,
  } = useGetFetch("/users/" + user_id + "/plans");
  const [activePlan, setActivePlan] = useState([]);
  const [upcomingPhases, setUpcomingPhases] = useState([]);
  const [pastPhases, setPastPhases] = React.useState([]);
  const [activeMicro, setActiveMicro] = useState();
  const [micros, setMicros] = useState();
  const [selectedPhase, setSelectedPhase] = useState();
  const [selectedMicro, setSelectedMicro] = useState();
  const [isPhaseSelected, setIsPhaseSelected] = useState(false);
  const [isMicroSelected, setIsMicroSelected] = useState(false);
  const [completedDays, setCompletedDays] = useState(0);
  const [completedMicros, setCompletedMicros] = useState(0);
  const [totalDays, SetTotalDays] = useState(0);
  const [totalCompletedDays, setTotalCompletedDays] = useState(0);
  const [showUpcomingPhases, setShowUpcomingPhases] = useState(true);
  const [showPastPhases, setShowPastPhases] = useState(false);
  const [showSavePlanForm, setShowSavePlanForm] = useState(false);
  const [showSaveMicroAsPlanForm, setShowSaveMicroAsPlanForm] = useState(false);
  const [summary, setSummary] = useState();
  const [selectDay, setSelectDay] = useState();
  const [dayHistory, setDayHistory] = useState([]);

  useEffect(() => {
    if (!phasesPending) {
      const activeData = phases.results.filter((phase) => {
        return phase.isActive === true;
      });
      const previousData = phases.results.filter((phase) => {
        return phase.isActive === false && phase.isNextActive === false;
      });
      const upcomingData = phases.results.filter((phase) => {
        return phase.isNextActive === true;
      });
      setActivePlan(activeData);
      dispatch(saveActivePhase(activeData));
      setPastPhases(previousData);
      setUpcomingPhases(upcomingData);
    }
  }, [phases, phasesPending, dispatch]);

  useEffect(() => {
    if (activePlan.length > 0) {
      const microSelect = async () => {
        try {
          let res = await get(`/users/${user_id}/plans/${activePlan[0].id}/weeks`);

          const activeData = res.results.filter((r) => r.isActive === true);
          if (activeData.length !== 0) {
            setActiveMicro(activeData);
            dispatch(saveActiveMicro(activeData));
          } else setActiveMicro();
        } catch (err) {
          console.log(err);
        }
      };
      microSelect();
    }
  }, [activePlan, user_id, dispatch]);

  useEffect(() => {
    if (activeMicro && activePlan.length > 0) {
      const microSelect = async () => {
        try {
          let res = await get(`/users/${user_id}/plans/${activePlan[0].id}/weeks/${activeMicro[0].id}/days`);
          let day;
          res.results.every((r) => {
            if (!r.systemCheckInTimestamp && !r.clientCheckInTimestamp) {
              day = r;
              return false;
            }
            return true;
          });
          dispatch(saveNextDay(day));
        } catch (err) {
          console.log(err);
        }
      };
      microSelect();
    }
  }, [activeMicro, user_id, activePlan, dispatch]);

  useEffect(() => {
    if (selectedMicro) {
      const complete = selectedMicro.results.filter((s) => {
        return s.clientCheckInTimestamp !== null || s.systemCheckInTimestamp !== null;
      });
      setCompletedDays(complete.length);
    }
  }, [selectedMicro]);

  useEffect(() => {
    setIsMicroSelected(false);
    setIsPhaseSelected(false);
    setSelectDay();
    setDayHistory([]);
  }, [user_id]);

  const showMicrocycles = async (id) => {
    setTotalCompletedDays(0);
    SetTotalDays(0);
    try {
      let res = await get(`/users/${user_id}/plans/${id}/weeks?includeDays=true&&includeDayItems=true`);
      setMicros(res.results);
      // Get microcycle complete info for phase summary
      let index;
      res.results.filter((r, i) => {
        if (r.isActive === true) index = i;
        return r.isActive === true;
      });
      if (index) setCompletedMicros(index);
      else setCompletedMicros(0);

      // Get days complete info for phase summary
      let totalDays = 0;
      let completedDays = 0;
      for (const m of res.results) {
        totalDays += m.trainingPlanDays.length;

        const complete = m.trainingPlanDays.filter((re) => {
          return re.clientCheckInTimestamp !== null || re.systemCheckInTimestamp !== null;
        });
        completedDays += complete.length;
      }
      SetTotalDays(totalDays);
      setTotalCompletedDays(completedDays);
    } catch (err) {
      console.log(err);
    }
  };

  const getMicroInfo = async (mId, title, active) => {
    try {
      let res = await get(`/users/${user_id}/plans/${selectedPhase.id}/weeks/${mId}/days`);
      setSelectedMicro({ name: title, id: mId, results: res.results, isActive: active });
      setIsPhaseSelected(false);
      setIsMicroSelected(true);
      setOptionSelected("Blank");
      setSelectDay();
      setDayHistory([]);
      let sum = await get(`/users/${user_id}/weeks/${mId}`);
      setSummary(sum);

      let element = document.querySelector(".phase-name.selected.micro");
      element && element.classList.remove("selected");
      element && element.classList.remove("micro");
      document.getElementById(mId).classList.add("selected");
      document.getElementById(mId).classList.add("micro");
      let phase = document.querySelector(".phase-name.selected.phase");
      phase && phase.classList.add("non-focus");
    } catch (err) {
      console.log(err);
    }
  };

  const showPhaseSummary = (id) => {
    if (phases) {
      const phase = phases.results.filter((r) => {
        return r.id === id;
      });
      setSelectedPhase(phase[0]);

      let element = document.querySelector(".phase-name.selected.phase");
      element && element.classList.remove("selected");
      element && element.classList.remove("phase");
      document.getElementById(id).classList.add("selected");
      document.getElementById(id).classList.add("phase");
      let micro = document.querySelector(".phase-name.selected.micro");
      micro && micro.classList.remove("selected");
      micro && micro.classList.remove("micro");
      let element1 = document.querySelector(".phase-name.selected.phase.non-focus");
      element1 && element1.classList.remove("non-focus");
    }
    setIsPhaseSelected(true);
    setIsMicroSelected(false);
    setOptionSelected("Blank");
  };

  const showUpcoming = () => {
    setShowUpcomingPhases(!showUpcomingPhases);
  };

  const showPast = () => {
    setShowPastPhases(!showPastPhases);
  };

  const [showAddPhase, setAddPhaseShow] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);
  const [showNextActive, setShowNextActive] = useState(false);
  const [showForceActive, setShowForceActive] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editPlanFormSchema, setEditPlanFormSchema] = useState([]);
  const [showAddMicro, setShowAddMicro] = useState(false);
  const [optionSelected, setOptionSelected] = useState("Blank");
  const [showDeleteMicrocycleConfirmationModal, setShowDeleteMicrocycleConfirmationModal] = useState(false);

  useEffect(() => {
    selectedPhase && setEditPlanFormSchema(editPhaseFormSchema(selectedPhase));
  }, [selectedPhase, micros, phases]);

  const addNewPlanFormHandler = () => {
    setAddPhaseShow(true);
  };

  const addNewMicroFormHandler = () => {
    setShowAddMicro(true);
  };

  const handleAddPhaseSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    create("/users/" + user_id + "/plans", values)
      .then(() => setPhasesRefresh((old) => old + 1))
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
        setAddPhaseShow(false);
      });
  };

  const handleEditPlanSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const data = {
      name: values.name,
      length: values.length,
    };

    update(`/users/${user_id}/plans/${selectedPhase.id}`, data)
      .then(() => {
        setPhasesRefresh((old) => old + 1);
        setShowEditPlan(false);
        setSelectedPhase((prev) => ({ ...prev, name: values.name, length: values.length }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDeleteSubmit = () => {
    remove(`/users/${user_id}/plans/${selectedPhase.id}`)
      .then(() => {
        setShowDelete(false);
        setPhasesRefresh((old) => old + 1);
        setSelectedPhase();
        setMicros();
        setIsMicroSelected(false);
        setIsPhaseSelected(false);
      })
      .catch((err) => console.log(err));
  };

  const handleNextActivePlan = useCallback(() => {
    update(`/users/${user_id}/plans/${selectedPhase.id}`, { isNextActive: true })
      .then(() => {
        const phase = { ...selectedPhase, isNextActive: true };
        setShowNextActive(false);
        setSelectedPhase(phase);
        setPhasesRefresh((old) => old + 1);
      })
      .catch((err) => console.log(err));
  }, [selectedPhase, user_id, setSelectedPhase, setPhasesRefresh, setShowNextActive]);

  const handleAddMicroSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    create("/users/" + user_id + "/plans/" + selectedPhase.id + "/weeks", values)
      .then(() => {
        setShowAddMicro(false);
        showMicrocycles(selectedPhase.id);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCopySubmit = async (values) => {
    create(`/users/${user_id}/plans/${selectedPhase.id}/weeks/${values.microId}/copy`)
      .then(() => {
        setShowAddMicro(false);
        showMicrocycles(selectedPhase.id);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setOptionSelected("Blank");
      });
  };

  const handleOptionsSelected = (option) => {
    setOptionSelected(option);
  };

  const findOptionsAndSchema = () => {
    if (optionSelected === "Blank") {
      return addMicroFormSchema;
    } else if (optionSelected === "Copy") {
      return;
    }
    return addMicroFormSchema;
  };

  const findOptionsAndMethods = () => {
    if (optionSelected === "Blank") {
      return handleAddMicroSubmit;
    } else if (optionSelected === "Copy") {
      return handleCopySubmit;
    }
    return handleAddMicroSubmit;
  };

  const handleForceActiveMicrocycle = () => {
    create(`/users/${user_id}/plans/${selectedPhase.id}/weeks/${selectedMicro.id}/force-active`)
      .then(() => {
        setMicros((current) => {
          current.forEach((v) => {
            if (v.id === selectedMicro.id) {
              v.isActive = true;
            }
          });
          return current;
        });
        micros.map((e) => {
          if (e.id !== selectedMicro.id) return (e.isActive = false);
          else return (e.isActive = true);
        });
        setShowForceActive(false);
        setPhasesRefresh((old) => old + 1);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteMicrocycleSubmit = () => {
    remove(`/users/${user_id}/plans/${selectedPhase.id}/weeks/${selectedMicro.id}`)
      .then(() => {
        let slicedArray = micros.filter((p) => p.id !== selectedMicro.id);
        setMicros(slicedArray);
        setIsPhaseSelected(true);
        setIsMicroSelected(false);
        setSelectedMicro(null);
        setSelectDay(null);
        let phase = document.querySelector(".phase-name.selected.phase");
        phase && phase.classList.remove("non-focus");
      })
      .catch((err) => console.log(err))
      .finally(() => setShowDeleteMicrocycleConfirmationModal(false));
  };

  const savePlanSubmit = async (values) => {
    const tags = [];
    if (values.tagsList) {
      for (const t of values.tagsList) {
        tags.push(t.label);
      }
    }
    values.level = values.level.value;
    values.goal = values.goal.value;
    values.tags = tags;
    values.trainingPlanId = selectedPhase.id;
    values.coachwiseAuto = values.auto.value;

    try {
      await create("/template-plans", values);
    } catch (err) {
      console.log(err);
    } finally {
      setShowSavePlanForm(false);
    }
  };

  const saveMicroAsPlanSubmit = async (values) => {
    const tags = [];
    if (values.tagsList) {
      for (const t of values.tagsList) {
        tags.push(t.label);
      }
    }
    values.level = values.level.value;
    values.goal = values.goal.value;
    values.tags = tags;
    values.trainingWeekId = selectedMicro.id;
    values.coachwiseAuto = values.auto.value;

    try {
      await create("/template-plans", values);
    } catch (err) {
      console.log(err);
    } finally {
      setShowSaveMicroAsPlanForm(false);
    }
  };

  const showE1rm = (workoutLogs) => {
    const result = findE1rm(workoutLogs);
    if (result && result !== "NA") return <div className="e1rm-vl">E1RM: {result}</div>;
  };

  const showVL = (workoutLogs) => {
    const result = findVolumnLoad(workoutLogs);
    if (result && result !== "NA")
      return (
        <div className="e1rm-vl">
          Volume Load: {result} {calculateIntensity(workoutLogs)}
        </div>
      );
  };

  const getHistory = async (day) => {
    const results = [];
    let history = await get(`/users/${user_id}/plans/${selectedPhase.id}/weeks/${selectedMicro.id}/days/${day.id}`);
    for (const d of history.exercises) {
      if (d.exerciseProperties && d.exerciseProperties.isMultiExercise) {
        if (d.exerciseProperties.clientMultiSelectedExerciseId) {
          results.push({
            exerciseName: d.exerciseProperties.clientMultiSelectedExercise.exerciseName,
            workoutLogs: d.workoutLogs,
            comment: d.clientComment,
          });
        } else {
          results.push({
            exerciseName: d.exerciseProperties.itemName,
            workoutLogs: d.workoutLogs,
            comment: d.clientComment,
          });
        }
      } else {
        results.push({
          exerciseName: d.exerciseProperties?.exercise.exerciseName,
          workoutLogs: d.workoutLogs,
          comment: d.clientComment,
        });
      }
    }
    setDayHistory(results);
  };

  return (
    <div className="my-2 ctn">
      <FormModal
        formInputsSchema={addPhaseFormSchema}
        show={showAddPhase}
        title="Add New Phase"
        submitLabel="Confirm"
        handleCancel={() => setAddPhaseShow(false)}
        handleSubmit={handleAddPhaseSubmit}
      />
      <FormModal
        formInputsSchema={editPlanFormSchema}
        show={showEditPlan}
        title="Edit Phase"
        submitLabel="Confirm"
        handleCancel={() => setShowEditPlan(false)}
        handleSubmit={handleEditPlanSubmit}
      />
      <FormModal
        formInputsSchema={findOptionsAndSchema()}
        show={showAddMicro}
        title="Add New Microcycle"
        submitLabel="Confirm"
        handleCancel={() => setShowAddMicro(false)}
        handleSubmit={findOptionsAndMethods()}
        options={{ default: optionSelected, options: ["Blank", "Copy"] }}
        handleOptionsSelected={handleOptionsSelected}
        body={
          optionSelected === "Copy" && (
            <DynamicSelectForm
              phases={phases}
              handleCancel={() => setShowAddMicro(false)}
              handleSubmit={findOptionsAndMethods()}
              currentPlan={selectedPhase}
              micros={micros.length > 0 && micros.slice().reverse()}
            />
          )
        }
      />
      <FormModal
        show={showSavePlanForm}
        title="Save Training Plan"
        submitLabel="Confirm"
        handleCancel={() => setShowSavePlanForm(false)}
        handleSubmit={savePlanSubmit}
        body={<SavePlanForm submit={savePlanSubmit} handleCancel={() => setShowSavePlanForm(false)} submitLabel="Submit" />}
      />
      <FormModal
        show={showSaveMicroAsPlanForm}
        title="Save Training Plan"
        submitLabel="Confirm"
        handleCancel={() => setShowSaveMicroAsPlanForm(false)}
        handleSubmit={saveMicroAsPlanSubmit}
        body={
          <SavePlanForm
            submit={saveMicroAsPlanSubmit}
            handleCancel={() => setShowSaveMicroAsPlanForm(false)}
            submitLabel="Submit"
          />
        }
      />
      <ConfirmationModal
        show={showNextActive}
        title={`Set '${selectedPhase?.name ?? ""}' as the next active plan?`}
        body={`This will set '${
          selectedPhase?.name ?? ""
        }' as the next active plan. Your client will automatically move to this plan when they're done with the current plan or does not have any active plan. This will override the current next active plan if there's one currently set.`}
        handleCancel={() => setShowNextActive(false)}
        handleConfirmation={handleNextActivePlan}
      />
      <ConfirmationModal
        show={showForceActive}
        title={`Set 'Microcycle ${selectedMicro?.name ?? ""}' as active`}
        body={`This will set 'Microcycle ${
          selectedMicro?.name ?? ""
        }' as the active microcycle. This will force this plan to become active if it's not`}
        handleCancel={() => setShowForceActive(false)}
        handleConfirmation={handleForceActiveMicrocycle}
      />
      <ConfirmationModal
        show={showDelete}
        title="Confirm Training Phase Delete"
        body="This action cannot be reversed and will delete any training history for the client for this period."
        handleCancel={() => setShowDelete(false)}
        handleConfirmation={handleDeleteSubmit}
      />
      <ConfirmationModal
        show={showDeleteMicrocycleConfirmationModal}
        title="Confirm Microcycle Delete"
        body="This action cannot be reversed and will delete any training history for the client for this period."
        handleCancel={() => {
          setShowDeleteMicrocycleConfirmationModal(false);
        }}
        handleConfirmation={handleDeleteMicrocycleSubmit}
      />
      <div style={{ fontSize: 26, fontWeight: "bold" }}>TRAINING PLAN</div>
      <div className="main-container">
        <div className="main-column">
          <PlanHeader title={"phases"} btn={"add phase"} btnFunction={addNewPlanFormHandler} />
          <div className="phases">
            <div className="phase-header">Active</div>
            {!phasesPending &&
              activePlan.map((ac) => {
                return (
                  <NameCard
                    key={ac.id}
                    title={ac.name}
                    showMicrocycles={showMicrocycles}
                    id={ac.id}
                    showPhaseSummary={showPhaseSummary}
                  />
                );
              })}
          </div>

          <div className="phases">
            <div className="phase-header">
              <div>Next Phase</div>
              {showUpcomingPhases && <DashLg style={{ cursor: "pointer" }} onClick={showUpcoming} />}
              {!showUpcomingPhases && <PlusLg style={{ cursor: "pointer" }} onClick={showUpcoming} />}
            </div>
            {showUpcomingPhases &&
              !phasesPending &&
              upcomingPhases.map((up) => {
                return (
                  <NameCard
                    key={up.id}
                    title={up.name}
                    showMicrocycles={showMicrocycles}
                    id={up.id}
                    showPhaseSummary={showPhaseSummary}
                  />
                );
              })}
          </div>

          <div className="phases">
            <div className="phase-header">
              Other
              {showPastPhases && <DashLg style={{ cursor: "pointer" }} onClick={showPast} />}
              {!showPastPhases && <PlusLg style={{ cursor: "pointer" }} onClick={showPast} />}
            </div>
            {showPastPhases &&
              !phasesPending &&
              pastPhases.map((past) => {
                return (
                  <NameCard
                    key={past.id}
                    title={past.name}
                    showMicrocycles={showMicrocycles}
                    id={past.id}
                    showPhaseSummary={showPhaseSummary}
                  />
                );
              })}
          </div>
        </div>

        {(isPhaseSelected || isMicroSelected) && (
          <div className="main-column">
            <PlanHeader title={"microcycles"} btn={"add micro"} btnFunction={addNewMicroFormHandler} />
            <div className="phases">
              <div className="phases-scroll">
                {micros &&
                  micros
                    .slice()
                    .reverse()
                    .map((m, i) => {
                      return (
                        <NameCard
                          key={m.id}
                          title={`Microcycle ${m.position}`}
                          active={m.isActive}
                          getMicroInfo={getMicroInfo}
                          id={m.id}
                        />
                      );
                    })}
              </div>
            </div>
          </div>
        )}

        {isPhaseSelected && !isMicroSelected && selectedPhase && micros && (
          <div className="main-column">
            <PlanHeader
              title="summary"
              btn={"save phase to database"}
              btnFunction={() => {
                setShowSavePlanForm(true);
              }}
            />
            <div className="summary  phases-scroll">
              <table>
                <tbody>
                  <TableRow name="Name:" content={selectedPhase.name} />
                  <TableRow name="Microcycle:" content={` ${completedMicros}/${micros.length} Completed`} />
                  <TableRow name="Days:" content={`${totalCompletedDays}/${totalDays} Completed`} />
                  <TableRow name="Next Active?" content={`${selectedPhase.isNextActive ? "Yes" : "No"}`} />
                </tbody>
              </table>

              <div className="phase-info">Phase Information</div>
              <table>
                <tbody>
                  <TableRow name="Goal:" content="" />
                  <TableRow name="Level:" content="" />
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                {!selectedPhase.isActive && !selectedPhase.isNextActive && (
                  <Button className="edit-phase-btn" onClick={() => setShowNextActive(true)}>
                    Set as next active
                  </Button>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                <Button className="edit-phase-btn" onClick={() => setShowDelete(true)}>
                  Delete Phase
                </Button>
                <Button className="edit-phase-btn" onClick={() => setShowEditPlan(true)}>
                  Edit Phase
                </Button>
              </div>
            </div>
          </div>
        )}

        {!isPhaseSelected && isMicroSelected && (
          <>
            <div className="main-column">
              <PlanHeader
                title={"summary"}
                btn={"save microcycle to database"}
                btnFunction={() => {
                  setShowSaveMicroAsPlanForm(true);
                }}
              />
              <div className="summary phases-scroll">
                <table>
                  <tbody>
                    <TableRow name="Microcycle:" content={selectedMicro.name} />
                    <TableRow name="Days:" content={`${completedDays}/${selectedMicro.results.length} Completed`} />
                  </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                  {!selectedMicro.isActive ? (
                    <Button variant="primary" className=" edit-phase-btn" onClick={() => setShowForceActive(true)}>
                      Set as Active
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  <Link
                    className="view-micro-btn"
                    to={`/client/${user_id}/training-plans/${selectedPhase.id}/microcycles/${selectedMicro.id}/${selectedMicro.name}?mesocycleName=${selectedPhase.name}&viewAll=false`}
                  >
                    View Micro
                  </Link>
                </div>
                <div style={{ position: "absolute", right: "0px", bottom: "0px" }}>
                  <Button
                    variant="primary"
                    className=" edit-phase-btn"
                    onClick={() => setShowDeleteMicrocycleConfirmationModal(true)}
                  >
                    Delete Micro
                  </Button>
                </div>
              </div>
            </div>
            <div className="summary-column">
              {summary && <MicroSummaryTable summary={summary} header={"Micro Summary"} className="summary-in-planboard " />}
            </div>
          </>
        )}
      </div>

      {!isPhaseSelected && isMicroSelected && selectedMicro && (
        <>
          <div className="day-summaries-container day-summary-board">
            <div style={{ fontWeight: "bold" }}>Prescribed Training: Microcycle {selectedMicro.name}</div>

            <div className="day-summaries round">
              {selectedMicro &&
                selectedMicro.results.map((day) => (
                  <div
                    className="day-card current-summary round untoggle"
                    key={day.id}
                    onClick={() => {
                      var elems = document.querySelector(".day-table.selected");
                      elems && elems.classList.remove("selected");
                      document.getElementById(day.id).classList.add("selected");
                      setSelectDay(day);
                      getHistory(day);
                    }}
                  >
                    {selectDay && selectDay.id === day.id ? (
                      <DaySummaryTable
                        classname="day-table selected"
                        index={day.id}
                        result={day.exercises}
                        day={day.day}
                        name={day.name}
                      />
                    ) : (
                      <DaySummaryTable
                        classname="day-table"
                        index={day.id}
                        result={day.exercises}
                        day={day.day}
                        name={day.name}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>

          {selectDay && (
            <div>
              <div style={{ fontWeight: "bold" }}>
                Completed Training: Microcycle {selectedMicro.name} - Day #{selectDay.day} {selectDay.name}
              </div>

              <div className="history-container day-summary-board">
                {dayHistory.length > 0 ? (
                  dayHistory.map((e, i) => {
                    return (
                      <table className="exercise-history-table" key={e.exerciseName + i}>
                        <div>
                          <div className="header-content" style={{ textAlign: "center" }}>
                            {e.exerciseName}
                          </div>
                        </div>
                        <div className="scroll-bar">
                          <table cellPadding="0" cellSpacing="0">
                            <thead className="history-subheader">
                              <tr>
                                <th>Set</th>
                                <th>Reps</th>
                                <th>Load</th>
                                <th>Int</th>
                              </tr>
                            </thead>
                            <tbody className="worklogs-content">
                              {e.workoutLogs &&
                                e.workoutLogs.length > 0 &&
                                e.workoutLogs.map((w, i) => {
                                  return (
                                    <tr key={e.exerciseName + i} className="worklogs-value">
                                      <td>{w.set}</td>
                                      <td>{w.rep}</td>
                                      <td>{w.weight}</td>
                                      <td>{w.intensityValue}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>

                          {(!e.workoutLogs || e.workoutLogs.length === 0) && (
                            <div className="no-training-result">No workouts recorded</div>
                          )}

                          {showE1rm(e.workoutLogs)}
                          {showVL(e.workoutLogs)}

                          <div className="comment-box">
                            {e.comment === null || e.comment === "" ? "No Comment" : e.comment}
                          </div>
                        </div>
                      </table>
                    );
                  })
                ) : (
                  <i style={{ paddingLeft: "20px", paddingTop: "60px" }}>NO HISTORY</i>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TrainingPlanBoard;
