import Switch from "@mui/material/Switch";
import { useCallback, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { get, update } from "../../utilities/api";
import DateUtil from "../../utilities/date";
import useGetFetch from "../../utilities/useFetch";
import { useDispatch } from "react-redux";
import { selectClient } from "../../redux/clientSlice";

const Dashboard = () => {
  const [searchStr, setSearchStr] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null); // This will be used to set a delay before calling the api to search

  const { data, isPending, setRefresh } = useGetFetch(`/me/assigned-clients${searchStr}`, false);
  const dispatch = useDispatch();

  const getClientCheckIn = (checkInTimestamp, coachUpdateTimestamp) => {
    if (checkInTimestamp === null) {
      return <>--</>;
    } else {
      const checkInDays = DateUtil.calculateDaysFromToday(checkInTimestamp);
      const coachUpdateDays = DateUtil.calculateDaysFromToday(coachUpdateTimestamp);

      if (checkInDays > 8 && coachUpdateDays > checkInDays) {
        return <div className="amber-text">{checkInDays} days ago</div>;
      } else if (checkInDays > 8 && coachUpdateDays < checkInDays) {
        return <div className="red-text">{checkInDays} days ago</div>;
      } else if (checkInDays < 8 && coachUpdateDays <= checkInDays) {
        return <div className="green-text">{checkInDays} days ago</div>;
      } else {
        return <>{checkInDays} days ago</>;
      }
    }
  };

  // This only gets the latest timestamp of their workout
  const getClientLogIn = (timestamp) => {
    if (timestamp === null) {
      return "--";
    } else {
      return DateUtil.calculateDaysFromToday(timestamp) + " days ago";
    }
  };

  const getCoachUpdate = (timestamp) => {
    if (timestamp === null) {
      return "--";
    } else {
      return DateUtil.calculateDaysFromToday(timestamp) + " days ago";
    }
  };

  const toggleClientActiveStatus = useCallback(
    async (id) => {
      await update(`/me/assigned-clients/${id}/active`, {}, false);
      setRefresh((current) => current + 1);
    },
    [setRefresh]
  );

  // Handle the search input, a delay will be added before calling the api
  // so it does not spam the calls
  // https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
  const handleSearch = (event) => {
    // Stop existing timer
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const keyword = event.target.value.toLowerCase();

    const timeoutFunction = setTimeout(() => {
      if (keyword !== "") {
        setSearchStr(`?name=${keyword}`);
      } else {
        setSearchStr("");
      }
    }, 500);

    setSearchTimeout(timeoutFunction);
  };

  const handleSaveClient = useCallback(
    async (id) => {
      const data = await get("/users/" + id);
      dispatch(selectClient(data));
    },
    [dispatch]
  );

  return (
    <>
      <div className="mx-5">
        <h1 className="py-2">My Clients</h1>
        <div
          className="form-group has-search"
          style={{ width: "31vh", marginLeft: "10px", fontWeight: "bold", marginTop: "6px" }}
        >
          <input type="text" className="form-control" placeholder="Search..." onChange={(event) => handleSearch(event)} />
        </div>
        <Table
          className="table"
          modules-table
          striped
          bordered
          hover
          style={{ padding: "15px", background: "#FAFAFA", marginTop: "15px" }}
        >
          <thead className="alert alert-danger">
            <tr>
              <th>Name</th>
              <th>Last Check-In</th>
              <th>Last Login</th>
              <th>Last Updated</th>
              <th></th>
              <th>Active</th>
            </tr>
          </thead>
          {!isPending && data && data.results.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <center>
                    <b>You have no clients assigned to you</b>
                  </center>
                </td>
              </tr>
            </tbody>
          )}
          {!isPending &&
            data &&
            data.results.length > 0 &&
            data.results.map((client) => (
              <tbody key={client.id}>
                <tr>
                  <td>
                    {client.firstName} {client.lastName}
                  </td>
                  <td>{getClientCheckIn(client.checkInTimestamp, client.coachUpdateTimestamp)}</td>
                  <td>{getClientLogIn(client.loginTimestamp)}</td>
                  <td>{getCoachUpdate(client.coachUpdateTimestamp)}</td>
                  <td>
                    <Link to={`/client/${client.id}/training-plans`} onClick={() => handleSaveClient(client.id)}>
                      View training plans
                    </Link>
                  </td>
                  <td>
                    {client.isActive ? (
                      <Switch defaultChecked onChange={() => toggleClientActiveStatus(client.id)} color="error" />
                    ) : (
                      <Switch onChange={() => toggleClientActiveStatus(client.id)} color="error" />
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
        </Table>
      </div>
    </>
  );
};

export default Dashboard;
