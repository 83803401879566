import * as Yup from "yup";

export const editPhaseFormSchema = (selectedPhase) => {
  return [
    {
      type: "text",
      name: "name",
      label: "Title:",
      initialValue: selectedPhase.name,
      validationSchema: Yup.string().required("Please enter the Title"),
    },
  ];
};

export const addPhaseFormSchema = [
  {
    type: "text",
    name: "name",
    label: "Title:",
    initialValue: "",
    validationSchema: Yup.string().required("Please enter the Title"),
  },
];

export const addMicroFormSchema = [];

export const priorityOptions = [
  { value: "0", label: "No Priority Flag" },
  { value: 1, label: "P1" },
  { value: 2, label: "P2" },
  { value: 3, label: "P3" },
  { value: 4, label: "P4" },
  { value: 5, label: "P5" },
  { value: 6, label: "P6" },
  { value: 7, label: "P7" },
  { value: 8, label: "P8" },
  { value: 9, label: "P9" },
  { value: 10, label: "P10" },
];

export const subTypeOptions = [
  { value: "Straight Set", label: "Straight Set", abbre: "StS" },
  { value: "MyoRep", label: "MyoRep", abbre: "MR" },
  { value: "Drop Set", label: "Drop Set", abbre: "DS" },
  { value: "Top Set", label: "Top Set", abbre: "TS" },
  { value: "Back Off Set", label: "Back Off Set", abbre: "BS" },
  { value: "Last Set to Failure", label: "Last Set to Failure", abbre: "LSF" },
  { value: "Rest Pause Set", label: "Rest Pause Set", abbre: "RP" },
  { value: "Rest Pause Set + Partials", label: "Rest Pause Set + Partials", abbre: "RPP" },
];

export const superSetOptions = () => {
  let i;
  let superSetOptions = [];
  superSetOptions.push({ value: "NA", label: "No Super Set Group" });
  for (i = "A".charCodeAt(); i <= "Z".charCodeAt(); i++) {
    superSetOptions.push({ value: String.fromCharCode(i), label: String.fromCharCode(i) });
  }
  return superSetOptions;
};

export const e1rmVlOptions = [
  { value: 1, label: "Don't show E1RM/VL" },
  { value: 2, label: "Only show E1RM" },
  { value: 3, label: "Only show Volume Load" },
  { value: 4, label: "Show both E1RM and VL" },
];

export const copyMicroFormSchema = (options) => {
  return [
    {
      type: "select",
      name: "microcycle",
      label: "Microcycle",
      initialValue: "",
      selectOptions: options.map((value, index) => {
        return {
          value: value.id,
          label: `Microcycle ${index + 1}`,
        };
      }),
      validationSchema: Yup.object().required("Please select the microcycle"),
    },
  ];
};

export const questionnaireSchema = (questionnaire) => {
  const schema = [];

  // At the moment we are doing only 5 questions
  for (let i = 0; i < 5; i++) {
    schema.push({
      type: "text",
      name: `questionIndex${i}`,
      label: `Question ${i + 1}:`,
      initialValue: questionnaire ? questionnaire[i] ?? "" : "",
    });
  }
  return schema;
};

export const changeCoachSchema = (currentCoach, options) => {
  return [
    {
      type: "select",
      name: "coach",
      label: "Select a Coach",
      initialValue: currentCoach || "",
      validationSchema: Yup.object().required("Please select the coach"),
      selectOptions: options,
    },
  ];
};

export const addDayFormSchema = [
  {
    type: "text",
    name: "name",
    label: "Name:",
    initialValue: "",
    validationSchema: Yup.string().required("Please enter the name of the day"),
  },
];
