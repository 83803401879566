import { Col, Row, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DateUtil from "../../utilities/date";

/**
    const formSchema = [
        {
            type: "text",
            name: "firstName",
            label: "First Name:",
            initialValue: "",
            validationSchema: Yup.string().required("Please enter the first name"),
        },
        {
            type: "select",
            name: "gender",
            label: "Gender:",
            initialValue: "m", <-- When passing in the inital value, pass the same value as in the options
            validationSchema: Yup.object().required("Please select the gender"), <-- Make sure validation is using object
            selectOptions: [
                {
                    value: "m",
                    label: "Male",
                },
                {
                    value: "f",
                    label: "Female",
                },
                {
                    value: "o",
                    label: "Other",
                },
            ],
        },
        {
            type: "multi-select",
            name: "gender",
            label: "Gender:",
            initialValue: ['m', 'f'], <-- When passing in the inital value for multi-select pass an array of values from the options
            validationSchema: Yup.array().required("Please select the gender"), <-- Make sure validation is using array
            selectOptions: [
                {
                    value: "m",
                    label: "Male",
                },
                {
                    value: "f",
                    label: "Female",
                },
                {
                    value: "o",
                    label: "Other",
                },
            ],
        },
        {
            type: "date",
            name: "dob",
            label: "Date of Birth:",
            initialValue: "",
            validationSchema: Yup.date().required("Please provide the date of birth"),
        },
    ];
*/

const CustomSelect = (props) => {
  const onChange = (value) => {
    props.onChange(props.name, value);
  };

  return (
    <div className={props.isInvalid && "form-control is-invalid p-0"}>
      <Select
        name={props.name}
        options={props.options}
        isMulti={props.isMulti}
        onChange={onChange}
        value={props.value}
        isInvalid={props.isInvalid}
      />
    </div>
  );
};

const CustomForm = (props) => {
  const { labelColWidths, handleSubmit, formInputsSchema, submitLabel, handleCancel, cancelUrl } = props;

  const initialValues = {};
  const validationsSchema = {};

  // Build initials values and validation schema
  formInputsSchema.forEach((data) => {
    const { type, name, initialValue, validationSchema, selectOptions } = data;
    if (type === "select" && initialValue && initialValue !== "") {
      initialValues[name] = selectOptions.filter((s) => s.value === initialValue)[0];
    } else if (type === "multi-select" && initialValue && initialValue.length > 0) {
      initialValues[name] = selectOptions.filter((s) => initialValue.includes(s.value));
    } else if (type === "date" || type === "microcycleDates") {
      initialValues[name] = DateUtil.fromApi(initialValue);
    } else {
      initialValues[name] = initialValue;
    }

    validationsSchema[name] = validationSchema;
  });

  const handleBeforeSubmit = (values, { setSubmitting, setErrors }) => {
    const results = {};

    for (const s of formInputsSchema) {
      if (s.type === "date" && values[s.name]) {
        results[s.name] = DateUtil.toApi(values[s.name]);
      } else if (s.type === "microcycleDates" && values[s.name]) {
        results[s.name] = DateUtil.toApi(values[s.name]);
        results["endDate"] = DateUtil.toApiFromUnixTimestamp(values["endDate"]);
      } else if (s.type === "select" && values[s.name]) {
        // Grab the value from single select
        results[s.name] = values[s.name].value;
      } else if (s.type === "multi-select" && values[s.name]) {
        // Grab the values from multi-select
        results[s.name] = values[s.name].map((a) => a.value);
      } else {
        // Otherwise just insert into our result object
        results[s.name] = values[s.name];
      }
    }

    handleSubmit(results, { setSubmitting, setErrors });
  };

  // Generate the inputs based on the schema provided
  const inputs = (handleChange, setFieldValue, values, errors) =>
    formInputsSchema.map((data) => {
      const { type, name, label, maxLength, selectOptions } = data;

      const handleMesocycleDate = (value) => {
        setFieldValue(name, value);
        const endDate = new Date(DateUtil.toApi(value));
        setFieldValue("endDate", endDate.setDate(endDate.getDate() + 6));
      };

      return (
        <>
          <Form.Group as={Row} className="p-1">
            <Form.Label column {...(labelColWidths ?? {})}>
              {label}
            </Form.Label>
            <Col>
              {type === "text" && (
                <Form.Control
                  name={name}
                  type="text"
                  maxLength={maxLength || 50}
                  value={values[name]}
                  onChange={handleChange}
                  isInvalid={errors[name]}
                />
              )}
              {type === "number" && (
                <Form.Control
                  name={name}
                  type="number"
                  value={values[name]}
                  onChange={handleChange}
                  isInvalid={errors[name]}
                />
              )}
              {type === "date" && (
                <div className={errors[name] && "form-control is-invalid p-0"}>
                  <Datetime
                    name={name}
                    value={values[name]}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    closeOnSelect={true}
                    onChange={(value) => {
                      setFieldValue(name, value);
                    }}
                  />
                </div>
              )}
              {type === "microcycleDates" && (
                <div className={errors[name] && "form-control is-invalid p-0"}>
                  <Datetime
                    name={name}
                    value={values[name]}
                    timeFormat={false}
                    dateFormat={"DD/MM/YYYY"}
                    closeOnSelect={true}
                    onChange={handleMesocycleDate}
                  />
                </div>
              )}
              {type === "select" && (
                <CustomSelect
                  name={name}
                  value={values[name]}
                  options={selectOptions}
                  isMulti={false}
                  onChange={setFieldValue}
                  isInvalid={errors[name]}
                />
              )}

              {type === "multi-select" && (
                <CustomSelect
                  name={name}
                  value={values[name]}
                  options={selectOptions}
                  isMulti={true}
                  onChange={setFieldValue}
                  isInvalid={errors[name]}
                />
              )}
              {type === "textarea" && (
                <Form.Control
                  name={name}
                  as="textarea"
                  value={values[name]}
                  onChange={handleChange}
                  isInvalid={errors[name]}
                />
              )}
              {errors[name] && <div className="d-flex text-danger">{errors[name]}</div>}
            </Col>
          </Form.Group>
          {type === "microcycleDates" && (
            <>
              <Form.Group as={Row} className="p-1">
                <Form.Label column {...(labelColWidths ?? {})}>
                  End Date:
                </Form.Label>
                <Col>
                  <div className={errors[name] && "form-control is-invalid p-0"}>
                    <Datetime
                      name={"endDate"}
                      value={values["endDate"]}
                      timeFormat={false}
                      dateFormat={"DD/MM/YYYY"}
                      closeOnSelect={true}
                      disabled={true}
                      open={false}
                      inputProps={{ disabled: true }}
                    />
                  </div>
                </Col>
              </Form.Group>
            </>
          )}
        </>
      );
    });

  return (
    <Formik
      validateOnChange={false}
      validationOnBlur={false}
      initialValues={initialValues}
      validationSchema={Yup.object().shape(validationsSchema)}
      onSubmit={(v, { setSubmitting, setErrors }) => handleBeforeSubmit(v, { setSubmitting, setErrors })}>
      {({ handleSubmit, handleChange, setFieldValue, values, errors, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {inputs(handleChange, setFieldValue, values, errors)}
          <Row>
            <Col {...(labelColWidths ?? {})}></Col>
            <Col>
              <Form.Group>
                <div className="pt-1 ps-1">
                  <Button className="me-1 form-button" type="submit" disabled={isSubmitting}>
                    {submitLabel ?? ""}
                  </Button>
                  {handleCancel !== undefined && (
                    <Button style={{ width: "49%" }} onClick={handleCancel}>
                      Cancel
                    </Button>
                  )}
                  {cancelUrl !== undefined && (
                    <a href={cancelUrl}>
                      <Button className="form-button">Cancel</Button>
                    </a>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CustomForm;
