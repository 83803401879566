import { Button, Card, Tab, Col, Row, Nav, DropdownButton, Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DateUtil from "../../utilities/date";
import ConfirmationModal from "../modal/ConfirmationModal";
import { get, remove } from "../../utilities/api";
import useAccountStatus from "../../hooks/user/useAccountStatus";
import useClientAutoMode from "../../hooks/user/useClientAutoMode";

const Clients = () => {
  const [me, setMe] = useState(null);
  const [coaches, setCoaches] = useState(null);
  const [clients, setClients] = useState(null);

  const [filterCoachesId, setFilterCoachesId] = useState([]); // Stores the assigned coaches id to filter from

  const [searchTimeout, setSearchTimeout] = useState(null); // This will be used to set a delay before calling the api to search

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState(false);

  const { AccountStatusModal, AccountStatusButton } = useAccountStatus(clients, setClients);
  const { ClientAutoStatusButton, ClientAutoStatusModal } = useClientAutoMode(clients, setClients);

  // This effect will be use for initialisation of this component
  useEffect(() => {
    initialiseData();
    return () => {};
  }, []);

  // This effect will update clients data everytime the coach filter array gets changed
  useEffect(() => {
    // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    const updateClients = async () => {
      const coachesFilterStr = filterCoachesId.map((v) => `filters[coaches][]=${v}`);

      let response = null;
      if (coachesFilterStr.length > 0) {
        response = await get(`/users?filters[types][]=Client&${coachesFilterStr.join("&")}`);
      } else {
        response = await get(`/users?filters[types][]=Client`);
      }

      setClients(response.results);
    };

    updateClients();
    return () => {};
  }, [filterCoachesId]);

  // Initialise
  const initialiseData = async () => {
    const me = await get("/me", false, true);
    const userData = await get("/users", false, true);

    const tempCoaches = [];
    const tempClients = [];

    for (const d of userData.results) {
      if (d.type === "Client") {
        tempClients.push(d);
      } else {
        tempCoaches.push(d);
      }
    }

    setMe(me);
    setCoaches(tempCoaches);
    setClients(tempClients);
  };

  // This will fetch the clients data and update the state
  const updateClients = async (searchStr) => {
    // Stop existing timer
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    let filterStr = "filters[types][]=Client";

    if (filterCoachesId.length > 0) {
      const coachesFilterStr = filterCoachesId.map((v) => `filters[coaches][]=${v}`);
      filterStr = filterStr + `&${coachesFilterStr.join("&")}`;
    }

    if (searchStr && searchStr !== "") {
      filterStr = filterStr + `&filters[name]=${searchStr}`;
    }

    try {
      const response = await get(`/users?${filterStr}`);
      setClients(response.results);
    } catch (e) {
      console.log(e);
    }
  };

  // This will generate the dropdown selection of coaches to pick from
  const generateCoachesFilterSelection = () => {
    const results = [];

    for (const d of coaches) {
      if (filterCoachesId.includes(d.id)) {
        results.push(
          <Dropdown.Item key={d.id} onClick={() => setFilterCoachesId([d.id])} active>
            {d.firstName} {d.lastName}
          </Dropdown.Item>
        );
      } else {
        results.push(
          <Dropdown.Item key={d.id} onClick={() => setFilterCoachesId([d.id])}>
            {d.firstName} {d.lastName}
          </Dropdown.Item>
        );
      }
    }

    // Add in a reset button if there's currently coaches in the filter
    if (filterCoachesId.length > 0) {
      results.push(<Dropdown.Divider key={-1} />);
      results.push(
        <Dropdown.Item key={0} onClick={() => setFilterCoachesId([])}>
          <i>Reset</i>
        </Dropdown.Item>
      );
    }

    return results;
  };

  // Close all modals opened
  const handleModalCancel = () => {
    setShowDeleteUserConfirmationModal(false);
  };

  // Show the delete modal to confirm deletion
  const handleDeleteUserClick = (userId, name) => {
    setSelectedUserId(userId);
    setSelectedUserName(name);
    setShowDeleteUserConfirmationModal(true);
  };

  // Handle the confirmation of delete from modal
  const handleConfirmedUserDelete = async () => {
    await remove(`/users/${selectedUserId}`);
    await updateClients();
    handleModalCancel();
  };

  // Handle the search input, a delay will be added before calling the api
  // so it does not spam the calls
  // https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
  const handleSearch = (event) => {
    // Stop existing timer
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const keyword = event.target.value.toLowerCase();

    const timeoutFunction = setTimeout(() => {
      updateClients(keyword);
    }, 500);

    setSearchTimeout(timeoutFunction);
  };

  return (
    <>
      <AccountStatusModal />
      <ClientAutoStatusModal />
      <ConfirmationModal
        show={showDeleteUserConfirmationModal}
        title={`Confirm Delete for ${selectedUserName}`}
        body="This action cannot be reversed and will delete all data related to this client."
        handleCancel={handleModalCancel}
        handleConfirmation={handleConfirmedUserDelete}
      />
      {me !== null && coaches !== null && clients !== null && (
        <div>
          <div className="d-flex flex-row" style={{}}>
            <h4 className="py-2" style={{ fontWeight: "bold", marginLeft: "245px", marginRight: "10px" }}>
              Clients
            </h4>
            <div style={{ marginTop: "5px" }}>
              <Button as={NavLink} to="/users/clients/create" style={{}}>
                Add
              </Button>
            </div>
            <DropdownButton title="Filter By" style={{ marginLeft: "10px", marginTop: "5px" }}>
              {generateCoachesFilterSelection(filterCoachesId)}
            </DropdownButton>
            <div
              className="form-group has-search"
              style={{ width: "31vh", marginLeft: "10px", fontWeight: "bold", marginTop: "6px" }}
            >
              <span className="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                onChange={(event) => handleSearch(event)}
              />
            </div>
          </div>
          <hr />
          <div>
            <Tab.Container defaultActiveKey="0" style={{ padding: "10px" }}>
              <div className="d-flex flex-row w-100">
                <div className="tab-user-list">
                  <Nav variant="pills" className="flex-column">
                    {clients.map((client, index) => (
                      <div key={index}>
                        <Nav.Item style={{ margin: "10px", textAlign: "center" }}>
                          <Nav.Link eventKey={index}>
                            {client.firstName} {client.lastName}
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    ))}
                  </Nav>
                </div>
                <div className="flex-fill">
                  {clients.map((client, index) => (
                    <div className="tab-user-content " key={index}>
                      <Tab.Content>
                        <Tab.Pane eventKey={index}>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col md={3} lg={2} xl={2} xxl={1}>
                                  <div className="d-flex flex-row">
                                    <img
                                      className="profile-pic"
                                      src={`https://ui-avatars.com/api/?name=${client.firstName}+${client.lastName}&bold=true`}
                                      alt="profile-pic"
                                      width="72"
                                      height="72"
                                      style={{ marginTop: "6px" }}
                                    />
                                    <div className="ms-3 flex-column">
                                      <h2
                                        style={{
                                          textTransform: "uppercase",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {client.firstName + " " + client.lastName}
                                      </h2>
                                      <h4>{client.email}</h4>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="pt-3">
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Gender: </b>
                                </Col>
                                <Col>{client.gender}</Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Date of Birth: </b>
                                </Col>
                                <Col>{DateUtil.fromApiToString(client.dob)}</Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Mobile: </b>
                                </Col>
                                <Col>{client.mobile}</Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Height (cm): </b>
                                </Col>
                                <Col>
                                  {client.height === null && <span className="placeholder-text">Height not set</span>}
                                  {client.height === "" && <span className="placeholder-text">Height not set</span>}
                                  {client.height && client.height}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Weight (kg): </b>
                                </Col>
                                <Col>
                                  {client.weight === null && <span className="placeholder-text">Weight not set</span>}
                                  {client.weight === "" && <span className="placeholder-text">Weight not set</span>}
                                  {client.weight && client.weight}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Primary Goal: </b>
                                </Col>
                                <Col>
                                  {client.goal === null && <span className="placeholder-text">Goal not set</span>}
                                  {client.goal === "" && <span className="placeholder-text">Goal not set</span>}
                                  {client.goal && client.goal}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} lg={4} xxl={3}>
                                  <b> Assigned Coach: </b>
                                </Col>
                                <Col>
                                  {client.coaches.length === 0 && (
                                    <span className="placeholder-text">No coach assigned.</span>
                                  )}
                                  {client.coaches.length > 0 &&
                                    client.coaches[0].firstName + " " + client.coaches[0].lastName}
                                </Col>
                              </Row>
                              {me.type === "Admin" && (
                                <>
                                  <Row>
                                    <Col xs={12} lg={4} xxl={3}>
                                      <b> Account Status: </b>
                                    </Col>
                                    <Col>
                                      {client.disabled ? "Disabled" : "Enabled"}
                                      <AccountStatusButton
                                        userId={client.id}
                                        userName={client.firstName}
                                        isUserAccountDisabled={client.disabled}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12} lg={4} xxl={3}>
                                      <b> Auto Mode: </b>
                                    </Col>
                                    <Col>
                                      {client.isClientAutoMode ? "Enabled" : "Disabled"}
                                      <ClientAutoStatusButton
                                        userId={client.id}
                                        userName={client.firstName}
                                        isClientAutoMode={client.isClientAutoMode}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Link to={"/users/clients/" + client.id} className="btn btn-primary">
                                Edit
                              </Link>
                              {me.type === "Admin" && (
                                <Button
                                  className="btn btn-primary"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => handleDeleteUserClick(client.id, client.firstName + " " + client.lastName)}
                                >
                                  Delete
                                </Button>
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  ))}
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      )}
    </>
  );
};

export default Clients;
