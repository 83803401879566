import React from "react";
import useGetFetch from "../../utilities/useFetch";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { get } from "../../utilities/api";
import { useDispatch } from "react-redux";
import { saveActiveMicro, saveActivePhase, selectClient } from "../../redux/clientSlice";

const ClientDropDown = (props) => {
  const { selectedOption, isPlan, handleSelect, handleLoadClients } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: clientList } = useGetFetch(`/me/assigned-clients`, false);

  const findActiveMicro = async (selectedId) => {
    let activePlan, activeMicro;
    try {
      const data = await get("/users/" + selectedId);
      let activeData = await get("/users/" + selectedId + "/plans");
      activePlan = activeData.results.filter((phase) => {
        return phase.isActive === true && phase.isUpcoming === false;
      });

      if (activePlan.length > 0) {
        let res = await get(`/users/${selectedId}/plans/${activePlan[0].id}/weeks`);
        const activeMicroData = res.results.filter((r) => r.isActive === true);
        if (activeMicroData.length > 0) activeMicro = activeMicroData;
      }

      if (activePlan && activeMicro) {
        dispatch(selectClient(data));
        dispatch(saveActiveMicro(activeMicro));
        dispatch(saveActivePhase(activePlan));
        handleSelect(selectedId, activePlan[0].id, activeMicro[0].id, activeMicro[0].position, activePlan[0].name, false);
      } else {
        history.push(`/client/${selectedId}/training-plans`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FormClientsSelect = () => {
    const results = [{ id: "0", name: "--SELECT CLIENT--" }];
    if (clientList) {
      for (const c of clientList.results) {
        results.push({ id: c.id, name: c.firstName + " " + c.lastName });
      }
    }

    const options = results.map((r) => ({
      value: r,
      label: r.name,
    }));

    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: "#d9d9d9",
        borderRadius: "5px",
        borderColor: "#d9d9d9",
        boxShadow: state.isFocused ? null : null,
      }),
      menuList: (base) => ({
        ...base,
        borderRadius: "5px",
        padding: 0,
        overflowY: "auto",
        maxHeight: "600px",
        position: "fixed",
        minWidth: "250px",
      }),
      option: (base, { isFocused }) => ({
        ...base,
        background: isFocused ? "#b01212" : "#fff",
        color: isFocused ? "#fff" : "black",
      }),
    };

    return (
      <Select
        styles={customStyles}
        options={options}
        onChange={(v) => {
          if (v.value.id === "0") {
            history.push(`/client/select-client`);
          } else {
            if (isPlan) {
              handleSelect(v.value.id);
              history.push(`/client/${v.value.id}/training-plans`);
            } else if (!handleLoadClients) {
              findActiveMicro(v.value.id);
            } else {
              handleLoadClients(v);
            }
          }
        }}
        value={selectedOption}
      />
    );
  };

  return clientList ? (
    <div className="name-list">
      <FormClientsSelect />
    </div>
  ) : (
    <div className="name-list"></div>
  );
};

export default ClientDropDown;
