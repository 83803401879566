import Container from "react-bootstrap/Container";
import Link from "react-router-dom/Link";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavLink from "react-router-dom/NavLink";
import useGetFetch from "../../utilities/useFetch";
import { auth } from "../../firebase";
import { useHistory } from "react-router";

const TopNavbar = () => {
  const { data } = useGetFetch("/me", false);
  const history = useHistory();

  const username = () => {
    return `${data.firstName} ${data.lastName}`;
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => history.push("/login"))
      .catch((err) => {
        console.log("Something happened when trying to log out");
        history.push("/login");
      });
  };

  return (
    <Navbar className="font-montserrat" bg="secondary" expand="xl" variant="dark">
      <Navbar.Brand as={Link} to="/">
        <img src="/jps-logo-2.png" display="block" width="390" height="36" alt="JPS logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" data-testid="buttonNavToggle" />
      <Container fluid={true}>
        <Navbar.Collapse>
          <Nav className="justify-content-end">
            <Navbar.Text className="d-xl-none d-block welcome" data-testid="txtMobileWelcome">
              Welcome, {data && username()}
            </Navbar.Text>
          </Nav>
          <Nav className="me-auto">
            <Nav.Link as={NavLink} exact to="/" data-testid="btnDashboard" style={{ color: "white" }}>
              DASHBOARD
            </Nav.Link>
            <Nav.Link as={NavLink} strict to="/exercises" data-testid="btnExercises" style={{ color: "white" }}>
              EXERCISES
            </Nav.Link>
            <Nav.Link as={NavLink} strict to="/programs" data-testid="btnPrograms" style={{ color: "white" }}>
              PROGRAMS
            </Nav.Link>
            <div style={{ color: "white" }}>
              <NavDropdown title="USERS" data-testid="btnUsersDropdown">
                <NavDropdown.Item as={NavLink} strict to="/users/clients" data-testid="btnUsersDropdownClients">
                  CLIENTS
                </NavDropdown.Item>
                {data && data.type === "Admin" && (
                  <NavDropdown.Item as={NavLink} strict to="/users/coaches">
                    COACHES
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </div>
            <Nav.Link as={NavLink} strict to="/account" data-testid="btnMyAccount" style={{ color: "white" }}>
              MY ACCOUNT
            </Nav.Link>
          </Nav>
          <Nav className="justify-content-end">
            <Navbar.Text className="d-none d-xl-block welcome" data-testid="txtDesktopWelcome" style={{ color: "white" }}>
              WELCOME, {data && username()}
            </Navbar.Text>
            <Nav.Link id="btnLogout" onClick={() => handleLogout()} data-testid="btnLogout">
              <div className="logout">LOG OUT</div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
