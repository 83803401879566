import React from "react";

const TableRow = (props) => {
  const { name, content } = props;
  return (
    <tr>
      <td className="column-name">{name}</td>
      <td>{content}</td>
    </tr>
  );
};

export default TableRow;
