import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import ProgramModal from "./ProgramModal";
import ClientDropDown from "../../client-dropdown/ClientDropDown";
import { Checkbox } from "@mui/material";
import { create } from "../../../utilities/api";

const LoadModal = (props) => {
  const { show, info, handleSubmit, handleCancel, submitLabel, size, cancelLabel } = props;
  // const [toggle, setToggle] = useState(true);
  const [micro1Active, setMicro1Active] = useState(false);
  const [inputChange, setInputChange] = useState(info && info.displayName);
  const [selectedclient, setSelectedClient] = useState({
    value: { id: "0", name: "--SELECT CLIENT--" },
    label: "SELECT CLIENT",
  });

  // const handleToggle = () => {
  //   setToggle(!toggle);
  // };

  const handleLoadClients = (v) => {
    setSelectedClient({
      value: { id: v.value.id, name: v.value.name },
      label: v.value.name,
    });
  };

  const handleLoad = async () => {
    let res = await create(`/training-plan-template-import`, {
      clientId: selectedclient.value.id,
      templatePlanId: info.id,
      setActive: micro1Active,
      phaseName: inputChange,
    });
    if (res) handleSubmit();
  };

  return (
    <ProgramModal
      show={show}
      info={
        info && {
          name: info.name,
          goal: info.goal,
          level: info.level,
          duration: info.weeks ? info.weeks.length : 0,
          sessions: info.weeks ? info.weeks.sessions : 0,
          createdBy: info.owner && info.owner.firstName + " " + info.owner.lastName,
        }
      }
      handleCancel={handleCancel}
      handleSubmit={() => {
        handleLoad();
      }}
      submitLabel={submitLabel}
      cancelLabel={cancelLabel}
      size={size}
    >
      <Modal.Body style={{ minHeight: "250px" }}>
        <div style={{ width: "250px" }}>
          <div style={{ marginBottom: "6px" }}>
            <b>Select target client</b>
          </div>
          <ClientDropDown selectedOption={selectedclient} isPlan={false} handleLoadClients={handleLoadClients} />
        </div>
        <div style={{ marginTop: "15px", display: "flex" }}>
          <div style={{ width: "250px" }}>
            <b>Phase name</b>
            {/* <div style={{ marginTop: "6px" }}>{info && info.displayName}</div> */}

            <div className="form-group" style={{ marginTop: "6px" }}>
              <input
                type="text"
                className="form-control"
                placeholder={info && info.displayName}
                value={inputChange}
                defaultValue={info && info.displayName}
                onChange={(event) => setInputChange(event.target.value)}
              />
            </div>
          </div>

          <div style={{ marginLeft: "30px", width: "250px" }}>
            <div>Set as Microcycle 1 as active</div>
            <Checkbox
              checked={micro1Active}
              onChange={(event) => setMicro1Active(event.target.checked)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 28 },
                color: "#434343",
                "&.Mui-checked": {
                  color: "#434343",
                },
              }}
            />
          </div>
          {/* <div style={{ width: "250px" }}>
            <div>Copy as new phase</div>
            <Switch defaultChecked color="error" checked={toggle} onChange={handleToggle} />
          </div>
          {toggle ? (
            <>
              <div style={{ marginLeft: "30px", width: "250px" }}>
                <div>Phase name</div>
                <div className="form-group" style={{ marginTop: "6px" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={info && info.displayName}
                    value={inputChange}
                    defaultValue={info && info.displayName}
                    onChange={(event) => setInputChange(event.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginLeft: "30px", width: "250px" }}>
                <div>Set as Microcycle 1 as active</div>
                <Checkbox
                  checked={CheckStatus}
                  onChange={(event) => setCheckStatus(event.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: "#434343",
                    "&.Mui-checked": {
                      color: "#434343",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div style={{ marginLeft: "30px", width: "250px" }}>
                <div style={{ marginBottom: "6px" }}>Phase to load into</div>
                <ClientDropDown
                  selectedOption={{
                    value: { id: "0", name: "--CLIENT PHASES--" },
                    label: "CLIENT PHASES",
                  }}
                  isPlan={true}
                  size="150px"
                />
              </div>
            </>
          )}*/}
        </div>
      </Modal.Body>
    </ProgramModal>
  );
};

export default LoadModal;
