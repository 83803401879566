export const findE1rm = (workoutLogs) => {
  let highestE1RM = 0;
  if (workoutLogs) {
    for (let i = 0; i < workoutLogs.length; i++) {
      if (workoutLogs[i].e1rm && workoutLogs[i].e1rm > highestE1RM) {
        highestE1RM = workoutLogs[i].e1rm;
      }
    }
    if (highestE1RM) return highestE1RM;
    else if (workoutLogs.length === 0) return "";
    else return "NA";
  } else {
    return "";
  }
};

export const findVolumnLoad = (workoutLogs) => {
  let totalVl = 0;
  if (workoutLogs) {
    for (let i = 0; i < workoutLogs.length; i++) {
      if (workoutLogs[i].volumeLoad) {
        totalVl += workoutLogs[i].volumeLoad;
      }
    }
    if (totalVl) return totalVl;
    if (workoutLogs.length === 0) return "";
    else return "NA";
  } else {
    return "";
  }
};

export const calculateIntensity = (workoutLogs) => {
  let total = 0;
  let length = 0;
  if (workoutLogs) {
    for (let i = 0; i < workoutLogs.length; i++) {
      if (workoutLogs[i].intensityValue !== null) {
        total += workoutLogs[i].intensityValue;
        length++;
      }
    }

    if (total && length) {
      return `@ ${Math.round((total / length) * 10) / 10}`;
    }
  } else {
    return "";
  }
};
