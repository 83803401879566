import React, { useCallback, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import * as Yup from "yup";
import { e1rmVlOptions, priorityOptions, subTypeOptions, superSetOptions } from "../../../schemas/schemas";
import useGetFetch from "../../../utilities/useFetch";

const ExerciseForm = ({ submit, submitLabel, handleCancel, defaultValues }) => {
  const { data: exercises } = useGetFetch("/exercises?filters[active]=true");
  const [optionType, setOptionType] = useState({
    value: defaultValues?.exerciseProperties?.isMultiExercise ? "Multi Option" : "Single Option",
    label: defaultValues?.exerciseProperties?.isMultiExercise ? "Multi Option" : "Single Option",
  });
  const [itemname, setItemname] = useState(
    defaultValues?.exerciseProperties?.itemName ? defaultValues.exerciseProperties.itemName : ""
  );
  const [selectedExercises, setSelectedExercises] = useState([
    defaultValues?.exerciseProperties?.exercise && {
      value: defaultValues.exerciseProperties.exercise.exerciseId,
      label: defaultValues.exerciseProperties.exercise.exerciseName,
    },
    defaultValues?.exerciseProperties?.exercise2 && {
      value: defaultValues.exerciseProperties.exercise2.exerciseId,
      label: defaultValues.exerciseProperties.exercise2.exerciseName,
    },
    defaultValues?.exerciseProperties?.exercise3 && {
      value: defaultValues.exerciseProperties.exercise3.exerciseId,
      label: defaultValues.exerciseProperties.exercise3.exerciseName,
    },
    defaultValues?.exerciseProperties?.exercise4 && {
      value: defaultValues.exerciseProperties.exercise4.exerciseId,
      label: defaultValues.exerciseProperties.exercise4.exerciseName,
    },
    defaultValues?.exerciseProperties?.exercise5 && {
      value: defaultValues.exerciseProperties.exercise5.exerciseId,
      label: defaultValues.exerciseProperties.exercise5.exerciseName,
    },
  ]);
  const [errorExerciseSelect, setErrorExerciseSelect] = useState();
  const [errorItemName, setErrorItemName] = useState();

  const getExercisesOptions = () => {
    const options = exercises.results.map((e) => ({
      value: e.exerciseId,
      label: e.exerciseName,
    }));
    return options;
  };

  const defaultE1rmVlValue = () => {
    if (defaultValues.exerciseProperties.showE1RM && defaultValues.exerciseProperties.showVolumeLoad)
      return { value: 4, label: "Show both E1RM and VL" };
    else if (!defaultValues.exerciseProperties.showE1RM && defaultValues.exerciseProperties.showVolumeLoad)
      return { value: 3, label: "Only show Volume Load" };
    else if (defaultValues.exerciseProperties.showE1RM && !defaultValues.exerciseProperties.showVolumeLoad)
      return { value: 2, label: "Only show E1RM" };
    else return { value: 1, label: "Don't show E1RM/VL" };
  };

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const validationSchema = Yup.object({
    sets: Yup.number().required("Please enter no. of sets"),
    reps: Yup.string()
      .required("Please enter no. of reps")
      .matches(/^[0-9]+(?:-[0-9]+)?$/, "Format must be like 3 or 3-4 (Decimal allow)"),
    weight: Yup.string()
      .nullable(true)
      .matches(/^$|[0-9]+(?:\.[0-9]{0,2})?(?:-[0-9]+(?:\.[0-9]{0,2})?)?$/, "Format must be like 3 or 3-4 (Decimal allow)"),
    intensityValue: Yup.string()
      .required("Please enter the intensity value")
      .matches(/^[0-9]+(?:\.[0-9]{0,2})?(?:-[0-9]+(?:\.[0-9]{0,2})?)?$/, "Format must be 3 or 3-4"),
  });

  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: defaultValues
      ? {
          type: defaultValues.type,
          exerciseType: {
            value: defaultValues.exerciseProperties.isMultiExercise ? "Multi Option" : "Single Option",
            label: defaultValues.exerciseProperties.isMultiExercise ? "Multi Option" : "Single Option",
          },
          subType: { value: defaultValues.subType, label: defaultValues.subType },
          superSet: {
            value: defaultValues.superSet ? defaultValues.superSet : "NA",
            label: defaultValues.superSet ? defaultValues.superSet : "No Super Set Group",
          },
          intensity: {
            value: defaultValues.exerciseProperties.intensity,
            label: defaultValues.exerciseProperties.intensity,
          },
          priority: {
            value: defaultValues.priority,
            label: priorityOptions.filter((p) => Number(p.value) === defaultValues.priority)[0].label,
          },
          e1rmvl: defaultE1rmVlValue(),
          weight: defaultValues.exerciseProperties.weight,
          sets: defaultValues.exerciseProperties.sets,
          reps: defaultValues.exerciseProperties.reps,
          intensityValue: defaultValues.exerciseProperties.intensityValue,
          comment: defaultValues.exerciseProperties.comment,
        }
      : {
          type: "Exercise",
          exerciseType: { value: "Single Option", label: "Single Option" },
          subType: { value: "Straight Set", label: "Straight Set", abbre: "StS" },
          superSet: { value: "NA", label: "No Super Set Group" },
          intensity: { value: "RIR", label: "RIR" },
          priority: { value: "0", label: "No Priority Flag" },
          e1rmvl: { value: 1, label: "Don't show E1RM/VL" },
        },
  });

  const onSubmit = (data) => {
    data.exerciseType = optionType;
    if (
      (optionType.value === "Multi Option" && itemname && selectedExercises.filter(Boolean).length >= 1) ||
      (optionType.value === "Single Option" && selectedExercises.filter(Boolean).length >= 1)
    ) {
      data.itemName = optionType.value === "Multi Option" ? itemname : "";
      if (optionType === "Single Option" && selectedExercises.filter(Boolean).length > 1) {
        data.exerciseId = [selectedExercises[0]];
      } else {
        data.exerciseId = selectedExercises;
      }
      setErrorExerciseSelect();
      setErrorItemName();
      submit(data);
    } else if (optionType.value === "Multi Option" && !itemname) {
      setErrorItemName("Please enter an item name");
    } else if (selectedExercises.filter(Boolean).length === 0) {
      setErrorExerciseSelect("Must select one exercise");
    }
  };

  const customStyles = {
    option: (base, { isFocused, isDisabled }) => ({
      ...base,
      background: !isDisabled ? (isFocused ? "#b01212" : "#fff") : "#fff",
      color: !isDisabled ? (isFocused ? "#fff" : "black") : "lightgrey",
    }),
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "49%" }}>
          <Col>
            <Col style={{ marginTop: "2px" }}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <span>
                    <Form.Label>Item Type</Form.Label>
                    <Form.Control name="type" {...field} type="text" placeholder="Exercise" disabled />
                  </span>
                )}
              />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="exerciseType"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>Exercise Type</Form.Label>
                    <Select
                      styles={customStyles}
                      name="exerciseType"
                      {...field}
                      options={[
                        { value: "Single Option", label: "Single Option" },
                        { value: "Multi Option", label: "Multi Option" },
                      ]}
                      value={optionType}
                      onChange={(e) => {
                        setOptionType(e);
                      }}
                    />
                  </>
                )}
              />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="itemName"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>Item Name</Form.Label>
                    <Form.Control
                      name="itemName"
                      {...field}
                      type="text"
                      placeholder={optionType.value === "Single Option" ? "Disabled for Single Exercise" : ""}
                      disabled={optionType.value === "Single Option"}
                      value={optionType.value === "Single Option" ? "Disabled for Single Exercise" : itemname}
                      onChange={(e) => setItemname(e.target.value)}
                    />
                  </>
                )}
              />
              {errorItemName && <i style={{ color: "#b01212" }}>{errorItemName}</i>}
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="subType"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>Set Type</Form.Label>
                    <Select styles={customStyles} {...field} options={subTypeOptions} />
                  </>
                )}
              />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="superSet"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>Super Set Group (Optional)</Form.Label>
                    <Select styles={customStyles} {...field} options={superSetOptions()} />
                  </>
                )}
              />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>Priority Flag (Optional)</Form.Label>
                    <Select styles={customStyles} {...field} options={priorityOptions} />
                  </>
                )}
              />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="e1rmvl"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>Show E1RM/Volume Load</Form.Label>
                    <Select styles={customStyles} {...field} options={e1rmVlOptions} />
                  </>
                )}
              />
            </Col>
          </Col>
        </div>
        <div style={{ width: "49%" }}>
          <Col>
            <Col style={{ marginTop: "3px" }}>
              <Controller
                name="exerciseId"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>
                      Exercise Select{" "}
                      {optionType.value === "Multi Option" && (
                        <small>
                          <i>(up to 5 exercises)</i>
                        </small>
                      )}
                    </Form.Label>
                    <Select
                      styles={customStyles}
                      name="exerciseId"
                      {...field}
                      options={exercises && getExercisesOptions()}
                      isMulti={optionType.value === "Multi Option"}
                      value={selectedExercises}
                      isOptionDisabled={() => selectedExercises?.filter(Boolean).length >= 5}
                      onChange={(e) => {
                        setSelectedExercises(optionType.value === "Multi Option" ? e : [e]);
                        setErrorExerciseSelect();
                      }}
                    />
                  </>
                )}
              />
              {errorExerciseSelect && <i style={{ color: "#b01212" }}>{errorExerciseSelect}</i>}
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="sets"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Label>No. of Sets</Form.Label>
                    <Form.Control name="sets" {...field} type="number" max={50} min={1} />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name="sets" render={({ message }) => <p className="text-danger">{message}</p>} />
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Controller
                name="reps"
                control={control}
                render={({ field }) => (
                  <span>
                    <Form.Label>No. of Reps</Form.Label>
                    <Form.Control name="reps" {...field} type="text" />
                  </span>
                )}
              />
              <ErrorMessage errors={errors} name="reps" render={({ message }) => <p className="text-danger">{message}</p>} />
            </Col>
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Controller
              name="weight"
              control={control}
              render={({ field }) => (
                <span>
                  <Form.Label>Load</Form.Label>
                  <Form.Control name="weight" {...field} type="text" />
                </span>
              )}
            />
            <ErrorMessage errors={errors} name="weight" render={({ message }) => <p className="text-danger">{message}</p>} />
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Controller
              name="intensity"
              control={control}
              render={({ field }) => (
                <span>
                  <Form.Label>Intensity Metric</Form.Label>
                  <Select
                    styles={customStyles}
                    {...field}
                    options={[
                      { value: "RPE", label: "RPE" },
                      { value: "RIR", label: "RIR" },
                    ]}
                  />
                </span>
              )}
            />
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Controller
              name="intensityValue"
              control={control}
              render={({ field }) => (
                <span>
                  <Form.Label>Intensity Value</Form.Label>
                  <Form.Control name="intensityValue" {...field} type="text" />
                </span>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="intensityValue"
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </Col>
        </div>
      </div>
      <Row className="mt-2">
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <span>
              <Form.Label>Comment</Form.Label>
              <Form.Control name="comment" {...field} as="textarea" />
            </span>
          )}
        />
        <ErrorMessage errors={errors} name="about" render={({ message }) => <p className="text-danger">{message}</p>} />
      </Row>

      <Row className="mt-3">
        <Col>
          <Form.Group>
            <div>
              <Button className="form-button" type="submit">
                {submitLabel}
              </Button>
              <Button className="form-button" style={{ marginLeft: "15px" }} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ExerciseForm;
