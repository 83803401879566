import CustomForm from "../form/CustomForm";
import Modal from "./Modal";

const FormModal = ({
  show,
  title,
  submitLabel,
  formInputsSchema,
  handleSubmit,
  handleCancel,
  options,
  handleOptionsSelected,
  body,
}) => {
  const getForm = () => {
    const formLabelColumnViewpoint = {
      sm: 12,
      lg: 3,
    };

    return (
      <CustomForm
        labelColWidths={formLabelColumnViewpoint}
        handleSubmit={handleSubmit}
        formInputsSchema={formInputsSchema}
        submitLabel={submitLabel ?? "Submit"}
        handleCancel={handleCancel}
      />
    );
  };

  return (
    <>
      <Modal
        show={show}
        title={title}
        body={body ? body : getForm()}
        size="lg"
        submitLabel={submitLabel}
        handleCancel={handleCancel}
        showFooter={false}
        options={options}
        handleOptionsSelected={handleOptionsSelected}
      />
    </>
  );
};

export default FormModal;
