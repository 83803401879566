import React, { useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import LoadModal from "./programModals/LoadModal";
import SummaryModal from "./programModals/SummaryModal";
import { remove } from "../../utilities/api";
import Link from "react-router-dom/Link";

const ProgramDatabase = ({ plans, refreshPlans, isSearch, refreshSearch }) => {
  const [view, setView] = useState(false);
  const [showLoadModal, setLoadModal] = useState(false);
  const [plan, setPlan] = useState({});

  const getModeSessions = (weeks) => {
    const mode = {};
    let max = 0,
      count = 0;

    for (let i = 0; i < weeks.length; i++) {
      if (weeks[i].days && weeks[i].days.length > 0) {
        const item = weeks[i].days.length;

        if (mode[item]) {
          mode[item]++;
        } else {
          mode[item] = 1;
        }

        if (count < mode[item]) {
          max = item;
          count = mode[item];
        }
      }
    }
    weeks.sessions = max;
    return max;
  };

  const getPlan = (id) => {
    const plan = plans.find((p) => p.id === id);
    setPlan(plan);
  };

  const handleDelete = async (id) => {
    let res = await remove("/template-plans/" + id);
    if (res.success) {
      refreshPlans((old) => old + 1);
      isSearch && refreshSearch(id);
    }
    setView(false);
  };

  return (
    <>
      <SummaryModal
        show={view}
        info={plan}
        handleCancel={() => setView(false)}
        handleSubmit={() => {
          setView(false);
          setLoadModal(true);
        }}
        submitLabel="SELECT CLIENT"
        size="modal-90w"
        cancelLabel="CANCEL"
        handleDelete={handleDelete}
        handleEdit={
          <Button
            as={Link}
            to={{ pathname: `/program/${plan.id}/microcycles/${plan.weeks && plan.weeks[0].id}`, query: { plan: plan } }}
            className="modal-btn"
          >
            EDIT PLAN
          </Button>
        }
      />
      <LoadModal
        show={showLoadModal}
        info={plan}
        handleCancel={() => {
          setLoadModal(false);
          setView(true);
        }}
        handleSubmit={() => setLoadModal(false)}
        submitLabel="LOAD"
        size="modal-70w"
        cancelLabel="BACK"
      />
      <Container fluid className="summary-container">
        <Row className="header">PROGRAM DATABASE</Row>
        <Row>
          <Table hover borderless>
            <thead className="table-header">
              <tr>
                <th>name</th>
                <th>goal</th>
                <th>level</th>
                <th>duration</th>
                <th>sessions</th>
                <th>tags</th>
                <th style={{ width: "20px" }}></th>
              </tr>
            </thead>
            {plans &&
              plans.map((p) => {
                return (
                  <tbody className="table-body">
                    <tr>
                      <td style={{ maxWidth: "120px" }}>{p.name}</td>
                      <td style={{ width: "140px" }}>{p.goal}</td>
                      <td style={{ width: "130px" }}>{p.level}</td>
                      <td style={{ width: "130px" }}>{p.weeks && p.weeks.length} Microcycles</td>
                      <td style={{ width: "110px" }}>{p.weeks ? getModeSessions(p.weeks) : 0} per micro</td>
                      <td className="tags">
                        {p.tags &&
                          p.tags.map((t) => {
                            return <span className="tag">#{t.tag} </span>;
                          })}
                      </td>
                      <td>
                        <Button
                          className="view-btn"
                          onClick={() => {
                            getPlan(p.id);
                            setView(true);
                          }}
                        >
                          VIEW
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </Table>
        </Row>
      </Container>
    </>
  );
};

export default ProgramDatabase;
