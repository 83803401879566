import React, { useCallback, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import useGetFetch from "../../utilities/useFetch";
import CheckIcon from "@mui/icons-material/Check";
import { get } from "../../utilities/api";

const SavePlanForm = ({ submit, submitLabel, handleCancel, defaultValues }) => {
  const { data: tagsList } = useGetFetch("/template-tags", false);
  const [isNameExist, setIsNameExist] = useState(null);

  const useYupValidationResolver = (validationSchema) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const validationSchema = yup.object({
    name: yup.string().required("Please enter the name of the plan in the database"),
    displayName: yup.string().required("Please enter the name of the training phase"),
    about: yup.string().max(2000),
  });

  const levelOption = [
    { value: "beginner", label: "Beginner" },
    { value: "intermediate", label: "Intermediate" },
    { value: "advanced", label: "Advanced" },
  ];

  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: defaultValues
      ? {
          name: defaultValues.name,
          displayName: defaultValues.displayName,
          level: levelOption.filter((l) => l.value === defaultValues.level),
          goal: { value: defaultValues.goal, label: defaultValues.goal },
          auto: { value: defaultValues.coachwiseAuto, label: defaultValues.coachwiseAuto ? "Yes" : "No" },
          about: defaultValues.about,
        }
      : {
          level: { value: "beginner", label: "Beginner" },
          goal: { value: "hypertrophy", label: "Hypertrophy" },
          auto: { value: false, label: "No" },
        },
  });
  const onSubmit = (data) => {
    submit(data);
  };

  const customStyles = {
    option: (base, { isFocused }) => ({
      ...base,
      background: isFocused ? "#b01212" : "#fff",
      color: isFocused ? "#fff" : "black",
    }),
  };

  const checkUniqueName = async (name) => {
    let res = await get("/template-plans-name-check/?name=" + name);
    if (res && res.exist) setIsNameExist(true);
    else setIsNameExist(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-1">
        <Col>
          <Controller
            name="name"
            control={control}
            render={({ field, onBlur }) => (
              <span>
                <Form.Label>Training Plan Name</Form.Label>
                <Form.Control
                  name="name"
                  {...field}
                  type="text"
                  maxLength={255}
                  {...register("name")}
                  onBlur={(e) => {
                    checkUniqueName(e.target.value);
                  }}
                  disabled={defaultValues && true}
                ></Form.Control>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ fontSize: "13px", color: "grey" }}>The name of the plan in the database</div>
                  <div style={{ fontSize: "13px", marginLeft: "auto" }}>
                    {isNameExist === true && isNameExist !== null && <i className="text-danger">Name taken</i>}
                    {isNameExist !== true && isNameExist !== null && <CheckIcon color="success" sx={{ fontSize: "18px" }} />}
                  </div>
                </div>
              </span>
            )}
          />
          <ErrorMessage errors={errors} name="name" render={({ message }) => <p className="text-danger">{message}</p>} />
        </Col>
        <Col>
          <Controller
            name="displayName"
            control={control}
            render={({ field }) => (
              <span>
                <Form.Label>Phase Name</Form.Label>
                <Form.Control name="displayName" {...field} type="text" maxLength={255} disabled={defaultValues && true} />
                <div style={{ fontSize: "13px", color: "grey" }}>
                  The name of the phase that will appear on the client's program
                </div>
              </span>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="displayName"
            render={({ message }) => <p className="text-danger">{message}</p>}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Controller
            name="level"
            control={control}
            render={({ field }) => (
              <>
                <Form.Label>Training Plan Level</Form.Label>
                <Select styles={customStyles} {...field} options={levelOption}></Select>
              </>
            )}
          />
        </Col>
        <Col>
          <Controller
            name="goal"
            control={control}
            render={({ field }) => (
              <>
                <Form.Label>Training Plan Goal</Form.Label>
                <Select
                  styles={customStyles}
                  {...field}
                  name="goal"
                  options={[
                    { value: "Hypertrophy", label: "Hypertrophy" },
                    { value: "Strength", label: "Strength" },
                    { value: "General Health", label: "General Health" },
                    { value: "Power", label: "Power" },
                    { value: "Powerlifting", label: "Powerlifting" },
                  ]}
                ></Select>
              </>
            )}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs md lg={6}>
          <Controller
            name="tagsList"
            control={control}
            render={({ field }) => (
              <span>
                <Form.Label>Tags</Form.Label>
                <CreatableSelect
                  name="tagsList"
                  defaultValue={
                    defaultValues &&
                    defaultValues.tags.map((t) => {
                      return { value: t.id, label: t.tag };
                    })
                  }
                  {...field}
                  isMulti
                  options={tagsList ? convertTagsToOptions(tagsList.results) : {}}
                />
              </span>
            )}
          />
        </Col>
        <Col>
          <Controller
            name="viewPermissions"
            control={control}
            render={({ field }) => (
              <span>
                <Form.Label>View Permissions</Form.Label>
                <Form.Control name="viewPermissions" {...field} type="text" disabled placeholder="My Organisation" />
              </span>
            )}
          />
        </Col>
        <Col>
          <Controller
            name="editPermissions"
            control={control}
            render={({ field }) => (
              <span>
                <Form.Label>Edit Permissions</Form.Label>
                <Form.Control name="editPermissions" {...field} type="text" disabled placeholder="Only Me" />
              </span>
            )}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs md lg={6}>
          <Controller
            name="auto"
            control={control}
            render={({ field }) => (
              <>
                <Form.Label>Coachwise Auto Plan</Form.Label>
                <Select
                  styles={customStyles}
                  {...field}
                  name="auto"
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                ></Select>
              </>
            )}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Controller
          name="about"
          control={control}
          render={({ field }) => (
            <span>
              <Form.Label>Program Description (optional) </Form.Label>
              <Form.Control name="about" {...field} as="textarea" />
              <div style={{ textAlign: "right", color: "grey" }}>
                <small>(2000 Characters)</small>
              </div>
            </span>
          )}
        />
        <ErrorMessage errors={errors} name="about" render={({ message }) => <p className="text-danger">{message}</p>} />
      </Row>

      <Row className="mt-3">
        <Col>
          <Form.Group>
            <div>
              <Button className="form-button" type="submit" disabled={isNameExist}>
                {submitLabel}
              </Button>
              <Button className="form-button" style={{ marginLeft: "15px" }} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default SavePlanForm;

const convertTagsToOptions = (tags) => {
  const options = tags.map((t) => {
    return { value: t.id, label: t.tag };
  });
  return options;
};
