import { Col, Row, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import useGetFetch from "../../utilities/useFetch";
import * as React from "react";
import Switch from "@mui/material/Switch";
import { update } from "../../utilities/api";

const Exercises = () => {
  const { data, setRefresh } = useGetFetch("/exercises");
  const [filteredData, setFilteredData] = useState(null);
  const keyword = useRef("");

  const setData = useCallback(() => {
    if (keyword.current === "") {
      setFilteredData(data.results);
    } else {
      setFilteredData(data.results.filter((d) => d.exerciseName.toLowerCase().includes(keyword.current)));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setData();
    }
  }, [data, setData]);

  const handleSearch = (event) => {
    keyword.current = event.target.value.toLowerCase();
    setData();
  };

  const primaryMuscleGroup = (muscleGroups) => {
    muscleGroups = muscleGroups.filter((m) => m.primary === "True");
    return muscleGroups[0].name;
  };

  const secondaryMuscleGroups = (muscleGroups) => {
    muscleGroups = muscleGroups.filter((m) => m.primary === "False");
    return muscleGroups.map((mg) => mg.name).join(", ");
  };

  const handleStatus = (id, active) => {
    if (active === 1) {
      update("/exercises/" + id, { active: 0 })
        .then((res) => {
          setRefresh((old) => old + 1);
        })
        .catch((err) => {});
    } else {
      update("/exercises/" + id, { active: 1 })
        .then((res) => {
          setRefresh((old) => old + 1);
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      <div>
        <div className="mx-5">
          <h1 className="py-2">Exercise Library</h1>
          <Row>
            <div className="col-md-auto">
              <div style={{ marginLeft: "0px" }}>
                <Link to={"/exercises/create"} className="btn btn-primary">
                  Add Exercise
                </Link>
              </div>
            </div>
            <Col>
              <div className="form-group has-search" style={{ width: "31vh", marginLeft: "10px", fontWeight: "bold" }}>
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(event) => handleSearch(event)}
                />
              </div>
            </Col>
          </Row>
          <div className="p-2"></div>
          <Table className="table" modules-table striped bordered hover style={{ padding: "15px", background: "#FAFAFA" }}>
            <div> </div>
            <thead className="alert alert-danger">
              <tr>
                <th className="name-td">Name</th>
                <th className="type-td">Type</th>
                <th className="movement-type-td">Movement Type</th>
                <th className="primary-muscle-td">Primary Muscle</th>
                <th className="secondary-muscle-td">Secondry Muscle</th>
                <th className="lateral-type-td">Lateral Type</th>
                <th className="e1rm-td">E1RM</th>
                <th className="description-type-td">Description</th>
                <th className="youtube-td"></th>
                <th className="edit-button-td"></th>
                <th className="remove-button-td">Active</th>
              </tr>
            </thead>
            {!filteredData ||
              (filteredData.length === 0 && (
                <tbody>
                  <tr>
                    <td className="no-exercise" colspan={11}>
                      No Exercises
                    </td>
                  </tr>
                </tbody>
              ))}
            {filteredData &&
              filteredData.length > 0 &&
              filteredData.map((exercise) => (
                <tbody key={exercise.exerciseId}>
                  <tr>
                    <td className="name-td">
                      <b>{exercise.exerciseName}</b>
                    </td>
                    <td className="type-td">{exercise.exerciseType}</td>
                    <td className="movement-type-td">{exercise.movementType}</td>
                    <td className="primary-muscle-td">{primaryMuscleGroup(exercise.muscleGroups)}</td>
                    <td className="secondary-muscle-td">{secondaryMuscleGroups(exercise.muscleGroups)}</td>
                    <td className="lateral-type-td">{exercise.unilateralBilateral}</td>
                    <td className="e1rm-td">{exercise.E1RM}</td>
                    <td className="description-type-td">{exercise.description}</td>
                    <td className="youtube-td">
                      {exercise.videoLink && exercise.videoLink !== "" ? (
                        <a href={exercise.videoLink} target="_blank" rel="noreferrer">
                          Youtube
                        </a>
                      ) : (
                        <span>No Link</span>
                      )}
                    </td>
                    <td className="edit-button-td">
                      {exercise.organisationId && (
                        <Link to={"/exercises/" + exercise.exerciseId}>
                          <Button className="btn btn-light" type="submit" style={{ background: "#fcf5f5" }}>
                            <img src="/editIcon.png" display="block" width="20" height="20" alt="edit" />
                          </Button>
                        </Link>
                      )}
                    </td>
                    <td className="remove-button-td">
                      {exercise.active && exercise.active === 1 ? (
                        <Switch defaultChecked onChange={() => handleStatus(exercise.exerciseId, 1)} color="error" />
                      ) : (
                        <Switch onChange={() => handleStatus(exercise.exerciseId)} color="error" />
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </div>
      </div>
    </>
  );
};

export default Exercises;
