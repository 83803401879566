import { Card, Col, Row, Form, Button, Container } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import firebase from "firebase/compat/app";
import useGetFetch from "../../utilities/useFetch";
import { auth } from "../../firebase";

const ResetPassword = () => {
  const { data, isPending } = useGetFetch("/me");
  const history = useHistory();

  const passwordChangeSchema = Yup.object().shape({
    password: Yup.string().required("Please enter the password"),
    newpassword: Yup.string()
      .min(8, "New password must be at least 8 characters long")
      .required("Please enter the password"),
    confirmPassword: Yup.string()
      .required("New password does not match")
      .oneOf([Yup.ref("newpassword"), null], "New password does not match"),
  });

  const passwordChangeInitialValues = {
    password: "",
    newpassword: "",
    confirmPassword: "",
  };

  const handlePasswordChange = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const oldFirebaseCredentials = firebase.auth.EmailAuthProvider.credential(data.email, values.password);
    const newFirebaseCredentials = firebase.auth.EmailAuthProvider.credential(data.email, values.newpassword);

    auth.currentUser
      .reauthenticateWithCredential(oldFirebaseCredentials)
      .then(() => auth.currentUser.updatePassword(values.newpassword))
      .then(() => auth.currentUser.reauthenticateWithCredential(newFirebaseCredentials))
      .then(() => {
        setSubmitting(false);
        history.push("/account");
      })
      .catch((err) => {
        console.log("There was a problem changing user's password", err);
        setSubmitting(false);
        setErrors({ apiError: "There was a problem processing your request, please check the current password." });
      });
  };

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 1,
    },
    xl: {
      span: 9,
      offset: 1,
    },
  };

  return (
    <>
      {!isPending && (
        <Container fluid="md">
          <div className="p-5">
            <Row>
              <Col {...columnsViewpoint}>
                <h1>Reset Password</h1>
              </Col>
            </Row>
            <Row>
              <Col {...columnsViewpoint}>
                <Card className="shadow" style={{ background: "#fcf5f5" }}>
                  <Card.Body>
                    <Card.Text>
                      <Formik
                        validateOnChange={false}
                        validationOnBlur={false}
                        initialValues={passwordChangeInitialValues}
                        validationSchema={passwordChangeSchema}
                        onSubmit={(v, { setSubmitting, setErrors }) => handlePasswordChange(v, { setSubmitting, setErrors })}
                      >
                        {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
                          <Form noValidate onSubmit={handleSubmit} style={{ padding: "20px" }}>
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label as={Col} sm="3">
                                <b> Current Password: </b>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="password"
                                  type="password"
                                  placeholder="current password"
                                  value={values.password}
                                  onChange={handleChange}
                                  isInvalid={errors.password}
                                />
                              </Col>
                            </Form.Group>
                            {errors.password && (
                              <Row>
                                <Col>
                                  <div className="d-flex justify-content-center text-danger">{errors.password}</div>
                                </Col>
                              </Row>
                            )}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label as={Col} sm="3">
                                <b> New Password: </b>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="newpassword"
                                  type="password"
                                  placeholder="new password"
                                  value={values.newpassword}
                                  onChange={handleChange}
                                  isInvalid={errors.newpassword}
                                />
                              </Col>
                            </Form.Group>
                            {errors.newpassword && (
                              <Row>
                                <Col>
                                  <div className="d-flex justify-content-center text-danger">{errors.newpassword}</div>
                                </Col>
                              </Row>
                            )}
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label as={Col} sm="3">
                                <b> Confirm New Password: </b>
                              </Form.Label>
                              <Col>
                                <Form.Control
                                  name="confirmPassword"
                                  type="password"
                                  placeholder="re-enter new password"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  isInvalid={errors.confirmPassword}
                                />
                              </Col>
                            </Form.Group>
                            {errors.confirmPassword && (
                              <Row>
                                <Col>
                                  <div className="d-flex justify-content-center text-danger">{errors.confirmPassword}</div>
                                </Col>
                              </Row>
                            )}
                            {errors.apiError && (
                              <Row>
                                <Col>
                                  <div className="d-flex justify-content-center text-danger">{errors.apiError}</div>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col sm="3"></Col>
                              <Col>
                                <Form.Group>
                                  <Button
                                    className="me-2 form-button"
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    Reset Password
                                  </Button>
                                  <Link to={"/account"} className="btn btn-primary form-button">
                                    Cancel
                                  </Link>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default ResetPassword;
