import React from "react";
import ClientDropDown from "../client-dropdown/ClientDropDown";

const TrainingPlan = () => {
  return (
    <div style={{ marginTop: "25px", marginLeft: "50px" }}>
      <div style={{ width: "250px" }}>
        <ClientDropDown
          selectedOption={{
            value: { id: "0", name: "--SELECT CLIENT--" },
            label: "--SELECT CLIENT--",
          }}
          isPlan={true}
          size="400px"
        />
      </div>

      <div style={{ marginTop: "25px" }}>Please select a client to view their training program</div>
    </div>
  );
};

export default TrainingPlan;
