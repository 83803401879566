import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import firebase from "firebase/compat/app";

import useGetFetch from "../../utilities/useFetch";
import { auth } from "../../firebase";
import { update } from "../../utilities/api";

const InitialPasswordChange = () => {
  const { data, isPending } = useGetFetch("/me");
  const history = useHistory();

  const passwordChangeSchema = Yup.object().shape({
    password: Yup.string().min(8, "Password must be at least 8 characters long").required("Please enter the password"),
    confirmPassword: Yup.string()
      .required("Password does not match")
      .oneOf([Yup.ref("password"), null], "Password does not match"),
  });

  const passwordChangeInitialValues = {
    password: "",
    confirmPassword: "",
  };

  const handlePasswordChange = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const newFirebaseCredentials = firebase.auth.EmailAuthProvider.credential(data.email, values.password);

    auth.currentUser
      .updatePassword(values.password)
      .then(() => auth.currentUser.reauthenticateWithCredential(newFirebaseCredentials))
      .then(() => update("/me", { firstTimeLogin: false }))
      .then(() => {
        setSubmitting(false);
        history.go(0);
      })
      .catch((err) => {
        console.log("There was a problem changing user's password");
        setSubmitting(false);
        setErrors({ apiError: "There was a problem processing your request" });
      });
  };

  return (
    <>
      {!isPending && (
        <Container className="p-5">
          <Row>
            <Col>
              <h1 className="text-center">
                Welcome, {data.firstName} {data.lastName}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">You have logged in with a temporary password. Please set a new password.</Col>
          </Row>
          <Formik
            validateOnChange={false}
            validationOnBlur={false}
            initialValues={passwordChangeInitialValues}
            validationSchema={passwordChangeSchema}
            onSubmit={(v, { setSubmitting, setErrors }) => handlePasswordChange(v, { setSubmitting, setErrors })}
          >
            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <div className="p-2 d-flex justify-content-center">
                      <Form.Group>
                        <Form.Control
                          name="password"
                          className="text-center"
                          type="password"
                          placeholder="Enter New Password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={errors.password}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                {errors.password && (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-center text-danger">{errors.password}</div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className="p-2 d-flex justify-content-center">
                      <Form.Group>
                        <Form.Control
                          name="confirmPassword"
                          className="text-center"
                          type="password"
                          placeholder="Re-enter New Password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={errors.password}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                {errors.confirmPassword && (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-center text-danger">{errors.confirmPassword}</div>
                    </Col>
                  </Row>
                )}
                {errors.apiError && (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-center text-danger">{errors.apiError}</div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className="p-1 d-flex justify-content-center">
                      <Form.Group>
                        <Button type="submit" disabled={isSubmitting}>
                          Change Password
                        </Button>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
};

export default InitialPasswordChange;
