import { Button, Card, Tab, Col, Row, Nav, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import InfoModal from "../modal/InfoModal";
import useGetFetch from "../../utilities/useFetch";
import DateUtil from "../../utilities/date";
import ConfirmationModal from "../modal/ConfirmationModal";
import { remove } from "../../utilities/api";
import useAccountStatus from "../../hooks/user/useAccountStatus";

const Coaches = () => {
  const { data: me, isPending: mePending } = useGetFetch("/me");
  const {
    data: coachList,
    setRefresh,
    setData: setCoachData,
  } = useGetFetch("/users?filters[types][]=Coach&filters[types][]=Admin");

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState(false);

  const [show, setShow] = useState(false);
  const [coachsList, setcoachList] = useState([]);

  const { AccountStatusModal, AccountStatusButton } = useAccountStatus(coachList?.results ?? null, setCoachData);

  const handleCancel = () => {
    setShow(false);
    setShowDeleteUserConfirmationModal(false);
  };

  const handleDeleteUserClick = (userId, name) => {
    setSelectedUserId(userId);
    setSelectedUserName(name);
    setShowDeleteUserConfirmationModal(true);
  };

  const handleConfirmedUserDelete = () => {
    remove(`/users/${selectedUserId}`)
      .then(() => {
        handleCancel();
        setRefresh((old) => old + 1);
      })
      .catch((err) => console.log(err));
  };

  const rendercoachsList = () => {
    return (
      <>
        {coachsList.length === 0 && <h6> No Active coachs yet. </h6>}
        {coachsList.length > 0 && (
          <ListGroup vertical="sm">
            {coachsList.map((coach) => (
              <ListGroupItem>
                <b>
                  {" "}
                  {coach.firstName} {coach.lastName}{" "}
                </b>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </>
    );
  };

  const showModal = (cl) => {
    setcoachList(cl);
    setShow(true); // To show the modal
  };

  return (
    <>
      <AccountStatusModal />

      <InfoModal
        show={show}
        title="Assigned Clients"
        body={rendercoachsList()}
        size="sm"
        cancelLabel="Close"
        handleCancel={handleCancel}
      />

      <ConfirmationModal
        show={showDeleteUserConfirmationModal}
        title={`Confirm Delete for ${selectedUserName}`}
        body="This action cannot be reversed and will unassigned them from their clients."
        handleCancel={handleCancel}
        handleConfirmation={handleConfirmedUserDelete}
      />

      {!mePending && coachList && coachList.results.length > 0 && (
        <div>
          <div className="d-flex flex-row" style={{}}>
            <h4 className="py-2" style={{ fontWeight: "bold", marginLeft: "230px", marginRight: "10px" }}>
              Coaches
            </h4>
            <div style={{ marginTop: "5px" }}>
              <Button as={NavLink} to="/users/coaches/create">
                Add
              </Button>
            </div>
          </div>
          <hr />
          {coachList && coachList.results.length > 0 && (
            <div>
              <Tab.Container defaultActiveKey="0" style={{ padding: "10px" }}>
                <div className="d-flex flex-row w-100">
                  <div className="tab-user-list">
                    <Nav variant="pills" className="flex-column">
                      {coachList.results.map((coach, index) => (
                        <div key={index}>
                          <Nav.Item style={{ margin: "10px", textAlign: "center" }}>
                            <Nav.Link eventKey={index}>
                              {coach.firstName} {coach.lastName}
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                      ))}
                    </Nav>
                  </div>
                  <div className="flex-fill">
                    {coachList.results.map((coach, index) => (
                      <div className="tab-user-content " key={index}>
                        <Tab.Content>
                          <Tab.Pane eventKey={index}>
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Col md={3} lg={2} xl={2} xxl={1}>
                                    <div className="d-flex flex-row">
                                      <img
                                        className="profile-pic"
                                        src={`https://ui-avatars.com/api/?name=${coach.firstName}+${coach.lastName}&bold=true`}
                                        alt="profile-pic"
                                        width="72"
                                        height="72"
                                        style={{ marginTop: "6px" }}
                                      />
                                      <div className="ms-3 flex-column">
                                        <h2
                                          style={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {coach.firstName + " " + coach.lastName}
                                        </h2>
                                        <h4>{coach.email}</h4>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="pt-3">
                                  <Col xs={12} lg={4} xxl={3}>
                                    <b> Gender: </b>
                                  </Col>
                                  <Col>{coach.gender}</Col>
                                </Row>
                                <Row>
                                  <Col xs={12} lg={4} xxl={3}>
                                    <b> Date of Birth: </b>
                                  </Col>
                                  <Col>{DateUtil.fromApiToString(coach.dob)}</Col>
                                </Row>
                                <Row>
                                  <Col xs={12} lg={4} xxl={3}>
                                    <b> Mobile: </b>
                                  </Col>
                                  <Col>{coach.mobile}</Col>
                                </Row>
                                {me.type === "Admin" && (
                                  <Row>
                                    <Col xs={12} lg={4} xxl={3}>
                                      <b> Account Status: </b>
                                    </Col>
                                    <Col>
                                      {coach.disabled ? "Disabled" : "Active"}
                                      <AccountStatusButton
                                        userId={coach.id}
                                        userName={coach.firstName}
                                        isUserAccountDisabled={coach.disabled}
                                      />
                                    </Col>
                                  </Row>
                                )}
                                <Button onClick={() => showModal(coach.clients)} style={{ marginRight: "10px" }}>
                                  {" "}
                                  Assigned Clients{" "}
                                </Button>
                                <Link
                                  to={"/users/coaches/" + coach.id}
                                  className="btn btn-primary"
                                  style={{ width: "140px" }}
                                >
                                  Edit
                                </Link>
                                <Button
                                  className="btn btn-primary"
                                  style={{ marginLeft: "5px", width: "140px" }}
                                  onClick={() => handleDeleteUserClick(coach.id, coach.firstName + " " + coach.lastName)}
                                >
                                  Delete
                                </Button>
                              </Card.Body>
                            </Card>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    ))}
                  </div>
                </div>
              </Tab.Container>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Coaches;
