import { Card, Col, Row, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import useGetFetch from "../../utilities/useFetch";
import DateUtil from "../../utilities/date";

const MyAccount = () => {
  const { data } = useGetFetch("/me");

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 7,
      offset: 2,
    },
  };

  return (
    <>
      {data && (
        <Container fluid={"xl"}>
          {[data].map((activeUser, index) => (
            <div key={index} className="p-5">
              <Row>
                <Col {...columnsViewpoint}>
                  <h1>My Account</h1>
                </Col>
              </Row>
              <Row>
                <Col {...columnsViewpoint}>
                  <Card className="shadow" style={{ background: "#fcf5f5" }}>
                    <Card.Body>
                      <Card.Text>
                        <Form style={{ padding: "10px" }}>
                          <Form.Group as={Row}>
                            <div className="d-flex flex-row w-100 justify-content-center">
                              <img
                                className="profile-pic"
                                src={`https://ui-avatars.com/api/?name=${activeUser.firstName}+${activeUser.lastName}&bold=true`}
                                alt="profile-pic"
                                width="72"
                                height="72"
                                style={{ marginTop: "6px" }}
                              />
                              <div className="ms-3 d-flex flex-column">
                                <h2
                                  style={{
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {activeUser.firstName + " " + activeUser.lastName}
                                </h2>
                                <h4>{activeUser.email}</h4>
                              </div>
                            </div>
                          </Form.Group>
                          <Form.Group as={Row} className="my-3">
                            <Form.Label as={Col} sm="3">
                              <b> Phone No: </b>
                            </Form.Label>
                            <Form.Label as={Col}> {activeUser.mobile} </Form.Label>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label as={Col} sm="3">
                              <b> Gender: </b>
                            </Form.Label>
                            <Form.Label as={Col}> {activeUser.gender} </Form.Label>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-2">
                            <Form.Label as={Col} sm="3">
                              <b> Date of Birth: </b>
                            </Form.Label>
                            <Form.Label as={Col}> {DateUtil.fromApiToString(activeUser.dob)} </Form.Label>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-2">
                            <Col sm="3"></Col>
                            <Form.Label as={Col}>
                              <Link to={"/account/edit/" + activeUser.id} className="btn btn-primary form-button me-2">
                                Edit Details
                              </Link>
                              <Link to="/account/reset-password" className="btn btn-primary form-button">
                                Reset Password
                              </Link>
                            </Form.Label>
                          </Form.Group>
                        </Form>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          ))}
        </Container>
      )}
    </>
  );
};

export default MyAccount;
