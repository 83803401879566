import { Accordion, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useGetFetch from "../../utilities/useFetch";

const TrainingHistoryMicrocycle = () => {
  const { microcycle_id, user_id } = useParams();
  const { data, isPending } = useGetFetch(`/users/${user_id}/weeks/${microcycle_id}`);

  const microcycleName = new URLSearchParams(window.location.search).get("name");
  const mesocycleName = new URLSearchParams(window.location.search).get("mesocycleName");

  const max_sets = () => {
    var no_max_sets = 0;
    for (var i = 0; i < data.results.length; i++) {
      for (var j = 0; j < data.results[i].exercises.length; j++) {
        if (data.results[i].exercises[j].workoutLogs != null) {
          let k = data.results[i].exercises[j].workoutLogs.length;
          if (k > no_max_sets) {
            no_max_sets = k;
          }
        }
      }
    }
    return no_max_sets;
  };

  const table_headings = () => {
    const results = [];
    results.push(<th className="training-history-th">Exercises</th>);

    for (var i = 0; i < max_sets(); i++) {
      results.push(<th>Set {i + 1}</th>);
    }

    results.push(<th className="training-history-th">Comments</th>);

    return results;
  };

  const createTableSets = (sets) => {
    const results = [];
    if (sets != null && sets.length > 0) {
      for (const set of sets) {
        results.push(
          <td style={{ textAlign: "center", fontSize: "16px" }}>
            {set.rep} x {set.weight}kg @ {set.intensity} {set.intensityValue}
          </td>
        );
      }

      for (var i = 0; i < max_sets() - sets.length; i++) {
        results.push(<td></td>);
      }
    } else {
      results.push(
        <td colSpan={max_sets()}>
          <span className="placeholder-text">
            <center>No workouts recorded</center>
          </span>
        </td>
      );
    }
    return results;
  };

  const setSummary = (data) => {
    const muscleGroupsSetsDone = new Map();
    const muscleGroupsSetsTotal = new Map();
    const htmlResults = [];

    for (const r of data.results) {
      for (const i of r.exercises) {
        if (muscleGroupsSetsTotal.has(i.muscleGroupName)) {
          muscleGroupsSetsTotal.set(i.muscleGroupName, i.sets + muscleGroupsSetsTotal.get(i.muscleGroupName));
        } else {
          muscleGroupsSetsTotal.set(i.muscleGroupName, i.sets);
        }

        if (i.workoutLogs != null) {
          if (muscleGroupsSetsDone.has(i.muscleGroupName)) {
            muscleGroupsSetsDone.set(i.muscleGroupName, muscleGroupsSetsDone.get(i.muscleGroupName) + i.workoutLogs.length);
          } else {
            muscleGroupsSetsDone.set(i.muscleGroupName, i.workoutLogs.length);
          }
        }
      }
    }

    for (const j of muscleGroupsSetsTotal) {
      htmlResults.push(
        <tr>
          <td style={{ textAlign: "center" }}>
            <b>{j[0]}</b>
          </td>
          <td style={{ textAlign: "center" }}>
            {muscleGroupsSetsDone.has(j[0]) ? muscleGroupsSetsDone.get(j[0]) : 0}/{j[1]}
          </td>
        </tr>
      );
    }

    return htmlResults;
  };

  const getTrainingCompletionSummary = () => {
    let results = [];

    if (data.results.length === 0) {
      results.push(
        <tr>
          <td colSpan={3}>
            <span className="placeholder-text">
              <center>There are no days set for this microcycle.</center>
            </span>
          </td>
        </tr>
      );
    } else {
      for (const d of data.results) {
        let exercisesCompleteCount = 0;
        let exercisesTotal = 0;
        let setsCompleteCount = 0;
        let setsTotal = 0;

        for (const e of d.exercises) {
          setsTotal = setsTotal + e.sets;
          exercisesTotal = exercisesTotal + 1;

          const currentExerciseCompleteSetsCount = e.workoutLogs ? e.workoutLogs.length : 0;
          setsCompleteCount = setsCompleteCount + currentExerciseCompleteSetsCount;

          if (currentExerciseCompleteSetsCount === e.sets) {
            exercisesCompleteCount = exercisesCompleteCount + 1;
          }
        }

        results.push(
          <tr>
            <td>{d.day}</td>
            <td>
              {exercisesCompleteCount}/{exercisesTotal}
            </td>
            <td>
              {setsCompleteCount}/{setsTotal}
            </td>
          </tr>
        );
      }
    }

    return results;
  };

  return (
    <>
      <h1>
        {mesocycleName} - {microcycleName}
      </h1>
      <hr />
      <div>
        <h4>
          <b>Training Completion Summary</b>
        </h4>
        <Table className="training-history-table table" modules-table bordered hover responsive>
          <thead className="alert alert-danger training-history-tableheading">
            <tr>
              <td>Day #</td>
              <td>Exercises Completed</td>
              <td>Sets Completed</td>
            </tr>
          </thead>
          <tbody>{!isPending && getTrainingCompletionSummary()}</tbody>
        </Table>
      </div>
      <hr />
      <div>
        <h4>
          <b>Check In</b>
        </h4>
        <Table className="training-history-table table" modules-table bordered hover responsive>
          <thead className="alert alert-danger training-history-tableheading">
            <tr>
              <td>Question</td>
              <td>Answer</td>
            </tr>
          </thead>
          <tbody>
            {!isPending && data.results.length !== 0 && data.results[0].questionnaireAnswers == null && (
              <tr>
                <td colSpan={2}>
                  <span className="placeholder-text">
                    <center>Client has not checked in yet.</center>
                  </span>
                </td>
              </tr>
            )}
            {!isPending &&
              data.results.length !== 0 &&
              data.results[0].questionnaireAnswers &&
              data.results[0].questionnaireAnswers.map((questionnaireAnswer) => (
                <tr>
                  <td>{questionnaireAnswer.question}</td>
                  <td>{questionnaireAnswer.answer}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <hr />
      <div>
        <h4>
          <b>Summary</b>
        </h4>
        <Table className="training-history-table table" modules-table bordered hover responsive>
          <thead className="alert alert-danger training-history-tableheading">
            <tr>
              <td>Muscle Groups</td>
              <td>Sets Completed</td>
            </tr>
          </thead>
          <tbody>{!isPending && data && setSummary(data)}</tbody>
        </Table>
      </div>
      <hr />
      {!isPending &&
        data.results.length !== 0 &&
        data.results.map((days, index) => (
          <Accordion>
            <Accordion.Item eventKey={days.id}>
              <Accordion.Header>
                <span style={{ fontWeight: "bold", fontSize: "20px", textTransform: "uppercase" }}>
                  Day #{days.day} - {days.name}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <Table
                  className="training-history-table table"
                  modules-table
                  bordered
                  hover
                  responsive
                  style={{ background: "#FAFAFA" }}
                >
                  <thead className="alert alert-danger training-history-tableheading">
                    <tr>{table_headings()}</tr>
                  </thead>
                  <tbody>
                    {days.exercises.length === 0 && (
                      <tr>
                        <td colSpan={max_sets() + 2}>
                          <center>No exercises added</center>
                        </td>
                      </tr>
                    )}
                    {days.exercises.map((exercise, index) => (
                      <tr>
                        <td>
                          <b>{exercise.exerciseName}</b>
                        </td>
                        {createTableSets(exercise.workoutLogs)}
                        {(exercise.comment === null || exercise.comment === "") && (
                          <td>
                            <span className="placeholder-text">
                              <center>No Comment</center>
                            </span>
                          </td>
                        )}
                        {exercise.comment !== null && exercise.comment !== "" && <td>{exercise.comment}</td>}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={max_sets() + 2}>
                        <b>Client comments for the day: </b>
                        {days.comment === "" || days.comment === null ? (
                          <span className="placeholder-text">No comments</span>
                        ) : (
                          days.comment
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
    </>
  );
};

export default TrainingHistoryMicrocycle;
