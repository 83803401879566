import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import FormModal from "../../components/modal/FormModal";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { create, update, remove } from "../../utilities/api";
import { addDayFormSchema } from "../../schemas/schemas";

const actionType = {
  plan: "plan",
  program: "program",
};

const useManageDay = (selectDay, setSelectDay, setDaysRefresh, weeksSetRefresh) => {
  const [showAddDay, setShowAddDay] = useState(false);
  const [showEditDay, setShowEditDay] = useState(false);
  const [showDeleteDayConfirmationModal, setShowDeleteDayConfirmationModal] = useState(false);

  const userId = useRef("");
  const trainingPlanId = useRef("");
  const microcycleId = useRef("");
  const templateWeekId = useRef("");
  const planOrProgram = useRef("");
  const isSubmitting = useRef(false);

  const handleRefSetting = ({ type, values }) => {
    if (type === actionType.plan) {
      userId.current = values.user_id;
      trainingPlanId.current = values.training_plan_id;
      microcycleId.current = values.microcycle_id;
      planOrProgram.current = actionType.plan;
    } else if (type === actionType.program) {
      templateWeekId.current = values.template_week_id;
      planOrProgram.current = actionType.program;
    }
  };

  const handleAddDayPress = ({ type, values }) => {
    handleRefSetting({ type, values });
    setShowAddDay(true);
  };

  const handleDeleteDayPress = ({ type, values }) => {
    handleRefSetting({ type, values });
    setShowDeleteDayConfirmationModal(true);
  };

  const handleEditDayPress = ({ type, values }) => {
    handleRefSetting({ type, values });
    setShowEditDay(true);
  };

  const handleAddDaySubmit = async (values, { setSubmitting, setErrors }) => {
    if (isSubmitting.current) return; // Prevent spam

    // setIsSubmitting(true);
    isSubmitting.current = true;
    let res;
    try {
      if (planOrProgram.current === actionType.plan) {
        res = await create(
          `/users/${userId.current}/plans/${trainingPlanId.current}/weeks/${microcycleId.current}/days`,
          values
        );
      } else if (planOrProgram.current === actionType.program) {
        values.templatePlanWeekId = templateWeekId.current;
        res = await create(`/template-plan-days`, values);
      }

      if (res.id) {
        setDaysRefresh((old) => old + 1);
        setShowAddDay(false);
        weeksSetRefresh((old) => old + 1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      isSubmitting.current = false;
    }
  };

  const handleEditDaySubmit = async (values, { setSubmitting, setErrors }) => {
    if (isSubmitting.current) return; // Prevent spam

    // setIsSubmitting(true);
    isSubmitting.current = true;
    selectDay.name = values.name;
    let res;
    try {
      if (planOrProgram.current === actionType.plan) {
        res = await update(
          `/users/${userId.current}/plans/${trainingPlanId.current}/weeks/${microcycleId.current}/days/${selectDay.id}`,
          selectDay
        );
      } else if (planOrProgram.current === actionType.program) {
        res = await update(`/template-plan-days/${selectDay.id}`, selectDay);
      }
      if (res.id || res.success) {
        setDaysRefresh((old) => old + 1);
        setShowEditDay(false);
        weeksSetRefresh((old) => old + 1);
        setSelectDay(selectDay);
      }
    } catch (err) {
      console.log(err);
    } finally {
      isSubmitting.current = false;
    }
  };

  const handleDeleteDaySubmit = async () => {
    let res;
    try {
      if (planOrProgram.current === actionType.plan) {
        res = await remove(
          `/users/${userId.current}/plans/${trainingPlanId.current}/weeks/${microcycleId.current}/days/${selectDay.id}`
        );
      } else if (planOrProgram.current === actionType.program) {
        res = await remove(`/template-plan-days/${selectDay.id}`);
      }
      if (res.success) {
        setShowDeleteDayConfirmationModal(false);
        setDaysRefresh((old) => old + 1);
        weeksSetRefresh((old) => old + 1);
        setSelectDay(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      isSubmitting.current = false;
    }
  };

  const AddDayButton = ({ type, values }) => (
    <Button className="microcycle-header-group header-btn-col" onClick={() => handleAddDayPress({ type, values })}>
      Add Day
    </Button>
  );

  const EditDayButton = ({ type, values }) => (
    <Button className="microcycle-header-group header-btn-col" onClick={() => handleEditDayPress({ type, values })}>
      Edit Day
    </Button>
  );

  const DeleteDayButton = ({ type, values }) => (
    <Button
      className="microcycle-header-group  header-btn-col"
      onClick={() => selectDay && handleDeleteDayPress({ type, values })}
    >
      Delete Day
    </Button>
  );

  const AddDayModal = () => (
    <FormModal
      formInputsSchema={addDayFormSchema}
      show={showAddDay}
      title="Add Day"
      submitLabel="Confirm"
      handleCancel={() => setShowAddDay(false)}
      handleSubmit={handleAddDaySubmit}
    />
  );

  const EditDayModal = () => (
    <FormModal
      formInputsSchema={addDayFormSchema}
      show={showEditDay}
      title="Edit Day"
      submitLabel="Confirm"
      handleCancel={() => setShowEditDay(false)}
      handleSubmit={handleEditDaySubmit}
    />
  );

  const DeleteDayModal = () => (
    <ConfirmationModal
      show={showDeleteDayConfirmationModal}
      title="Confirm Day Delete"
      body="This action cannot be reversed and will delete any training history for the client for this period."
      handleCancel={() => setShowDeleteDayConfirmationModal(false)}
      handleConfirmation={handleDeleteDaySubmit}
    />
  );

  return { AddDayModal, AddDayButton, EditDayModal, EditDayButton, DeleteDayButton, DeleteDayModal };
};

export default useManageDay;
