import React from "react";
import { Button } from "react-bootstrap";

const PlanHeader = (props) => {
  return (
    <div className="plan-header-ctn">
      <div style={{ paddingLeft: 5 }}>{props.title}</div>
      <div>
        <Button className="header-btn" onClick={props.btnFunction}>
          {props.btn}
        </Button>
      </div>
    </div>
  );
};

export default PlanHeader;
