import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useGetFetch from "../../utilities/useFetch";
import MesocycleList from "../mesocycle-list/MesocycleList";

const TrainingHistory = () => {
  const { user_id } = useParams();
  const { data, isPending } = useGetFetch(`/users/${user_id}/plans?sortDirection=desc`);

  return (
    <>
      <Container fluid="true" className="me-3">
        <div className="pt-3 d-flex">
          <h2 className="font-anton pt-2 training-plan-title">TRAINING HISTORY</h2>
        </div>
        <hr />
        {!isPending && <MesocycleList data={data.results} nextPageBaseUrl={`/client/${user_id}/training-history`} />}
      </Container>
    </>
  );
};

export default TrainingHistory;
