import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { get } from "../../../utilities/api";
import { Button } from "react-bootstrap";
import Remove from "@mui/icons-material/Remove";
import { calculateIntensity, findE1rm, findVolumnLoad } from "../../../utilities/math";

const HistoryTable = ({ phases, micros, currentPlan, microId, microIndex, selectDays }) => {
  const { user_id } = useParams();
  const [toggle, setToggle] = useState(false);
  const [phaseSelected, setPhaseSelected] = useState([{ value: currentPlan.id, label: currentPlan.name }]);
  const [microSelected, setMicroSelected] = useState([{ value: microId, label: `Microcycle ${microIndex}` }]);
  const [daySelected, setDaySelected] = useState([]);
  const [summary, setSummary] = useState();
  const { control, handleSubmit } = useForm();

  const onSubmit = async () => {
    const results = [];
    if (
      phaseSelected[0].value !== 0 &&
      microSelected[0] &&
      microSelected[0].value !== 0 &&
      daySelected[0] &&
      daySelected[0].value !== 0
    ) {
      let day = await get(
        `/users/${user_id}/plans/${phaseSelected[0].value}/weeks/${microSelected[0].value}/days/${daySelected[0].value}`
      );

      for (const d of day.exercises) {
        if (d.exerciseProperties && d.exerciseProperties.isMultiExercise) {
          if (d.exerciseProperties.clientMultiSelectedExerciseId) {
            results.push({
              exerciseName: d.exerciseProperties.clientMultiSelectedExercise.exerciseName,
              workoutLogs: d.workoutLogs,
              comment: d.clientComment,
            });
          } else {
            results.push({
              exerciseName: d.exerciseProperties.itemName,
              workoutLogs: d.workoutLogs,
              comment: d.clientComment,
            });
          }
        } else {
          results.push({
            exerciseName: d.exerciseProperties?.exercise.exerciseName,
            workoutLogs: d.workoutLogs,
            comment: d.clientComment,
          });
        }
      }

      setSummary(results);
    } else {
      setSummary();
    }
  };

  const selectedOptions = phases
    ? phases.results.map((p) => {
        return { value: p.id, label: p.name };
      })
    : [{ value: 0, label: "No Option" }];

  const initialMicroOption = micros
    ? micros.results
        .slice()
        .reverse()
        .map((p, i) => {
          return { value: p.id, label: `Microcycle ${micros.results.length - i}` };
        })
    : [{ value: 0, label: "No Option" }];
  const [microOptions, setMicroOptions] = useState(initialMicroOption);

  const initialDaysOptions = selectDays
    ? selectDays.results.map((p, i) => {
        return { value: p.id, label: p.name, index: i };
      })
    : [{ value: 0, label: "No Option", index: -1 }];

  const [daysOptions, setDaysOptions] = useState(initialDaysOptions);

  async function fetchMicrocycle(id) {
    let res = await get(`/users/${user_id}/plans/${id}/weeks/`);
    const selectedOptions =
      res && res.results.length > 0
        ? res.results
            .slice()
            .reverse()
            .map((p, i) => {
              return { value: p.id, label: `Microcycle ${res.results.length - i}` };
            })
        : [];
    setMicroOptions(selectedOptions);
    setMicroSelected([]);
    setDaySelected([]);
  }

  useEffect(() => {
    setPhaseSelected([{ value: currentPlan.id, label: currentPlan.name }]);
    setMicroSelected([{ value: microId, label: `Microcycle ${microIndex}` }]);
    setDaySelected([]);
    setMicroOptions(initialMicroOption);
    fetchDays(microId);
    setSummary([]);
  }, [microId, microIndex, currentPlan]); //eslint-disable-line react-hooks/exhaustive-deps

  async function fetchDays(microId) {
    let res = await get(`/users/${user_id}/plans/${phaseSelected}/weeks/${microId}/days`);
    const selectedOptions =
      res && res.results.length > 0
        ? res.results.map((p, i) => {
            return { value: p.id, label: p.name, index: i };
          })
        : [];
    setDaysOptions(selectedOptions);
    setDaySelected([]);
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fff",
      borderRadius: "5px",
      borderColor: "#fff",
      boxShadow: state.isFocused ? null : null,
      fontSize: "12px",
      height: "10px",
      minHeight: "24px",
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: "5px",
      padding: 0,
    }),
    option: (base, { isFocused }) => ({
      ...base,
      background: isFocused ? "#b01212" : "#fff",
      color: isFocused ? "#fff" : "black",
      padding: "2px 5px",
    }),
    valueContainer: (base, state) => ({
      ...base,
      height: "24px",
      padding: "0px 6px",
      margin: "0px",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      height: "24px",
    }),
  };

  const showE1rm = (workoutLogs) => {
    const result = findE1rm(workoutLogs);
    if (result && result !== "NA") return <div className="e1rm-vl">E1RM: {result}</div>;
  };

  const showVL = (workoutLogs) => {
    const result = findVolumnLoad(workoutLogs);
    if (result && result !== "NA")
      return (
        <div className="e1rm-vl">
          Volume Load: {result} {calculateIntensity(workoutLogs)}
        </div>
      );
  };

  return !toggle ? (
    <div className="no-history-container">
      <table>
        <thead>
          <div className="history-header">
            <div className="header-content">Client History</div>
            <AddIcon
              className="toggle-btn"
              onClick={() => {
                setToggle(true);
              }}
            />
          </div>
        </thead>
      </table>
    </div>
  ) : (
    <div className="main-history-container">
      <div className="select-form-container">
        <div className="select-table">
          <div>
            <table className="select-area">
              <thead>
                <tr>
                  <th className="history-header">
                    <div className="header-content">Client History</div>
                    <Remove
                      className="toggle-btn"
                      onClick={() => {
                        setToggle(false);
                      }}
                    />
                  </th>
                </tr>
              </thead>
            </table>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: "10px", display: "flex", marginTop: "10px" }}>
              <div>
                <div className="history-input">
                  <Controller
                    name="phase"
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={customStyles}
                        {...field}
                        value={phaseSelected}
                        options={selectedOptions}
                        onChange={async (e) => {
                          setPhaseSelected([{ value: e.value, label: e.label }]);
                          fetchMicrocycle(e.value);
                          setDaysOptions([]);
                        }}
                      ></Select>
                    )}
                  />
                </div>
                <div className="history-input">
                  <Controller
                    name="micro"
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={customStyles}
                        {...field}
                        value={microSelected}
                        options={microOptions}
                        onChange={async (e) => {
                          setMicroSelected([{ value: e.value, label: e.label }]);
                          fetchDays(e.value);
                        }}
                        disabled={microOptions.length === 0}
                      ></Select>
                    )}
                  />
                </div>
                <div className="history-input">
                  <Controller
                    name="day"
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={customStyles}
                        {...field}
                        value={daySelected}
                        options={daysOptions}
                        onChange={async (e) => {
                          setDaySelected([{ value: e.value, label: e.label, index: e.index }]);
                        }}
                        disabled={daysOptions.length === 0}
                      ></Select>
                    )}
                  />
                </div>
              </div>
              <Button type="submit" className="submit-btn">
                <SearchIcon />
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="history-container">
        {summary && summary.length > 0 ? (
          summary.map((e, i) => {
            return (
              <table className="exercise-history-table" key={e.exerciseName + i}>
                <div>
                  <div className="header-content" style={{ textAlign: "center" }}>
                    {e.exerciseName}
                  </div>
                </div>
                <div className="scroll-bar">
                  <table cellPadding="0" cellSpacing="0">
                    <thead className="history-subheader">
                      <tr>
                        <th>Set</th>
                        <th>Reps</th>
                        <th>Load</th>
                        <th>Int</th>
                      </tr>
                    </thead>
                    <tbody className="worklogs-content">
                      {e.workoutLogs &&
                        e.workoutLogs.length > 0 &&
                        e.workoutLogs.map((w, i) => {
                          return (
                            <tr key={e.exerciseName + i} className="worklogs-value">
                              <td>{w.set}</td>
                              <td>{w.rep}</td>
                              <td>{w.weight}</td>
                              <td>{w.intensityValue}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {(!e.workoutLogs || e.workoutLogs.length === 0) && (
                    <div className="no-training-result">No workouts recorded</div>
                  )}

                  {showE1rm(e.workoutLogs)}
                  {showVL(e.workoutLogs)}

                  <div className="comment-box">{e.comment === null || e.comment === "" ? "No Comment" : e.comment}</div>
                </div>
              </table>
            );
          })
        ) : (
          <i style={{ paddingLeft: "20px", paddingTop: "60px" }}>NO HISTORY</i>
        )}
      </div>
    </div>
  );
};

export default HistoryTable;
