import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import FormModal from "../../components/modal/FormModal";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { create, update, remove } from "../../utilities/api";
import ExerciseForm from "../../components/training-plan/microcycle/ExerciseForm";

const actionType = {
  plan: "plan",
  program: "program",
};

const useManageExercise = (selectDay, handleAddExerciseSubmit, handleEditExerciseSubmit, handleDeleteExerciseSubmit) => {
  const [showAddExerciseForm, setShowAddExerciseForm] = useState(false);
  const [showEditExerciseForm, setShowEditExerciseForm] = useState(false);
  const [showDeleteExerciseConfirmationModal, setShowDeleteExerciseConfirmationModal] = useState(false);

  const userId = useRef("");
  const trainingPlanId = useRef("");
  const microcycleId = useRef("");
  const dayExerciseId = useRef("");
  const templateWeekId = useRef("");
  const editDefaultValue = useRef({});
  const planOrProgram = useRef("");
  const isSubmitting = useRef(false);

  const handleRefSetting = ({ type, values }) => {
    if (type === actionType.plan) {
      userId.current = values.user_id;
      trainingPlanId.current = values.training_plan_id;
      microcycleId.current = values.microcycle_id;
      planOrProgram.current = actionType.plan;
    } else if (type === actionType.program) {
      templateWeekId.current = values.template_week_id;
      planOrProgram.current = actionType.program;
    }
  };

  const handleAddExercisePress = ({ type, values }) => {
    handleRefSetting({ type, values });
    setShowAddExerciseForm(true);
  };

  const handleDeleteExercisePress = ({ type, values }) => {
    dayExerciseId.current = values.dayExerciseId;
    handleRefSetting({ type, values });
    setShowDeleteExerciseConfirmationModal(true);
  };

  const handleEditExercisePress = ({ type, values }) => {
    editDefaultValue.current = values.editDefaultValue;
    handleRefSetting({ type, values });
    setShowEditExerciseForm(true);
  };

  const addExerciseSubmit = async (values) => {
    if (isSubmitting.current) return; // Prevent spam

    isSubmitting.current = true;
    if (values.weight === "") {
      delete values.weight;
    }
    if (values.priority === "0") {
      values.priority = 0;
    }
    values.type = values.type.toLowerCase();
    values.subType = values.subType.value;
    values.priority = Number(values.priority.value);
    values.superSet = values.superSet.value;
    values.isVersion2Structure = true;
    const exerciseProperties = {
      isMultiExercise: values.exerciseType.value === "Multi Option" ? true : false,

      exerciseId: values.exerciseId[0]?.value ?? null,
      exerciseId2: values.exerciseType.value === "Multi Option" ? values.exerciseId[1]?.value ?? null : null,
      exerciseId3: values.exerciseType.value === "Multi Option" ? values.exerciseId[2]?.value ?? null : null,
      exerciseId4: values.exerciseType.value === "Multi Option" ? values.exerciseId[3]?.value ?? null : null,
      exerciseId5: values.exerciseType.value === "Multi Option" ? values.exerciseId[4]?.value ?? null : null,
      sets: values.sets,
      reps: values.reps,
      weight: values.weight,
      intensity: values.intensity.value,
      intensityValue: values.intensityValue,
      comment: values.comment ?? "",
      itemName: values.itemName,
      showE1RM: values.e1rmvl.value === 2 || values.e1rmvl.value === 4 ? true : false,
      showVolumeLoad: values.e1rmvl.value === 3 || values.e1rmvl.value === 4 ? true : false,
    };
    values.exerciseProperties = exerciseProperties;
    values.intensity = values.intensity.value;
    values.exerciseId =
      values.exerciseType.value === "Single Option" ? values.exerciseId.value : values.exerciseId[0]?.value;

    let res;
    try {
      if (planOrProgram.current === actionType.plan) {
        res = await create(
          `/users/${userId.current}/plans/${trainingPlanId.current}/weeks/${microcycleId.current}/days/${selectDay.id}/exercises`,
          values
        );
      } else if (planOrProgram.current === actionType.program) {
        values.templatePlanDayId = selectDay.id;
        res = await create(`/template-plan-items`, values);
      }
      if (res.id || res.success) {
        handleAddExerciseSubmit(values, res.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      isSubmitting.current = false;
      setShowAddExerciseForm(false);
    }
  };

  const editExerciseSubmit = async (values) => {
    if (isSubmitting.current) return; // Prevent spam

    // setIsSubmitting(true);
    isSubmitting.current = true;
    if (values.weight === "") {
      values.weight = null;
    }
    if (values.priority === "0") {
      values.priority = 0;
    }

    values.type = values.type.toLowerCase();
    values.subType = values.subType.value;
    values.priority = Number(values.priority.value);
    values.superSet = values.superSet.value;
    values.isVersion2Structure = true;
    const exerciseProperties = {
      isMultiExercise: values.exerciseType.value === "Multi Option" ? true : false,

      exerciseId: values.exerciseId[0]?.value ?? null,
      exerciseId2: values.exerciseType.value === "Multi Option" ? values.exerciseId[1]?.value ?? null : null,
      exerciseId3: values.exerciseType.value === "Multi Option" ? values.exerciseId[2]?.value ?? null : null,
      exerciseId4: values.exerciseType.value === "Multi Option" ? values.exerciseId[3]?.value ?? null : null,
      exerciseId5: values.exerciseType.value === "Multi Option" ? values.exerciseId[4]?.value ?? null : null,
      sets: values.sets,
      reps: values.reps,
      weight: values.weight,
      intensity: values.intensity.value,
      intensityValue: values.intensityValue,
      comment: values.comment ?? "",
      itemName: values.itemName,
      showE1RM: values.e1rmvl.value === 2 || values.e1rmvl.value === 4 ? true : false,
      showVolumeLoad: values.e1rmvl.value === 3 || values.e1rmvl.value === 4 ? true : false,
    };

    values.exerciseProperties = exerciseProperties;
    values.intensity = values.intensity.value;
    values.exerciseId =
      values.exerciseType.value === "Single Option" ? values.exerciseId.value : values.exerciseId[0]?.value;

    let res;
    try {
      if (planOrProgram.current === actionType.plan) {
        res = await update(
          `/users/${userId.current}/plans/${trainingPlanId.current}/weeks/${microcycleId.current}/days/${selectDay.id}/exercises/${editDefaultValue.current.id}`,
          values
        );
      } else if (planOrProgram.current === actionType.program) {
        res = await update(`/template-plan-items/${editDefaultValue.current.id}`, values);
      }
      if (res.id || res.success) {
        handleEditExerciseSubmit(values, editDefaultValue.current.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowEditExerciseForm(false);
      isSubmitting.current = false;
    }
  };

  const deleteExerciseSubmit = async () => {
    let res;
    try {
      if (planOrProgram.current === actionType.plan) {
        res = await remove(
          `/users/${userId.current}/plans/${trainingPlanId.current}/weeks/${microcycleId.current}/days/${selectDay.id}/exercises/${dayExerciseId.current}`
        );
      } else if (planOrProgram.current === actionType.program) {
        res = await remove(`/template-plan-items/${dayExerciseId.current}`);
      }
      if (res.success) {
        handleDeleteExerciseSubmit(dayExerciseId.current);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowDeleteExerciseConfirmationModal(false);
      isSubmitting.current = false;
    }
  };

  const AddExerciseButton = ({ type, values }) => (
    <Button className="microcycle-header-group" onClick={() => handleAddExercisePress({ type, values })}>
      Add Exercise
    </Button>
  );

  const EditExerciseButton = ({ type, values }) => (
    <button className="btn" onClick={() => handleEditExercisePress({ type, values })}>
      <img src="/editIcon.png" width="20" height="20" alt="edit" />
    </button>
  );

  const DeleteExerciseButton = ({ type, values }) => (
    <button className="btn round" onClick={() => handleDeleteExercisePress({ type, values })}>
      <img src="/deleteIcon.png" width="20" height="20" alt="edit" />
    </button>
  );

  const AddExerciseModal = () => (
    <FormModal
      show={showAddExerciseForm}
      title="Add Exercise"
      handleCancel={() => setShowAddExerciseForm(false)}
      body={
        <ExerciseForm submit={addExerciseSubmit} handleCancel={() => setShowAddExerciseForm(false)} submitLabel="Save" />
      }
    />
  );

  const EditExerciseModal = () => (
    <FormModal
      show={showEditExerciseForm}
      title="Edit Exercise"
      handleCancel={() => setShowEditExerciseForm(false)}
      body={
        <ExerciseForm
          submit={editExerciseSubmit}
          handleCancel={() => setShowEditExerciseForm(false)}
          submitLabel="Confirm"
          defaultValues={editDefaultValue.current}
        />
      }
    />
  );

  const DeleteExerciseModal = () => (
    <ConfirmationModal
      show={showDeleteExerciseConfirmationModal}
      title="Confirm Exercise Delete"
      body="This action cannot be reversed and will delete any training history for the client for this period."
      handleCancel={() => setShowDeleteExerciseConfirmationModal(false)}
      handleConfirmation={deleteExerciseSubmit}
    />
  );

  return {
    AddExerciseModal,
    AddExerciseButton,
    EditExerciseModal,
    EditExerciseButton,
    DeleteExerciseButton,
    DeleteExerciseModal,
  };
};

export default useManageExercise;
