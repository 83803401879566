import React from "react";

const DaySummaryTable = ({ index, result, classname, day, name }) => {
  return (
    <table className={classname} id={index}>
      <thead>
        <tr>
          <th style={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "1px" }}>
            Day #{day} - {name}
          </th>
          <th className="sri">S</th>
          <th className="sri">R</th>
          <th className="sri">I</th>
        </tr>
      </thead>

      {result.map((e, index) => (
        <tbody key={index}>
          <tr>
            {e.exerciseProperties.isMultiExercise ? (
              <td
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "1px",
                }}
              >
                {e.exerciseProperties.itemName}
              </td>
            ) : (
              <td
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "1px",
                }}
              >
                {e.exerciseProperties.exercise.exerciseName}
              </td>
            )}
            <td className="sri">{e.exerciseProperties.sets}</td>
            <td className="sri">{e.exerciseProperties.reps}</td>
            <td className="sri">{e.exerciseProperties.intensityValue}</td>
          </tr>
        </tbody>
      ))}
    </table>
  );
};

export default DaySummaryTable;
