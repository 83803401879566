import { Button, Modal as BootstrapModal } from "react-bootstrap";

const Modal = ({
  show,
  title,
  body,
  size,
  showFooter,
  submitLabel,
  handleSubmit,
  handleCancel,
  cancelLabel,
  options,
  handleOptionsSelected,
}) => {
  return (
    <>
      <BootstrapModal show={show} size={size} aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered>
        <BootstrapModal.Header closeButton onHide={handleCancel}>
          <BootstrapModal.Title>
            <b>{title}</b>
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {options && (
            <div style={{ borderBottom: "1px solid lightgrey", marginBottom: "15px" }}>
              {options.options.map((option) => {
                if (option === options.default) {
                  return (
                    <span key={option}>
                      <Button
                        variant="outline-grey"
                        style={{
                          border: "1px solid lightgrey",
                          borderBottom: "0px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          color: "#b01212",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          handleOptionsSelected(option);
                        }}
                        active>
                        {option}
                      </Button>{" "}
                    </span>
                  );
                } else {
                  return (
                    <span key={option}>
                      <Button
                        variant="outline-grey"
                        style={{
                          border: "1px solid lightgrey",
                          borderBottom: "0px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          color: "#b01212",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          handleOptionsSelected(option);
                        }}>
                        {option}
                      </Button>{" "}
                    </span>
                  );
                }
              })}
            </div>
          )}
          {body}
        </BootstrapModal.Body>
        {showFooter && (
          <BootstrapModal.Footer>
            {handleCancel !== undefined && (
              <Button style={{ backgroundColor: "gray", borderColor: "gray" }} onClick={handleCancel}>
                {cancelLabel}
              </Button>
            )}
            {handleSubmit !== undefined && <Button onClick={handleSubmit}>{submitLabel}</Button>}
          </BootstrapModal.Footer>
        )}
      </BootstrapModal>
    </>
  );
};

export default Modal;
