import React, { useState } from "react";
import ProgramDatabase from "./ProgramDatabase";
import { Container } from "react-bootstrap";
import useGetFetch from "../../utilities/useFetch";
// import { Funnel } from "react-bootstrap-icons";

const TrainingPlanDb = () => {
  const { data: plans, setRefresh: refreshPlans } = useGetFetch("/template-plans?includeFullDetails=true");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [plansResults, setPlansResults] = useState();

  const handleSearch = (event) => {
    // Stop existing timer
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const keyword = event.target.value.toLowerCase();

    const timeoutFunction = setTimeout(() => {
      updateClients(keyword);
    }, 500);

    setSearchTimeout(timeoutFunction);
  };

  const updateClients = async (searchStr) => {
    // Stop existing timer
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchStr && searchStr !== "") {
      const searchResults = plans.results.filter((p) => p.name.toLowerCase().includes(searchStr));
      setPlansResults(searchResults);
    } else {
      setPlansResults(plans.results);
    }
  };

  const refreshSearchAfterDelete = (id) => {
    const searchResults = plansResults.filter((p) => p.id !== id);
    setPlansResults(searchResults);
  };

  return (
    <Container fluid className="program-main-container">
      <div style={{ width: "300px", marginTop: "10px", marginBottom: "40px" }}>
        {/* <SearchBox programList={plans && plans.results} /> */}

        <div style={{ display: "flex" }}>
          {/* name-list is from TrainingPlanBoard.scss */}
          <div className="name-list" style={{ width: "250px" }}>
            <input
              type="text"
              className="form-control search-program-input"
              placeholder="SEARCH PROGRAMS"
              onChange={(event) => handleSearch(event)}
              style={{ backgroundColor: "#d9d9d9" }}
            />
          </div>
          {/* <Funnel
            style={{
              backgroundColor: "#434343",
              color: "white",
              padding: "5px 8px",
              fontSize: "38px",
              borderRadius: "5px",
              marginLeft: "5px",
            }}
          /> */}
        </div>
        {/* <div style={{ color: "#595959" }}>Current filters applied</div> */}
      </div>
      {plans && (
        <ProgramDatabase
          plans={plansResults ? plansResults : plans.results}
          refreshPlans={refreshPlans}
          refreshSearch={refreshSearchAfterDelete}
          isSearch={plansResults ? true : false}
        />
      )}
    </Container>
  );
};

export default TrainingPlanDb;
