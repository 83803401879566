import Modal from "./Modal";

const ConfirmationModal = ({ show, title, body, handleConfirmation, handleCancel }) => {
    /**
     * To use this component, this is the sample code to use

        import { useState } from "react";
        import ConfirmationModal from "../modal/Modal";

        const [show, setShow] = useState(false);
        const handleModalCancel = () => setShow(false);
        const handleModalSubmit = () => {
            // Put your confirmation logic here
            setShow(false); // To close the modal
        }

        const showModal = () => {
            // Put your logic here before showing the modal
            // For e.g. saving the id of an item you want to delete but will need
            // confirmation from the user
            setShow(true); // To show the modal
        }

        // then in the render, put this outside the main body of your html

        <>
            <ConfirmationModal
                show={show}
                title="This is the title"
                body="This is the body"
                handleCancel={handleModalCancel}
                handleConfirmation={handleModalSubmit}
            />
            <div>
                Rest of the code
                <Button onClick={() => showModal()}>This button will set the show state to true to display modal</Button>
            </div>
        </>
     */

    return (
        <>
            <Modal
                show={show}
                title={title}
                body={body}
                size="lg"
                submitLabel="Confirm"
                handleCancel={handleCancel}
                cancelLabel="Cancel"
                handleSubmit={handleConfirmation}
                showFooter={true}
            />
        </>
    );
};

export default ConfirmationModal;
