import React from "react";
import { Col, Row } from "react-bootstrap";
import MesoMicroButton from "../button/MesoMicroButton";

const MicrocycleList = ({ data, nextPageBaseUrl, mesocycleName, reverseOrder }) => {
  const colSpans = {
    xs: 1,
    md: 12,
    lg: 2,
    xl: 3,
    xxl: 3,
  };

  return (
    <>
      <h4
        className="font-anton"
        style={{
          textAlign: "left",
          alignItems: "center",
          color: "#b01212",
          display: "block",
        }}>
        MICROCYCLES
      </h4>
      <Row {...colSpans} className="g-3">
        {data.map((cycle, index) => (
          <Col className="client-meso-micro-view-button">
            <MesoMicroButton
              title={"Microcycle " + (reverseOrder ? data.length - index : index + 1)}
              active={false}
              link={`${nextPageBaseUrl}/${cycle.id}/${index + 1}?mesocycleName=${mesocycleName}&viewAll=false`}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default MicrocycleList;
