import React from "react";
import { HeartFill } from "react-bootstrap-icons";

const NameCard = (props) => {
  const { title, showMicrocycles, showPhaseSummary, id, active, getMicroInfo } = props;

  return (
    <div
      className="phase-name"
      onClick={() => {
        if (showMicrocycles && showPhaseSummary) {
          showMicrocycles(id);
          showPhaseSummary(id);
        } else if (getMicroInfo) {
          let name = title.split(" ")[1];
          getMicroInfo(id, name, active);
        }
      }}
      id={id}
    >
      <div style={{ marginRight: 5 }}>
        {title} &nbsp;
        <span>{active && <HeartFill className="active-heart" />}</span>
      </div>

      <div>&#x276F;</div>
    </div>
  );
};

export default NameCard;
