import React from "react";
import Modal from "react-bootstrap/Modal";
import MicroSummary from "../../micro-summary-table/MicroSummaryTable";
import DayDetail from "../../day-detail/DayDetail";
import ProgramModal from "./ProgramModal";

const SummaryModal = (props) => {
  const { show, info, handleSubmit, handleCancel, handleDelete, handleEdit, submitLabel, size, cancelLabel } = props;

  return (
    <ProgramModal
      show={show}
      info={
        info && {
          name: info.name,
          duration: info.weeks ? info.weeks.length : 0,
          sessions: info.weeks ? info.weeks.sessions : 0,
          createdBy: info.owner && info.owner.firstName + " " + info.owner.lastName,
        }
      }
      handleCancel={handleCancel}
      handleSubmit={() => {
        handleSubmit(info.id);
      }}
      submitLabel={submitLabel}
      handleDelete={() => {
        handleDelete(info.id);
      }}
      size={size}
      cancelLabel={cancelLabel}
      handleEdit={handleEdit}
    >
      <Modal.Body className="summary-modal-body" style={{ padding: "0px", overflowX: "hidden", boxSizing: "border-box" }}>
        {info.weeks &&
          info.weeks.map((m, i) => {
            return (
              <>
                <div className="micro-with-day">
                  <div className="micro-container">
                    <MicroSummary
                      muscleGroupSets={m.muscleGroupSetsSummary}
                      isPending={false}
                      header={`Micro ${i + 1}`}
                      className={"summary-in-modal"}
                    />
                  </div>
                  <div style={{ paddingTop: "12px", display: "flex", paddingBottom: "10px", paddingLeft: "5px" }}>
                    {m.days &&
                      m.days.map((d, i) => {
                        return <DayDetail header={`Day${d.position}: ${d.name}`} info={d.items && d.items} />;
                      })}
                  </div>
                </div>
                <hr style={{ minWidth: "100vw" }} />
              </>
            );
          })}
      </Modal.Body>
      <div className="phase-info-group">
        <div className="phase-info-left">
          <div className="phase-info phase-info-first-line">
            <div>
              <b>Phase Name: </b>
              <span>{info.name}</span>
            </div>
            <div>
              <b>Coachwise auto: </b>
              <span>{info.coachwiseAuto ? "Yes" : "No"}</span>
            </div>
          </div>
          <div className="phase-info">
            <b>Training Plan Level: </b>
            <span>{info.level}</span>
          </div>
          <div className="phase-info">
            <b>Training Plan Goal: </b>
            <span>{info.goal}</span>
          </div>
          <div className="phase-info">
            <b>Tags: </b>
            {info.tags?.map((t) => {
              return <span className="tag">#{t.tag}</span>;
            })}
          </div>
        </div>
        <div className="divide"></div>
        <div className="phase-info-right">
          <b>Plan Description: </b>
          <span>{info.about}</span>
        </div>
      </div>
    </ProgramModal>
  );
};

export default SummaryModal;
