import Modal from "./Modal";

const InfoModal = ({ show, title, size, cancelLabel, body, handleCancel }) => {
    return (
        <>
            <Modal
                show={show}
                title={title}
                body={body}
                size={size}
                cancelLabel={cancelLabel}
                handleCancel={handleCancel}
                showFooter={true}
            />
        </>
    );
};

export default InfoModal;
