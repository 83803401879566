import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clientInfo: {},
    activePhase: [],
    activeMicro: [],
    nextDay: {},
  },
  reducers: {
    selectClient: (state, action) => {
      state.clientInfo = action.payload;
    },
    saveActivePhase: (state, action) => {
      state.activePhase = action.payload;
    },
    saveActiveMicro: (state, action) => {
      state.activeMicro = action.payload;
    },
    saveNextDay: (state, action) => {
      state.nextDay = action.payload;
    },
  },
});

export const { selectClient, saveActivePhase, saveActiveMicro, saveNextDay } = clientSlice.actions;

export default clientSlice.reducer;
