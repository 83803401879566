import { useRef, useState } from "react";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { create } from "../../utilities/api";
import { Button } from "react-bootstrap";

const accountAction = {
  disable: "disable",
  enable: "enable",
};

const useAccountStatus = (clients, setClients) => {
  const [show, setShow] = useState(false);

  const title = useRef("");
  const body = useRef("");
  const action = useRef(null); // Whether we want to "enable" or "disable" account
  const userId = useRef(null);
  const isSubmitting = useRef(false);

  const enableAccount = (name) => {
    title.current = "Enable Account";
    body.current = `Are you sure you want to enable "${name}" account?`;
    action.current = accountAction.enable;
    setShow(true);
  };

  const disableAccount = (name) => {
    title.current = "Disable Account";
    body.current = `Are you sure you want to disable "${name}" account?`;
    action.current = accountAction.disable;
    setShow(true);
  };

  const changeAccountAction = (id, name, isDisabled) => {
    userId.current = id;

    if (isDisabled) {
      enableAccount(name);
    } else {
      disableAccount(name);
    }
  };

  const handleConfirm = async () => {
    if (isSubmitting.current) return; // Prevent spam

    // setIsSubmitting(true);
    isSubmitting.current = true;

    try {
      await create(`/users/${userId.current}/${action.current}`);

      setClients([
        ...clients.map((client) => {
          if (client.id === userId.current) {
            client.disabled = !client.disabled;
          }

          return client;
        }),
      ]);

      setShow(false);
    } catch (e) {
    } finally {
      isSubmitting.current = false;
    }
  };

  const AccountStatusButton = ({ userId, userName, isUserAccountDisabled }) => (
    <Button className="ms-2" onClick={() => changeAccountAction(userId, userName, isUserAccountDisabled)}>
      {isUserAccountDisabled ? "Enable Account" : "Disable Account"}
    </Button>
  );

  const AccountStatusModal = () => (
    <ConfirmationModal
      show={show}
      title={title.current}
      body={body.current}
      handleCancel={() => setShow(false)}
      handleConfirmation={() => handleConfirm()}
    />
  );

  return { AccountStatusModal, AccountStatusButton };
};

export default useAccountStatus;
