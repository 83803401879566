import React from "react";

const MicroSummaryTable = (props) => {
  const { summary, isPending, header, children, className, muscleGroupSets } = props;

  const setSummary = (data) => {
    const muscleGroupsSetsTotal = new Map();
    const htmlResults = [];
    if (data) {
      for (const r of data.results) {
        for (const i of r.exercises) {
          if (muscleGroupsSetsTotal.has(i.muscleGroupName)) {
            muscleGroupsSetsTotal.set(i.muscleGroupName, i.sets + muscleGroupsSetsTotal.get(i.muscleGroupName));
          } else {
            muscleGroupsSetsTotal.set(i.muscleGroupName, i.sets);
          }
        }
      }

      const setSort = new Map([...muscleGroupsSetsTotal.entries()].sort((a, b) => b[1] - a[1]));

      for (const j of setSort) {
        htmlResults.push(
          <tr key={j[0]}>
            <td>{j[0]}</td>
            <td>{j[1]}</td>
          </tr>
        );
      }
    }
    return htmlResults;
  };

  const setMuscleGroupSets = (muscleGroupSets) => {
    const htmlResults = [];
    for (const j of muscleGroupSets) {
      htmlResults.push(
        <tr key={j.muscleGroup}>
          <td>{j.muscleGroup}</td>
          <td>{j.sets}</td>
        </tr>
      );
    }
    return htmlResults;
  };

  return (
    <div className={`micro-summary-container round ${className}`}>
      <div className={`subtable-header round`}>{header}</div>
      <div className={`summary-page round`}>
        <b>Total sets</b>
        <div className="summary-content">
          <table>
            <thead>
              <tr>
                <td>
                  <b>Muscle Groups</b>
                </td>
                <td>
                  <b>Sets</b>
                </td>
              </tr>
            </thead>
            <tbody>{!isPending && summary && setSummary(summary)}</tbody>
            <tbody>{muscleGroupSets && setMuscleGroupSets(muscleGroupSets)}</tbody>
          </table>
        </div>
        {children}
      </div>
    </div>
  );
};

export default MicroSummaryTable;
