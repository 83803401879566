import React, { useCallback, useState } from "react";
import { ArrowRightCircleFill, ArrowLeftCircleFill } from "react-bootstrap-icons";
import "./Sidebar.scss";
import { Button, Nav } from "react-bootstrap";
import NavLink from "react-router-dom/NavLink";
import { useDispatch, useSelector } from "react-redux";
import ClientDropDown from "../client-dropdown/ClientDropDown";
import { selectClient } from "../../redux/clientSlice";
import { get, update } from "../../utilities/api";
import { changeCoachSchema } from "../../schemas/schemas";
import FormModal from "../modal/FormModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Sidebar(props) {
  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  const contentClass = props.isOpen ? "content open flex-column" : "content flex-column";
  const dispatch = useDispatch();
  const history = useHistory();
  const [coachSchema, setCoachSchema] = useState([]);
  const [showChangeCoach, setShowChangeCoach] = useState(false);

  const client = useSelector((state) => state.client.clientInfo);
  const activePlan = useSelector((state) => state.client.activePhase);
  const activeMicro = useSelector((state) => state.client.activeMicro);
  const nextDay = useSelector((state) => state.client.nextDay);

  const constructCoachSchema = useCallback(async () => {
    const userData = await get("/users", false, true);
    const tempCoaches = [];
    for (const d of userData.results) {
      if (d.type !== "Client") {
        tempCoaches.push(d);
      }
    }
    const options = tempCoaches.map((t) => ({
      value: t.id,
      label: t.firstName + " " + t.lastName,
    }));
    let currentCoach;
    if (client && client.coaches && client.coaches.length > 0) {
      currentCoach = client.coaches[0].firstName + " " + client.coaches[0].lastName;
    }

    setCoachSchema(changeCoachSchema(currentCoach, options));
  }, [client]);

  const changeCoachHandler = () => {
    constructCoachSchema();
    setShowChangeCoach(true);
  };

  const handleSelect = async (id) => {
    const data = await get("/users/" + id);
    dispatch(selectClient(data));
  };

  const handleChangeCoachSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const newData = Object.assign({}, client);
    newData.goal = client.goal === null ? "" : client.goal;
    newData.coaches = [{ id: parseInt(values.coach) }];

    update("/users/" + client.id, newData)
      .then((res) => {
        setShowChangeCoach(false);
        history.push("/client/select-client");
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <div className={sidebarClass}>
      <FormModal
        formInputsSchema={coachSchema}
        show={showChangeCoach}
        title="Change Coach"
        submitLabel="Confirm"
        handleCancel={() => setShowChangeCoach(false)}
        handleSubmit={handleChangeCoachSubmit}
      />
      <Nav variant="pills" className={contentClass}>
        {client && (
          <>
            <div style={{ width: "260px" }}>
              <ClientDropDown
                selectedOption={{
                  value: { id: client.id, name: client.firstName + " " + client.lastName },
                  label: client.firstName + " " + client.lastName,
                }}
                isPlan={true}
                userId={client.id}
                handleSelect={handleSelect}
              />
            </div>
            <table className="profile-table">
              <tbody>
                <tr>
                  <td>
                    <b>Goal</b>
                  </td>
                  {client.goal ? <td>{client.goal}</td> : <td>--</td>}
                </tr>
                <tr>
                  <td>
                    <b>Phase</b>
                  </td>
                  {activePlan.length > 0 ? <td>{activePlan[0].name}</td> : <td>--</td>}
                </tr>
                <tr>
                  <td>
                    <b>Micro</b>
                  </td>
                  {activeMicro.length > 0 ? <td>{activeMicro[0].position}</td> : <td>--</td>}
                </tr>
                <tr>
                  <td>
                    <b>Up Next</b>
                  </td>
                  {nextDay ? <td>{nextDay.name}</td> : <td>--</td>}
                </tr>
                <tr>
                  <td>
                    <b>Coach</b>
                  </td>
                  {client.coaches && client.coaches.length > 0 ? (
                    <td>{client.coaches[0].firstName + " " + client.coaches[0].lastName}</td>
                  ) : (
                    <td>--</td>
                  )}
                </tr>
              </tbody>
            </table>
            <Button className="change-coach-btn" variant="secondary" onClick={changeCoachHandler}>
              Change coach
            </Button>
          </>
        )}
        <Nav.Item>
          <Nav.Link className="sidebar-nav" as={NavLink} strict to={`/client/${client.id}/training-plans`}>
            TRAINING PLAN
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="sidebar-nav" as={NavLink} strict to={`/client/${client.id}/training-history`}>
            TRAINING HISTORY
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {props.isOpen ? (
        <ArrowLeftCircleFill onClick={props.toggleSidebar} className="sidebar-toggle" />
      ) : (
        <ArrowRightCircleFill onClick={props.toggleSidebar} className="sidebar-toggle" />
      )}
    </div>
  );
}

export default Sidebar;
