import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const MesoMicroButton = ({ title, subtitle, active, link }) => {
    return (
        <>
            <Card className="meso-micro-card" as={Link} to={link} bg={active ? "primary" : "dark"} text="white">
                <Card.Body>
                    <Card.Title className="font-anton">{title}</Card.Title>
                    <Card.Text>{subtitle}</Card.Text>
                </Card.Body>
            </Card>
        </>
    );
};

export default MesoMicroButton;
