import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import Dashboard from "./components/dashboard/Dashboard";
import Exercises from "./components/exercises/Exercises";
import AddExercise from "./components/exercises/AddExercise";
import EditExercise from "./components/exercises/EditExercise";
import TopNavbar from "./components/top-navbar/TopNavbar";
import Clients from "./components/users/Clients";
import Coaches from "./components/users/Coaches";
import EditCoach from "./components/users/EditCoach";
import EditClient from "./components/users/EditClient";
import MyAccount from "./components/my-account/MyAccount";
import ResetPassword from "./components/resetpassword/ResetPassword";
import EditMyAccount from "./components/edit-my-account/EditMyAccount";
import CreateUser from "./components/create-user/CreateUser";
import TrainingPlan from "./components/training-plan-board/TrainingPlan";
import Client from "./components/client/Client";
import TrainingPlanDb from "./components/training-plan-db/TrainingPlanDb";
import Login from "./components/signin/Login";
import InitialPasswordChange from "./components/initial-password-change/InitialPasswordChange";
import { auth as firebaseAuth } from "./firebase";
import { get } from "./utilities/api";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ProgramRoute from "./components/program-route/ProgramRoute";
import OrganisationDeactivatedMsgPage from "./pages/misc/OrganisationDeactivatedMsgPage";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [isOrganisationDeactivated, setIsOrganisationDeactivated] = useState(false);

  useEffect(() => {
    // firebase listener
    firebaseAuth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
        const me = await get("/me", false);

        if (me.organisations && me.organisations[0]?.isActive === false) {
          setIsOrganisationDeactivated(true);
        } else {
          setIsOrganisationDeactivated(false);
        }

        setFirstTimeLogin(me.firstTimeLogin);
        setIsLoading(false);
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    });
  }, []);

  const PrivateRoute = ({ ...props }) => {
    if (isAuthenticated) {
      if (isOrganisationDeactivated) {
        return <OrganisationDeactivatedMsgPage />;
      } else if (firstTimeLogin) {
        return <InitialPasswordChange />;
      } else {
        return (
          <>
            <TopNavbar />
            <Route {...props} />
          </>
        );
      }
    } else {
      return <Redirect to="/login" />;
    }
  };

  return (
    <>
      {!isLoading && (
        <Router>
          <div className="App">
            <ToastContainer />
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <PrivateRoute path="/account/reset-password" component={ResetPassword} />
              <PrivateRoute path="/account/edit" component={EditMyAccount} />
              <PrivateRoute path="/account" component={MyAccount} />
              <PrivateRoute path="/exercises/create" component={AddExercise} />
              <PrivateRoute path="/exercises/:id" component={EditExercise} />
              <PrivateRoute path="/exercises" component={Exercises} />
              <PrivateRoute path="/users/:type/create" component={CreateUser} />
              <PrivateRoute path="/users/clients/:id" component={EditClient} />
              <PrivateRoute path="/users/clients" component={Clients} />
              <PrivateRoute path="/users/coaches/:id" component={EditCoach} />
              <PrivateRoute path="/users/coaches" component={Coaches} />
              <PrivateRoute path="/client/select-client" component={TrainingPlan} />
              <PrivateRoute path="/client" component={Client} />
              <PrivateRoute path="/programs" component={TrainingPlanDb} />
              <PrivateRoute path="/program" component={ProgramRoute} />
              <PrivateRoute path="/" component={Dashboard} />
            </Switch>
          </div>
        </Router>
      )}
    </>
  );
};

export default App;
