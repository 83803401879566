import React, { useState } from "react";
import { useParams } from "react-router";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../../modal/ConfirmationModal";
import { remove } from "../../../utilities/api";
import { useHistory } from "react-router-dom";
import MicroSummaryTable from "../../micro-summary-table/MicroSummaryTable";

const MicroSummary = (props) => {
  const { user_id, training_plan_id, microcycle_id, template_week_id } = useParams();
  const { summary, isPending, muscleGroupSets, type } = props;
  const [showDeleteMicrocycleConfirmationModal, setShowDeleteMicrocycleConfirmationModal] = useState(false);
  const history = useHistory();

  const handleModalCancel = () => {
    setShowDeleteMicrocycleConfirmationModal(false);
  };

  const handleDeleteMicrocycleSubmit = () => {
    if (type === "plan") {
      remove(`/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}`)
        .then(() => {
          setShowDeleteMicrocycleConfirmationModal(false);
          history.replace(`/client/${user_id}/training-plans`);
        })
        .catch((err) => console.log(err));
    } else if (type === "program") {
      remove(`/template-plan-weeks/${template_week_id}`)
        .then(() => {
          setShowDeleteMicrocycleConfirmationModal(false);
          history.replace(`/programs`);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <ConfirmationModal
        show={showDeleteMicrocycleConfirmationModal}
        title="Confirm Microcycle Delete"
        body="This action cannot be reversed and will delete any training history for the client for this period."
        handleCancel={handleModalCancel}
        handleConfirmation={handleDeleteMicrocycleSubmit}
      />
      <MicroSummaryTable
        isPending={isPending}
        summary={summary}
        muscleGroupSets={muscleGroupSets}
        header={"Micro Summary"}
        className="summary-in-micro"
      >
        <div className="spacer"></div>
        <Button
          variant="primary"
          className="delete-button microcycle-header-group"
          onClick={() => setShowDeleteMicrocycleConfirmationModal(true)}
        >
          Delete Micro
        </Button>
      </MicroSummaryTable>
    </div>
  );
};

export default MicroSummary;
