import React from "react";
import { Col, Row } from "react-bootstrap";
import MesoMicroButton from "../button/MesoMicroButton";

const MesocycleList = ({ data, nextPageBaseUrl, showUpcoming }) => {
  const colSpans = {
    xs: 1,
    md: 12,
    lg: 2,
    xl: 3,
    xxl: 3,
  };

  showUpcoming = showUpcoming ?? false;

  return (
    <>
      <h4 className="font-anton" style={{ color: "#b01212", display: "block", fontSize: "2rem" }}>
        ACTIVE PHASE
      </h4>
      <Row {...colSpans} className="g-3">
        {data.map((phase) => (
          <>
            {phase.active && (
              <Col className="client-meso-micro-view-button">
                <MesoMicroButton
                  title={phase.name}
                  active={true}
                  link={`${nextPageBaseUrl}/${phase.id}?name=${phase.name}`}
                />
              </Col>
            )}
          </>
        ))}
      </Row>
      {showUpcoming && (
        <>
          <hr />
          <h4 className="font-anton" style={{ color: "#b01212", display: "block", fontSize: "2rem" }}>
            UPCOMING PHASES
          </h4>
          <Row {...colSpans} className="g-3">
            {data.map((phase) => (
              <>
                {phase.isUpcoming && (
                  <Col className="client-meso-micro-view-button">
                    <MesoMicroButton
                      title={phase.name}
                      active={false}
                      link={`${nextPageBaseUrl}/${phase.id}?name=${phase.name}`}
                    />
                  </Col>
                )}
              </>
            ))}
          </Row>
        </>
      )}
      <hr />
      <h4 className="font-anton" style={{ color: "#b01212", display: "block", fontSize: "2rem" }}>
        PREVIOUS PHASES
      </h4>
      <Row {...colSpans} className="g-3">
        {data.map((phase) => {
          return (
            <>
              {!phase.active && !phase.isUpcoming && (
                <Col className="client-meso-micro-view-button">
                  <MesoMicroButton
                    title={phase.name}
                    active={false}
                    link={`${nextPageBaseUrl}/${phase.id}?name=${phase.name}`}
                  />
                </Col>
              )}
            </>
          );
        })}
      </Row>
    </>
  );
};

export default MesocycleList;
