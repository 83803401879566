import React, { useState } from "react";
import { useParams } from "react-router";
import { Stack, Table } from "react-bootstrap";
import { priorityOptions, subTypeOptions } from "../../../schemas/schemas";
import useManageExercise from "../../../hooks/microcycle/useManageExercise";

const CurrentDayTable = (props) => {
  const { user_id, training_plan_id, microcycle_id } = useParams();
  const [isHover, setIsHover] = useState(false);

  const {
    selectDay,
    selectDays,
    handleAddExerciseSubmit,
    handleEditExerciseSubmit,
    handleDeleteExerciseSubmit,
    moveExercise,
    type,
  } = props;

  const exercises = selectDay ? (selectDay.exercises ? selectDay.exercises : selectDay.items) : [];
  const position = selectDay ? (selectDay.day ? selectDay.day : selectDay.position) : "";

  const {
    AddExerciseModal,
    AddExerciseButton,
    EditExerciseModal,
    EditExerciseButton,
    DeleteExerciseButton,
    DeleteExerciseModal,
  } = useManageExercise(selectDay, handleAddExerciseSubmit, handleEditExerciseSubmit, handleDeleteExerciseSubmit);

  const handleMouseOver = () => {
    setIsHover(true);
  };

  const handleMouseOut = () => {
    setIsHover(false);
  };

  return (
    <>
      <AddExerciseModal />
      <EditExerciseModal />
      <DeleteExerciseModal />
      <div className="current-day-container round">
        {selectDay && (
          <Stack direction="horizontal" gap={2} className="subtable-header round">
            <span className="me-auto">
              Day # {position} - {selectDay.name}
            </span>
            <AddExerciseButton values={{ user_id, training_plan_id, microcycle_id }} type={type} />
          </Stack>
        )}

        <div className="current-day-table">
          <Table striped hover>
            {exercises && exercises.length > 0 && (
              <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th className="priority"></th>
                  <th className="super-set"></th>
                  <th>Exercise Name</th>
                  <th>Style</th>
                  <th>Sets</th>
                  <th>Reps</th>
                  <th>Load</th>
                  <th>Metric</th>
                  <th>Metric Value</th>
                  <th className="comment">Comment</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
            )}
            <tbody>
              {selectDays &&
                selectDay &&
                selectDays.results
                  .filter((d) => d.id === selectDay.id)
                  .map((r) => {
                    const exercises = r.exercises ? r.exercises : r.items;
                    return exercises.map((e, index) => {
                      if (e.subType === null) e.subType = "Straight Set";
                      return (
                        <tr key={index}>
                          <th style={{ padding: "2px" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              {index !== 0 && (
                                <div className="reorder-btn" onClick={() => moveExercise(e, exercises, index, false)}>
                                  &#x25B2;
                                </div>
                              )}
                              {index !== exercises.length - 1 && (
                                <div className="reorder-btn" onClick={() => moveExercise(e, exercises, index, true)}>
                                  &#x25BC;
                                </div>
                              )}
                            </div>
                          </th>
                          <th>{index + 1}</th>
                          {e.priority !== 0 ? <th>{findPriorityName(e.priority)}</th> : <th></th>}
                          {e.superSet !== "NA" ? <th>{e.superSet}</th> : <th></th>}
                          {e.exerciseProperties.isMultiExercise ? (
                            <th style={{ textAlign: "left" }}>{e.exerciseProperties.itemName}</th>
                          ) : (
                            <th style={{ textAlign: "left" }}>{e.exerciseProperties.exercise.exerciseName}</th>
                          )}
                          <th onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            {!isHover ? findSubSetAbbre(e.subType) : e.subType}
                          </th>
                          <th>{e.exerciseProperties.sets}</th>
                          <th>{e.exerciseProperties.reps}</th>
                          <th>{e.exerciseProperties.weight}</th>
                          <th>{e.exerciseProperties.intensity}</th>
                          <th>{e.exerciseProperties.intensityValue}</th>
                          <th className="comment">{e.exerciseProperties.comment}</th>
                          <th className="table-button-group">
                            <EditExerciseButton
                              values={{ user_id, training_plan_id, microcycle_id, dayExerciseId: e.id, editDefaultValue: e }}
                              type={type}
                            />
                          </th>
                          <th className="table-button-group">
                            <DeleteExerciseButton
                              values={{ user_id, training_plan_id, microcycle_id, dayExerciseId: e.id }}
                              type={type}
                            />
                          </th>
                        </tr>
                      );
                    });
                  })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default CurrentDayTable;

const findPriorityName = (value) => {
  if (value === 0) value = "0";
  let p = priorityOptions.find((p) => p.value === value);
  return p && p.label ? p.label : null;
};

const findSubSetAbbre = (value) => {
  let p = subTypeOptions.find((p) => p.value === value);
  return p && p.abbre ? p.abbre : null;
};
