import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useState } from "react";
import { auth } from "../../firebase";
import { useHistory } from "react-router-dom";
import "./Login.css";
import { get } from "../../utilities/api";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const signInHandler = async (event) => {
    event.preventDefault();

    try {
      setError("");
      await auth.signInWithEmailAndPassword(email, password);

      // Only coach is allowed to log in here
      const meResult = await get("/me", false);

      if (meResult.type === "Client") {
        setError("You don't have permission to access this site.");
        await auth.signOut();
      } else if (meResult.organisations && meResult.organisations[0]?.isActive === false) {
        setError("Organisation deactivated. Contact your gym.");
        await auth.signOut();
      } else {
        history.push("/");
      }
    } catch (error) {
      // Sign out if user is signed in from firebase
      if (auth.currentUser) {
        await auth.signOut();
      }

      console.log("Login Failed.", error);

      if (error.code === "auth/wrong-password") {
        setError("Invalid password");
      } else if (error.code === "auth/user-not-found") {
        setError("Account does not exist");
      } else if (error.code === "auth/user-disabled") {
        setError("This account is disabled");
      } else {
        setError("Something went wrong");
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    const tempValue = value.trim();

    if (name === "userEmail") {
      setEmail(tempValue);
    } else if (name === "userPassword") {
      setPassword(tempValue);
    }
  };

  return (
    <div>
      <Container fluid="md" className="p-1 d-flex justify-content-center">
        <div className="p-1 d-flex justify-content-center">
          <Card className="shadow title-card">
            <Card.Body>
              <h1 className="text-center mb-4 font-anton">JPS Health & Fitness </h1>
              <h2 className="text-center mb-4 anton-font">Personal Training App </h2>
            </Card.Body>
          </Card>
        </div>
      </Container>
      <Container fluid="md">
        <div className="p-1 d-flex justify-content-center">
          <Card className="shadow login-card">
            <Card.Body>
              <h2 className="text-center mb-4 anton-font">Sign In</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={signInHandler}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="anton-font">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="userEmail"
                    value={email}
                    onChange={onChangeHandler}
                    placeholder="Enter email"
                  />
                  <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label className="anton-font">Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="userPassword"
                    value={password}
                    onChange={onChangeHandler}
                    placeholder="Password"
                  />
                </Form.Group>

                <Button className="block-button" variant="primary" type="submit">
                  Sign In
                </Button>

                <a className="forgot-password" href="/forgot-password">
                  Forgot Password?
                </a>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
