import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import useGetFetch from "../../../utilities/useFetch";
import { create, get, update } from "../../../utilities/api";
import FormModal from "../../modal/FormModal";
import ConfirmationModal from "../../modal/ConfirmationModal";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";
import { Heart, HeartFill, TriangleFill } from "react-bootstrap-icons";
import DaySummaryTable from "./DaySummaryTable";
import MicroSummary from "./MicroSummary";
import CurrentDayTable from "./CurrentDayTable";
import Questionnaire from "./Questionnaire";
import ClientDropDown from "../../client-dropdown/ClientDropDown";
import DynamicSelectForm from "../../dynamic-select-form/DynamicSelectForm";
import HistoryTable from "./HistoryTable";
import useManageDay from "../../../hooks/microcycle/useManageDay";

const Microcycle = () => {
  const { user_id, training_plan_id, microcycle_id, microcycle_index } = useParams();
  const { data } = useGetFetch("/users/" + user_id);
  const { data: selectDays, setRefresh: setDaysRefresh } = useGetFetch(
    `/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}/days`
  );
  const { data: summary, isPending, setRefresh: setSumRefresh } = useGetFetch(`/users/${user_id}/weeks/${microcycle_id}`);

  //TODO: add sort by position and sort direction desc on route when having position attribute
  //TODO: refactor option list with new weeks results in dynamicSelectForm.js and HistoryTable.js
  const { data: weeks, setRefresh: weeksSetRefresh } = useGetFetch(`/users/${user_id}/plans/${training_plan_id}/weeks`);
  const { data: exercises } = useGetFetch("/exercises?filters[active]=true");
  const { data: currentCycle, setRefresh: setCycleRefresh } = useGetFetch(
    `/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}`
  );
  const { data: phases } = useGetFetch("/users/" + user_id + "/plans");
  const { data: currentPlan } = useGetFetch("/users/" + user_id + "/plans/" + training_plan_id);

  const [selectDay, setSelectDay] = useState(null);
  const [showMarkMicrocycleActiveModal, setShowMarkMicrocycleActiveModal] = useState(false);
  const history = useHistory();
  const mesocycleName = new URLSearchParams(window.location.search).get("mesocycleName");
  const [optionSelected, setOptionSelected] = useState("Blank");

  const [toggle, setToggle] = useState(new URLSearchParams(window.location.search).get("viewAll"));
  const [sortedWeeks, setSortedWeeks] = useState([]);
  const [show, setShow] = useState(false);
  const { AddDayModal, AddDayButton, EditDayModal, EditDayButton, DeleteDayModal, DeleteDayButton } = useManageDay(
    selectDay,
    setSelectDay,
    setDaysRefresh,
    weeksSetRefresh
  );

  useEffect(() => {
    setSelectDay(null);
  }, [user_id]);

  const getAllWeeksData = async () => {
    try {
      const allWeeksData = await get(
        `/users/${user_id}/plans/${training_plan_id}/weeks?includeDays=true&&includeDayItems=true`
      );

      for (const a of allWeeksData.results) {
        a.trainingPlanDays.sort((a, b) => (a.day > b.day ? 1 : -1));
      }
      setSortedWeeks(allWeeksData.results);
    } catch (err) {
      console.log(err);
    }
  };

  const handleModalCancel = () => {
    setShowMarkMicrocycleActiveModal(false);
    setShow(false);
  };

  const handleAddExerciseSubmit = (values, id) => {
    setDaysRefresh((old) => old + 1);
    setSumRefresh((old) => old + 1);
    weeksSetRefresh((old) => old + 1);
    if (values.exerciseProperties.isMultiExercise) {
      values.exerciseName = values.exerciseProperties.itemName;
    } else {
      let exercise = exercises.results.filter((e) => e.exerciseId === values.exerciseProperties.exerciseId);
      values.exerciseProperties.exercise = {};
      values.exerciseProperties.exercise.exerciseName = exercise[0].exerciseName;
    }
    values.id = id;
    setSelectDay((prevState) => ({ ...prevState, exercises: [...prevState.exercises, values] }));
  };

  const handleEditExerciseSubmit = (values, id) => {
    setDaysRefresh((old) => old + 1);
    setSumRefresh((old) => old + 1);
    weeksSetRefresh((old) => old + 1);
    if (values.exerciseProperties.isMultiExercise) {
      values.exerciseName = values.exerciseProperties.itemName;
    } else {
      let exercise = exercises.results.filter((e) => e.exerciseId === values.exerciseProperties.exerciseId);
      values.exerciseProperties.exercise = {};
      values.exerciseProperties.exercise.exerciseName = exercise[0].exerciseName;
    }

    values.id = id;
    setSelectDay((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.map((el) => (el.id === id || typeof el.id === "undefined" ? values : el)),
    }));
  };

  const handleDeleteExerciseSubmit = (id) => {
    setDaysRefresh((old) => old + 1);
    setSumRefresh((old) => old + 1);
    weeksSetRefresh((old) => old + 1);
    setSelectDay((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.filter((el) => el.id !== id),
    }));
  };

  const handleAddMicroSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission
    try {
      let res = await create("/users/" + user_id + "/plans/" + training_plan_id + "/weeks", values);
      if (res.success) {
        weeksSetRefresh((old) => old + 1);
        setSelectDay(null);
        history.push(
          `/client/${user_id}/training-plans/${training_plan_id}/microcycles/${res.id}/${(
            Number(microcycle_index) + 1
          ).toString()}?mesocycleName=${mesocycleName}&viewAll=false`
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShow(false);
      setSubmitting(false);
    }
  };

  const handleCopySubmit = async (values) => {
    try {
      let responseJson = await create(`/users/${user_id}/plans/${training_plan_id}/weeks/${values.microId}/copy`);

      if (responseJson) {
        weeksSetRefresh((old) => old + 1);
        setSelectDay(null);
        history.push(
          `/client/${user_id}/training-plans/${training_plan_id}/microcycles/${responseJson.id}/${(
            Number(microcycle_index) + 1
          ).toString()}?mesocycleName=${mesocycleName}&viewAll=false`
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShow(false);
    }
  };

  const handleMarkMicrocycleActive = async () => {
    try {
      let responseJson = await create(`/users/${user_id}/plans/${training_plan_id}/weeks/${currentCycle.id}/force-active`);
      if (responseJson.success) {
        setCycleRefresh((old) => old + 1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowMarkMicrocycleActiveModal(false);
    }
  };

  const handleToggle = (isToggle) => {
    setToggle(!isToggle);
    getAllWeeksData();
  };

  const handleSelect = (selectedId, actionPlanId, microId, microName, planName) => {
    handleToggle(false);
    history.replace(
      `/client/${selectedId}/training-plans/${actionPlanId}/microcycles/${microId}/${microName}?mesocycleName=${planName}&viewAll=false`
    );
  };

  const onClickArrowBtn = (isLeft) => {
    let weekid, microId;
    if (isLeft) {
      weekid = weeks.results[Number(microcycle_index) - 2].id;
      microId = (microcycle_index - 1).toString();
    } else {
      weekid = weeks.results[Number(microcycle_index)].id;
      microId = (Number(microcycle_index) + 1).toString();
    }

    setSelectDay(null);
    history.replace(
      `/client/${user_id}/training-plans/${training_plan_id}/microcycles/${weekid}/${microId}?mesocycleName=${mesocycleName}&viewAll=false`
    );
  };

  const moveExercise = (currentExercise, allExercises, index, down) => {
    var p = currentExercise.position;
    if (down && p < allExercises.length - 1) {
      p = currentExercise.position + 1;
    } else if (!down && p !== 0) {
      p = currentExercise.position - 1;
    } else return;

    update(
      `/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}/days/${selectDay.id}/exercises/${currentExercise.id}`,
      { position: p }
    )
      .then(() => {
        setDaysRefresh((old) => old + 1);
      })
      .catch((err) => console.log(err));
  };

  const moveDay = (values, forward) => {
    if (forward) values.day = values.day + 1;
    else if (forward === false) values.day = values.day - 1;
    else return;

    update(`/users/${user_id}/plans/${training_plan_id}/weeks/${microcycle_id}/days/${values.id}`, values)
      .then(() => {
        setDaysRefresh((old) => old + 1);
        weeksSetRefresh((old) => old + 1);
        setSelectDay(values);
      })
      .catch((err) => console.log(err));
  };

  const handleOptionsSelected = (option) => {
    setOptionSelected(option);
  };

  const findOptionsAndMethods = () => {
    if (optionSelected === "Blank") {
      return handleAddMicroSubmit;
    } else if (optionSelected === "Copy") {
      return handleCopySubmit;
    }
    return handleAddMicroSubmit;
  };

  return (
    <div className="microcycle">
      <div className="microcycle-container">
        <AddDayModal />
        <EditDayModal />
        <DeleteDayModal />
        <FormModal
          formInputsSchema={[]}
          show={show}
          title="Add New Microcycle"
          submitLabel="Confirm"
          handleCancel={handleModalCancel}
          handleSubmit={findOptionsAndMethods()}
          options={{ default: optionSelected, options: ["Blank", "Copy"] }}
          handleOptionsSelected={handleOptionsSelected}
          body={
            optionSelected === "Copy" && (
              <DynamicSelectForm
                phases={phases}
                handleCancel={() => setShow(false)}
                handleSubmit={findOptionsAndMethods()}
                currentPlan={currentPlan}
                micros={weeks.results.length > 0 && weeks.results.slice().reverse()}
              />
            )
          }
        />
        <ConfirmationModal
          show={showMarkMicrocycleActiveModal}
          title="Mark This Microcycle as Active?"
          body="This action will mark this microcycle as active. The plan that is set to be next active will also reset."
          handleCancel={handleModalCancel}
          handleConfirmation={handleMarkMicrocycleActive}
        />
        <Container className="microcycle-header-container" fluid>
          <Row>
            <Col xs={12} md={3} lg={4} style={{ padding: 0, width: "250px" }}>
              {data && (
                <ClientDropDown
                  selectedOption={{
                    value: { id: user_id, name: data.firstName + " " + data.lastName },
                    label: data.firstName + " " + data.lastName,
                  }}
                  isPlan={false}
                  handleSelect={handleSelect}
                />
              )}
            </Col>
            <Col xs={1} md={1} lg={2} className="me-auto toggle-group">
              {toggle === false ? (
                <Switch size="small" checked={true} color="error" onChange={() => handleToggle(false)} />
              ) : (
                <Switch size="small" checked={false} onChange={() => handleToggle(true)} />
              )}
              <i className="label">(please select a day!!)</i>
            </Col>
            <Col xs="auto" md="auto" lg="auto" className="column">
              {currentCycle && currentCycle.isActive ? (
                <HeartFill className="active-toggle" />
              ) : (
                <Heart className="active-toggle" onClick={() => setShowMarkMicrocycleActiveModal(true)} />
              )}
              <AddDayButton values={{ user_id, training_plan_id, microcycle_id }} type="plan" />
              {selectDay && <EditDayButton values={{ user_id, training_plan_id, microcycle_id }} type="plan" />}
              <DeleteDayButton values={{ user_id, training_plan_id, microcycle_id }} type="plan" />
              <Questionnaire />
            </Col>
          </Row>
        </Container>

        {toggle ? (
          // default view - just show selected microcycle
          <div className="day-summaries-container">
            <p className="microcycle-id">Microcycle {microcycle_index}</p>
            {selectDay && selectDays && selectDay.day !== 1 && (
              <TriangleFill className="day-reorder-btn-left" onClick={() => moveDay(selectDay, false)} />
            )}
            {selectDay && selectDays && selectDay.day !== selectDays.results.length && (
              <TriangleFill className="day-reorder-btn-right" onClick={() => moveDay(selectDay, true)} />
            )}

            <div className="day-summaries round">
              {selectDays &&
                selectDays.results.map((day) => (
                  <div
                    className="day-card current-summary round untoggle"
                    key={day.id}
                    onClick={() => {
                      var elems = document.querySelector(".day-table.selected");
                      elems && elems.classList.remove("selected");
                      document.getElementById(day.id).classList.add("selected");
                      setSelectDay(day);
                    }}
                  >
                    {selectDay && selectDay.id === day.id ? (
                      <DaySummaryTable
                        classname="day-table selected"
                        index={day.id}
                        result={day.exercises}
                        day={day.day}
                        name={day.name}
                      />
                    ) : (
                      <DaySummaryTable
                        classname="day-table"
                        index={day.id}
                        result={day.exercises}
                        day={day.day}
                        name={day.name}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          //toggle view - show the same day of all microcycles
          <div className="day-summaries-container toggle">
            <div className="day-summaries round">
              {selectDay &&
                sortedWeeks.map((sortedWeek, index) => {
                  return sortedWeek.trainingPlanDays[selectDay.day - 1] ? (
                    <div
                      className="day-card toggle current-summary round"
                      key={index}
                      onClick={() => {
                        const newSelectDay = sortedWeek.trainingPlanDays[selectDay.day - 1];
                        newSelectDay.exercises = newSelectDay.trainingPlanDayItems;
                        setSelectDay(newSelectDay);
                        history.replace(
                          `/client/${user_id}/training-plans/${training_plan_id}/microcycles/${sortedWeek.id}/${
                            index + 1
                          }?mesocycleName=${mesocycleName}&viewAll=true`
                        );
                      }}
                    >
                      <p className="microcycle-id-toggle">Microcycle {index + 1}</p>
                      {selectDay.id === sortedWeek.trainingPlanDays[selectDay.day - 1].id ? (
                        <DaySummaryTable
                          result={selectDay.exercises}
                          index={index}
                          classname="day-table-toggle selected"
                          day={selectDay.day}
                          name={selectDay.name}
                        />
                      ) : (
                        <DaySummaryTable
                          classname="day-table-toggle"
                          index={index}
                          result={sortedWeek.trainingPlanDays[selectDay.day - 1].trainingPlanDayItems}
                          day={sortedWeek.trainingPlanDays[selectDay.day - 1].day}
                          name={sortedWeek.trainingPlanDays[selectDay.day - 1].name}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="day-card current-summary round toggle" key={index}>
                      <p className="microcycle-id-toggle">Microcycle {index + 1}</p>
                      <table className="day-table-toggle" id={index}>
                        <thead>
                          <tr>
                            <th>
                              <div className="exercise"></div>
                            </th>
                            <th className="exercise">S</th>
                            <th className="exercise">R</th>
                            <th className="exercise">I</th>
                          </tr>
                        </thead>
                        <tbody>
                          <i>no training day</i>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {currentPlan && currentCycle && weeks && selectDays && (
          <HistoryTable
            summary={summary}
            phases={phases}
            currentPlan={currentPlan}
            microId={microcycle_id}
            microIndex={microcycle_index}
            micros={weeks}
            selectDays={selectDays}
          />
        )}

        <div className="summary-table-container container">
          <MicroSummary summary={summary} isPending={isPending} type="plan" />
          <CurrentDayTable
            selectDay={selectDay}
            moveExercise={moveExercise}
            selectDays={selectDays}
            handleAddExerciseSubmit={handleAddExerciseSubmit}
            handleEditExerciseSubmit={handleEditExerciseSubmit}
            handleDeleteExerciseSubmit={handleDeleteExerciseSubmit}
            type="plan"
          />
        </div>
      </div>

      {/* left and right arrow buttons, right arrow button will be expanded with add new button and copy button when on latest microcycles*/}
      {toggle && weeks && (
        <>
          {microcycle_index > 1 && (
            <div className="microcycle-select-button left-btn" onClick={() => onClickArrowBtn(true)}>
              &#x276E;
            </div>
          )}
          {microcycle_index < weeks.results.length ? (
            <div className="microcycle-select-button right-btn" onClick={() => onClickArrowBtn(false)}>
              &#x276F;
            </div>
          ) : (
            <div>
              <div className="microcycle-select-button right-btn" onClick={() => setShow(true)}>
                +
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Microcycle;
