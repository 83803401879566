import React, { useState } from "react";
import { Formik, Field } from "formik";
import "react-datetime/css/react-datetime.css";
import { get } from "../../utilities/api";
import { useParams } from "react-router-dom";
import { Form, Col, Row, Button } from "react-bootstrap";

//https://stackoverflow.com/questions/60517777/how-can-i-create-connected-dependent-select-elements-in-formik
const DynamicSelectForm = (props) => {
  const initialValues = {
    phase: props.currentPlan ? [{ value: props.currentPlan.id, label: props.currentPlan.name }] : [{ value: "", label: "" }],
    microcycles: props.micros
      ? [{ value: props.micros[0].id, label: `Microcycle ${props.micros.length}` }]
      : [{ value: 0, label: "No Option" }],
  };

  const { user_id } = useParams();
  const [microList, setMicroList] = useState(
    props.micros
      ? props.micros.map((m, i) => {
          return { value: m.id, label: `Microcycle ${props.micros.length - i}` };
        })
      : [{ value: 0, label: "No Option" }]
  );
  const [phaseError, setPhaseError] = useState("");
  const [microError, setMicroError] = useState("");

  const selectedOptions =
    props.phases &&
    props.phases.results.map((p) => {
      return { value: p.id, label: p.name };
    });

  async function fetchMicrocycle(id, user_id) {
    let res = await get(`/users/${user_id}/plans/${id}/weeks/`);
    if (res.results.length > 0) {
      const selectedOptions = res.results
        .slice()
        .reverse()
        .map((p, i) => {
          return { value: p.id, label: `Microcycle ${res.results.length - i}` };
        });
      return selectedOptions;
    } else {
      return [{ value: 0, label: "No Option" }];
    }
  }

  const handleSubmit = (e, values) => {
    e.preventDefault();
    setPhaseError("");

    if (!values.phase[0].value) setPhaseError("You have to select a phase");
    if (!values.microcycles[0].value) setMicroError("You have to select a microcycle");

    if (values.phase[0].value && values.microcycles[0].value !== 0) {
      props.handleSubmit({
        phaseId: values.phase[0].value,
        microId: values.microcycles[0].value,
      });
    }
  };

  return (
    <div className="App">
      <Formik initialValues={initialValues} enableReinitialize>
        {(p) => {
          const { values, setFieldValue } = p;

          return (
            <form
              className="form-container"
              onSubmit={(e) => {
                handleSubmit(e, values);
              }}
            >
              {props.phases && (
                <Form.Group as={Row} className="p-1">
                  <Form.Label column lg={3} sm={12} htmlFor="phase">
                    Phase
                  </Form.Label>
                  <Col>
                    <Field
                      className="field"
                      id="phase"
                      name="phase"
                      as="select"
                      value={values.phase[0].value}
                      onChange={async (e) => {
                        const { value } = e.target;
                        const _micro = await fetchMicrocycle(value, user_id);
                        setFieldValue("phase", [selectedOptions.find((s) => s.value.toString() === value.toString())]);
                        setFieldValue("microcycles", _micro.length > 0 ? _micro : []);
                        setMicroList(_micro);
                      }}
                    >
                      {selectedOptions.map((r) => (
                        <option key={r.value} value={r.value} style={{ fontSize: "18px" }}>
                          {r.label}
                        </option>
                      ))}
                    </Field>
                    {phaseError && <i style={{ color: "#b01212" }}>{phaseError}</i>}
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="p-1">
                <Form.Label column lg={3} sm={12} htmlFor="microcycle">
                  Microcycle
                </Form.Label>
                {microList.length !== 0 ? (
                  <Col>
                    <Field
                      className="field"
                      value={values.microcycles[0].value}
                      id="microcycles"
                      name="microcycles"
                      as="select"
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("microcycles", [{ value: value, label: "" }]);
                      }}
                    >
                      {microList.length !== 0 &&
                        microList.map((r) => (
                          <option key={r.value} value={r.value} style={{ fontSize: "18px" }}>
                            {r.label}
                          </option>
                        ))}
                    </Field>
                    {microError && <i style={{ color: "#b01212" }}>{microError}</i>}
                  </Col>
                ) : (
                  <Col>
                    <Form.Control
                      className="field"
                      value="No Option"
                      id="microcycles"
                      name="microcycles"
                      disabled={true}
                    ></Form.Control>
                  </Col>
                )}
              </Form.Group>

              <Row>
                <Col lg={3} sm={12}></Col>
                <Col>
                  <Form.Group>
                    <div className="pt-1 ps-1">
                      <Button className="me-1 form-button" type="submit">
                        Submit
                      </Button>
                      {props.handleCancel !== undefined && (
                        <Button style={{ width: "49%" }} onClick={props.handleCancel}>
                          Cancel
                        </Button>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DynamicSelectForm;
