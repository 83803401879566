import { Container, Col, Row, Card } from "react-bootstrap";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import React from "react";
import { create } from "../../utilities/api";
import CustomForm from "../form/CustomForm";
import useGetFetch from "../../utilities/useFetch";

const CreateUser = () => {
  const history = useHistory();
  const pathname = window.location.pathname;

  const { data: coaches, isPending: coachIsPending } = useGetFetch("/users?filters[types][]=Coach&filters[types][]=Admin"); // For creating clients

  const typeOfUser = pathname === "/users/clients/create" ? "Client" : "Coach";
  const title = typeOfUser === "Client" ? "Add new client" : "Add new Coach";
  const redirectedPath = typeOfUser === "Client" ? "/users/clients" : "/users/coaches";

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 7,
      offset: 2,
    },
  };

  const formLabelColumnViewpoint = {
    sm: 12,
    lg: 3,
  };

  const constructFormSchema = () => {
    const results = [
      {
        type: "text",
        name: "firstName",
        label: "First Name:",
        initialValue: "",
        validationSchema: Yup.string().required("Please enter the first name"),
      },
      {
        type: "text",
        name: "lastName",
        label: "Last Name:",
        initialValue: "",
        validationSchema: Yup.string().required("Please enter the last name"),
      },
      {
        type: "text",
        name: "email",
        label: "Email:",
        initialValue: "",
        validationSchema: Yup.string().required("Please enter the email address").email("Invalid email address"),
      },
      {
        type: "select",
        name: "gender",
        label: "Gender:",
        initialValue: "",
        validationSchema: Yup.object().required("Please select the gender"),
        selectOptions: [
          {
            value: "Male",
            label: "Male",
          },
          {
            value: "Female",
            label: "Female",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],
      },
      {
        type: "date",
        name: "dob",
        label: "Date of Birth:",
        initialValue: "",
        validationSchema: Yup.date().typeError("Invalid date was provided").required("Please provide the date of birth"),
      },
      {
        type: "text",
        name: "mobile",
        label: "Mobile:",
        initialValue: "",
        validationSchema: Yup.string()
          .required("Please enter the mobile number")
          .matches(/^[0-9]+$/, "Mobile number must only be in digits")
          .min(10, "Mobile number should be in the format of 04XXXXXXXX")
          .max(10, "Mobile number should be in the format of 04XXXXXXXX"),
      },
    ];

    if (typeOfUser === "Client") {
      const generateCoachList = () => {
        const results = [];
        results.push({ values: "", label: "No Coach" }); // Allow no coach assignment

        coaches.results.forEach((coach) => {
          results.push({ value: coach.id, label: `${coach.firstName} ${coach.lastName}` });
        });

        return results;
      };

      results.push({
        type: "select",
        name: "coach",
        label: "Coach:",
        initialValue: "",
        validationSchema: null,
        selectOptions: generateCoachList(),
      });
    }

    return results;
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    let coaches = [];
    if (values.coach) {
      coaches.push({ id: values.coach });
    }

    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      gender: values.gender,
      dob: values.dob,
      mobile: values.mobile,
      type: typeOfUser,
      coaches: coaches,
    };

    create("/users", data)
      .then((res) => {
        // Handle api success and redirect them
        history.push(redirectedPath);
      })
      .catch((err) => {
        setErrors({ apiError: "There was a problem processing your request" });
        setSubmitting(false);
      });
  };

  return (
    <>
      <Container fluid="md">
        {!coachIsPending && (
          <div className="p-5">
            <Row>
              <Col {...columnsViewpoint}>
                <h1>{title}</h1>
              </Col>
            </Row>
            <Row>
              <Col {...columnsViewpoint}>
                <Card className="shadow">
                  <Card.Body>
                    <CustomForm
                      labelColWidths={formLabelColumnViewpoint}
                      handleSubmit={handleSubmit}
                      formInputsSchema={constructFormSchema()}
                      submitLabel={title}
                      cancelUrl={redirectedPath}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </>
  );
};

export default CreateUser;
