import { Container, Col, Row, Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { update } from "../../utilities/api";
import useGetFetch from "../../utilities/useFetch";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DateUtil from "../../utilities/date";

const EditClient = () => {
  const { id } = useParams();
  const { data: client } = useGetFetch("/users/" + id);
  const { data: coaches } = useGetFetch("/users?filters[types][]=Coach&filters[types][]=Admin");
  const history = useHistory();

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 7,
      offset: 2,
    },
  };

  const formLabelColumnViewpoint = {
    sm: 12,
    md: 3,
  };

  const editUserSchema = Yup.object().shape({
    name: Yup.string().required("Please enter the First name"),
    lastname: Yup.string().required("Please enter the Last name"),
    gender: Yup.string().required("Please select the gender"),
    contact: Yup.number()
      .required("Please enter the mobile number")
      .positive("Invalid mobile number")
      .integer("Invalid mobile number"),
    dob: Yup.date().typeError("Invalid date was provided").required("Please enter the date of birth"),
    height: Yup.number().typeError("Invalid height was provided").positive().integer().nullable(),
    weight: Yup.number().typeError("Invalid weight was provided").positive().nullable(),
    primary_goal: Yup.string().nullable(),
    coach: Yup.string().required("Please select the coach"),
    password: Yup.string().min(8),
  });

  const editClientInitialValues = (client) => {
    let clientsCoach = [];
    if (client.coaches.length === 0) {
      clientsCoach = "";
    } else {
      clientsCoach = client.coaches[0]["id"];
    }
    return {
      name: client.firstName,
      lastname: client.lastName,
      gender: client.gender,
      email: client.email,
      contact: client.mobile,
      dob: DateUtil.fromApi(client.dob),
      height: client.height,
      weight: client.weight,
      primary_goal: client.goal,
      coach: clientsCoach,
    };
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    let coachAssigned = [];
    coachAssigned.push({ id: parseInt(values.coach) });

    const newData = {
      email: values.email,
      firstName: values.name,
      lastName: values.lastname,
      gender: values.gender,
      mobile: values.contact,
      dob: DateUtil.toApi(values.dob),
      height: values.height === "" ? null : values.height,
      weight: values.weight === "" ? null : values.weight,
      goal: values.primary_goal === "" ? "" : values.primary_goal,
      coaches: coachAssigned,
      password: values.password && values.password !== "" ? values.password : undefined,
    };

    update("/users/" + id, newData)
      .then((res) => {
        // Handle api success and redirect them
        history.push("/users/clients");
      })
      .catch((err) => {
        setErrors({ apiError: "There was a problem processing your request" });
        setSubmitting(false);
      });
  };

  const FormInput = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Control
              name={props.name}
              type="text"
              value={props.values[props.name]}
              onChange={props.handleChange}
              isInvalid={error}
            />
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormDate = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <div className={error && "form-control is-invalid p-0"}>
              <Datetime
                name={props.name}
                value={props.values[props.name]}
                timeFormat={false}
                dateFormat={"DD/MM/YYYY"}
                closeOnSelect={true}
                onChange={(value) => {
                  props.handleChange(props.name, value);
                }}
              />
            </div>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormGenderSelect = (props) => {
    const error = props.errors[props.name];

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select name={props.name} value={props.values[props.name]} onChange={props.handleChange} isInvalid={error}>
              <option value="">Select the gender type</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Tther">Other</option>
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const FormCoachSelect = (props) => {
    const error = props.errors[props.name];

    const selectCoach = () => {
      const results = [];

      if (coaches != null) {
        for (const ch of coaches.results) {
          results.push(
            <option value={ch.id}>
              {ch.firstName} {ch.lastName}
            </option>
          );
        }
      }
      return results;
    };

    return (
      <>
        <Form.Group as={Row} className="p-1">
          <Form.Label column {...formLabelColumnViewpoint}>
            {props.label}
          </Form.Label>
          <Col>
            <Form.Select name={props.name} value={props.values[props.name]} onChange={props.handleChange} isInvalid={error}>
              <option value="">Select the coach</option>
              {selectCoach()}
            </Form.Select>
          </Col>
        </Form.Group>
        {error && (
          <Row>
            <Col {...formLabelColumnViewpoint}></Col>
            <Col>
              <div className="d-flex text-danger">{error}</div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <>
      {client && coaches && (
        <Container fluid="md">
          <div className="p-5">
            <Row>
              <Col {...columnsViewpoint}>
                <h1>Edit Client</h1>
              </Col>
            </Row>
            <Row>
              <Col {...columnsViewpoint}>
                <Card className="shadow">
                  <Card.Body>
                    <Formik
                      validateOnChange={false}
                      validationOnBlur={false}
                      initialValues={editClientInitialValues(client)}
                      validationSchema={editUserSchema}
                      onSubmit={(v, { setSubmitting, setErrors }) => handleSubmit(v, { setSubmitting, setErrors })}
                    >
                      {({ handleSubmit, handleChange, setFieldValue, values, errors, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <FormInput
                            label="Email:"
                            name="email"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormInput
                            label="First Name:"
                            name="name"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormInput
                            label="Last Name:"
                            name="lastname"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormGenderSelect
                            label="Gender:"
                            name="gender"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormInput
                            label="Mobile:"
                            name="contact"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormDate
                            label="Date of Birth:"
                            name="dob"
                            values={values}
                            handleChange={setFieldValue}
                            errors={errors}
                          />
                          <FormInput
                            label="Height (cm):"
                            name="height"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormInput
                            label="Weight (kg):"
                            name="weight"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormInput
                            label="Primary Goal:"
                            name="primary_goal"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormCoachSelect
                            label="Coach:"
                            name="coach"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <FormInput
                            label="New Password:"
                            name="password"
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                          />
                          <Row>
                            <Col {...(formLabelColumnViewpoint ?? {})}></Col>
                            <Col>
                              <Form.Group>
                                <div className="pt-1 ps-1">
                                  <Button className="me-1 form-button" type="submit" disabled={isSubmitting}>
                                    Save
                                  </Button>
                                  <Link to={"/users/clients"} className="btn btn-primary form-button">
                                    Cancel
                                  </Link>
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default EditClient;
