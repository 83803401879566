import React from "react";

const DayDetail = (props) => {
  const { header, info } = props;

  return (
    <div className="day-detail-container">
      <div className="header-in-day-detail">{header}</div>
      <div className="detail-page">
        <div className="detail-content">
          <table>
            <thead>
              <tr>
                <td className="exercise-name">
                  <b>Exercise</b>
                </td>
                <td>
                  <b>Sets</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {info &&
                info.length > 0 &&
                info.map((exercise, i) => {
                  return (
                    <tr>
                      {exercise.exerciseProperties.isMultiExercise ? (
                        <td className="exercise-name">{exercise.exerciseProperties.itemName}</td>
                      ) : (
                        <td className="exercise-name">{exercise.exerciseProperties.exercise.exerciseName}</td>
                      )}
                      <td className="sets">{exercise.exerciseProperties.sets}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DayDetail;
