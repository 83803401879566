import { Card, Col, Row, Form, Container, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { update } from "../../utilities/api";
import { Link, useHistory } from "react-router-dom";
import useGetFetch from "../../utilities/useFetch";
import DateUtil from "../../utilities/date";
import Datetime from "react-datetime";

const MyAccount = () => {
  const { data: activeUser } = useGetFetch("/me");
  const history = useHistory();

  const editDetailsSchema = Yup.object().shape({
    mobile: Yup.number()
      .required("Please enter the mobile number")
      .positive("Invalid mobile number")
      .integer("Invalid mobile number"),
    gender: Yup.string().required("Please select the gender"),
    dob: Yup.date().typeError("Invalid date was provided").required("Please enter the date of birth"),
  });

  const editDetailsInitialValues = (activeUser) => {
    return {
      mobile: activeUser.mobile,
      gender: activeUser.gender,
      dob: DateUtil.fromApi(activeUser.dob),
    };
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const newData = {
      mobile: values.mobile,
      dob: DateUtil.toApi(values.dob),
      gender: values.gender,
    };

    update("/me", newData)
      .then((res) => {
        // Handle api success and redirect them
        history.push("/account");
      })
      .catch((err) => {
        setErrors({ apiError: "There was a problem processing your request" });
        setSubmitting(false);
      });
  };

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 7,
      offset: 2,
    },
  };

  return (
    <>
      {activeUser && (
        <Container fluid="md">
          {[activeUser].map((activeUser, index) => (
            <div key={index} className="p-5">
              <Row>
                <Col {...columnsViewpoint}>
                  <h1>Edit details</h1>
                </Col>
              </Row>
              <Row>
                <Col {...columnsViewpoint}>
                  <Card className="shadow" style={{ background: "#fcf5f5" }}>
                    <Card.Body>
                      <Card.Text>
                        <Formik
                          validateOnChange={false}
                          validationOnBlur={false}
                          initialValues={editDetailsInitialValues(activeUser)}
                          validationSchema={editDetailsSchema}
                          onSubmit={(v, { setSubmitting, setErrors }) => handleSubmit(v, { setSubmitting, setErrors })}
                        >
                          {({ handleSubmit, handleChange, setFieldValue, values, errors, isSubmitting }) => (
                            <Form noValidate onSubmit={handleSubmit} style={{ padding: "20px" }}>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label as={Col}>
                                  <h2> {activeUser.firstName + " " + activeUser.lastName} </h2>
                                </Form.Label>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label as={Col}>
                                  <h4> {activeUser.email} </h4>
                                </Form.Label>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label as={Col} sm="3">
                                  <b> Phone No: </b>
                                </Form.Label>
                                <Col>
                                  <Form.Control
                                    name="mobile"
                                    type="text"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    isInvalid={errors.mobile}
                                  />
                                </Col>
                              </Form.Group>
                              {errors.mobile && (
                                <Row>
                                  <Col>
                                    <div className="d-flex justify-content-center text-danger">{errors.mobile}</div>
                                  </Col>
                                </Row>
                              )}
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label as={Col} sm="3">
                                  <b> Gender: </b>
                                </Form.Label>
                                <Col>
                                  <Form.Select
                                    name="gender"
                                    value={values.gender}
                                    onChange={handleChange}
                                    isInvalid={errors.gender}
                                  >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Tther">Other</option>
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Label as={Col} sm="3">
                                  <b> Date of Birth: </b>
                                </Form.Label>
                                <Col>
                                  <Datetime
                                    name="dob"
                                    value={values.dob}
                                    timeFormat={false}
                                    dateFormat={"DD/MM/YYYY"}
                                    closeOnSelect={true}
                                    onChange={(value) => {
                                      setFieldValue("dob", value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                              {errors.dob && <div className="d-flex justify-content-center text-danger">{errors.dob}</div>}
                              <div className="p-1">
                                <Row>
                                  <Col sm="3"></Col>
                                  <Col>
                                    <Form.Group>
                                      <Button
                                        className="me-1 form-button"
                                        variant="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                      >
                                        Save
                                      </Button>
                                      <Link to={"/account"} className="btn btn-primary form-button">
                                        Cancel
                                      </Link>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                              <br />
                            </Form>
                          )}
                        </Formik>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          ))}
        </Container>
      )}
    </>
  );
};

export default MyAccount;
