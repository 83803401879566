import React, { useEffect, useRef, useState } from "react";
import FormModal from "../modal/FormModal";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import MicroSummary from "../training-plan/microcycle/MicroSummary";
import CurrentDayTable from "../training-plan/microcycle/CurrentDayTable";
import useManageDay from "../../hooks/microcycle/useManageDay";
import useGetFetch from "../../utilities/useFetch";
import { TriangleFill } from "react-bootstrap-icons";
import DaySummaryTable from "../training-plan/microcycle/DaySummaryTable";
import { useHistory } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { update, create } from "../../utilities/api";
import { questionnaireSchema } from "../../schemas/schemas";
import DynamicSelectForm from "../dynamic-select-form/DynamicSelectForm";
import SavePlanForm from "../training-plan-board/SavePlanForm";

function EditProgram() {
  const { template_plan_id, template_week_id } = useParams();
  const history = useHistory();
  const [selectDay, setSelectDay] = useState(null);
  const { data: plan, setRefresh: planSetRefresh } = useGetFetch("/template-plans/" + template_plan_id);
  const { data: weeks, setRefresh: weeksSetRefresh } = useGetFetch(
    `/template-plan-weeks?templatePlanId=${template_plan_id}`
  );
  const { data: selectDays, setRefresh: setDaysRefresh } = useGetFetch(
    `/template-plan-days?templatePlanWeekId=${template_week_id}&includeItems=true`
  );
  const { data: exercises } = useGetFetch("/exercises?filters[active]=true");
  const summary = useRef([]);
  const position = useRef([]);
  const [week, setWeek] = useState({});

  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showQuestionnaireForm, setShowQuestionnaireForm] = useState(false);
  const [questionnaireFormSchema, setQuestionnaireFormSchema] = useState([]);
  const [showSavePlanForm, setShowSavePlanForm] = useState(false);
  const [optionSelected, setOptionSelected] = useState("Blank");

  const { AddDayModal, AddDayButton, EditDayModal, EditDayButton, DeleteDayModal, DeleteDayButton } = useManageDay(
    selectDay,
    setSelectDay,
    setDaysRefresh,
    weeksSetRefresh
  );

  useEffect(() => {
    if (plan) {
      const week = plan.weeks.find((w) => w.id === Number(template_week_id));
      summary.current = week && week.muscleGroupSetsSummary;
      position.current = week && week.position;
      setWeek(week);
    }
  }, [plan, template_week_id]);

  const handleAddExerciseSubmit = (values, id) => {
    setDaysRefresh((old) => old + 1);
    planSetRefresh((old) => old + 1);
    weeksSetRefresh((old) => old + 1);
    if (values.exerciseProperties.isMultiExercise) {
      values.exerciseName = values.exerciseProperties.itemName;
    } else {
      let exercise = exercises.results.filter((e) => e.exerciseId === values.exerciseProperties.exerciseId);
      values.exerciseProperties.exercise = {};
      values.exerciseProperties.exercise.exerciseName = exercise[0].exerciseName;
    }
    values.id = id;
    setSelectDay((prevState) => ({ ...prevState, items: [...prevState.items, values] }));
  };

  const handleEditExerciseSubmit = (values, id) => {
    setDaysRefresh((old) => old + 1);
    weeksSetRefresh((old) => old + 1);
    planSetRefresh((old) => old + 1);
    if (values.exerciseProperties.isMultiExercise) {
      values.exerciseName = values.exerciseProperties.itemName;
    } else {
      let exercise = exercises.results.filter((e) => e.exerciseId === values.exerciseProperties.exerciseId);
      values.exerciseProperties.exercise = {};
      values.exerciseProperties.exercise.exerciseName = exercise[0].exerciseName;
    }

    values.id = id;
    setSelectDay((prevState) => ({
      ...prevState,
      items: prevState.items.map((el) => (el.id === id || typeof el.id === "undefined" ? values : el)),
    }));
  };

  const handleDeleteExerciseSubmit = (id) => {
    setDaysRefresh((old) => old + 1);
    planSetRefresh((old) => old + 1);
    weeksSetRefresh((old) => old + 1);
    setSelectDay((prevState) => ({
      ...prevState,
      items: prevState.items.filter((el) => el.id !== id),
    }));
  };

  const moveExercise = (currentExercise, allExercises, index, down) => {
    var p = currentExercise.position;
    if (down && p < allExercises.length) {
      p = currentExercise.position + 1;
    } else if (!down && p !== 1) {
      p = currentExercise.position - 1;
    } else return;

    update(`/template-plan-items/${currentExercise.id}`, { position: p })
      .then(() => {
        setDaysRefresh((old) => old + 1);
      })
      .catch((err) => console.log(err));
  };

  const moveDay = (values, forward) => {
    if (forward) values.position = values.position + 1;
    else if (forward === false) values.position = values.position - 1;
    else return;

    update(`/template-plan-days/${selectDay.id}`, values)
      .then(() => {
        setDaysRefresh((old) => old + 1);
        weeksSetRefresh((old) => old + 1);
        setSelectDay(values);
      })
      .catch((err) => console.log(err));
  };

  const onClickArrowBtn = (isLeft) => {
    let weekId;
    if (isLeft && position.current > 1) {
      const week = plan.weeks.find((w) => w.position === position.current - 1);
      weekId = week && week.id;
    } else {
      const week = plan.weeks.find((w) => w.position === position.current + 1);
      weekId = week && week.id;
    }
    setSelectDay(null);

    history.replace(`/program/${template_plan_id}/microcycles/${weekId}`);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const questionnaireFormDisplayHandler = () => {
    setQuestionnaireFormSchema(questionnaireSchema(week.questionnaire));
    setShowQuestionnaireForm(true);
  };

  const handleQuestionnaireSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const questions = [];
    Object.values(values).forEach((val) => {
      questions.push(val);
    });

    update(`/template-plan-weeks/${template_week_id}`, { questionnaire: questions, position: position.current })
      .then(() => {
        setShowQuestionnaireForm(false);
        planSetRefresh((old) => old + 1);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleOptionsSelected = (option) => {
    setOptionSelected(option);
  };

  const findOptionsAndMethods = () => {
    if (optionSelected === "Blank") {
      return handleAddMicroSubmit;
    } else if (optionSelected === "Copy") {
      return handleCopySubmit;
    }
    return handleAddMicroSubmit;
  };

  const handleAddMicroSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission
    try {
      values.templatePlanId = template_plan_id;
      let res = await create("/template-plan-weeks", values);

      if (res.id) {
        weeksSetRefresh((old) => old + 1);
        planSetRefresh((old) => old + 1);
        setSelectDay(null);
        history.push(`/program/${template_plan_id}/microcycles/${res.id}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShow(false);
      setSubmitting(false);
    }
  };

  const handleCopySubmit = async (values) => {
    try {
      let res = await create(`/template-plan-weeks/${values.microId}/copy`, values);

      if (res.id) {
        weeksSetRefresh((old) => old + 1);
        planSetRefresh((old) => old + 1);
        setSelectDay(null);
        history.push(`/program/${template_plan_id}/microcycles/${res.id}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShow(false);
    }
  };

  const savePlanSubmit = async (values) => {
    const tags = [];

    if (values.tagsList) {
      for (const t of values.tagsList) {
        tags.push(t.label);
      }
    } else if (plan.tags) {
      for (const t of plan.tags) {
        tags.push(t.tag);
      }
    }
    values.level = values.level.value;
    values.goal = values.goal.value;
    values.tags = tags;
    values.trainingPlanId = template_plan_id;
    values.coachwiseAuto = values.auto.value;

    try {
      let res = await update(`/template-plans/${template_plan_id}`, values);

      if (res.id) {
        planSetRefresh((old) => old + 1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowSavePlanForm(false);
    }
  };

  return (
    <div className="microcycle template-plan-edit-container">
      <div className="microcycle-container">
        <AddDayModal />
        <EditDayModal />
        <DeleteDayModal />
        <FormModal
          formInputsSchema={questionnaireFormSchema}
          show={showQuestionnaireForm}
          title="Questionnaire"
          submitLabel="Confirm"
          handleCancel={() => setShowQuestionnaireForm(false)}
          handleSubmit={handleQuestionnaireSubmit}
        />

        <FormModal
          formInputsSchema={[]}
          show={show}
          title="Add New Microcycle"
          submitLabel="Confirm"
          handleCancel={() => setShow(false)}
          handleSubmit={findOptionsAndMethods()}
          options={{ default: optionSelected, options: ["Blank", "Copy"] }}
          handleOptionsSelected={handleOptionsSelected}
          body={
            optionSelected === "Copy" && (
              <DynamicSelectForm
                handleCancel={() => setShow(false)}
                handleSubmit={findOptionsAndMethods()}
                currentPlan={plan}
                micros={weeks.results.length > 0 && weeks.results.slice().reverse()}
              />
            )
          }
        />
        <FormModal
          show={showSavePlanForm}
          title="Save Training Plan"
          submitLabel="Confirm"
          handleCancel={() => setShowSavePlanForm(false)}
          handleSubmit={savePlanSubmit}
          body={
            <SavePlanForm
              submit={savePlanSubmit}
              handleCancel={() => setShowSavePlanForm(false)}
              submitLabel="Submit"
              defaultValues={plan}
            />
          }
        />
        <Container className="microcycle-header-container" fluid>
          <Row>
            <Col xs={12} md={3} lg={4} style={{ padding: 0, width: "250px" }}>
              <div style={{ display: "flex" }}>
                <div className="name-list" style={{ width: "250px" }}>
                  <input
                    type="text"
                    className="form-control search-program-input"
                    value={plan && plan.name}
                    style={{ backgroundColor: "#d9d9d9" }}
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs={1} md={1} lg={2} className="me-auto toggle-group">
              {toggle === true ? (
                <Switch size="small" checked={true} color="error" onChange={() => handleToggle()} />
              ) : (
                <Switch size="small" checked={false} onChange={() => handleToggle()} />
              )}
              <i className="label">(please select a day!!)</i>
            </Col>
            <Col xs="auto" md={1} lg={2} className="">
              <Button className="microcycle-header-group  header-btn-col" onClick={() => setShowSavePlanForm(true)}>
                PLAN DETAIL
              </Button>
            </Col>
            <Col xs="auto" md="auto" lg="auto" className="column">
              <AddDayButton values={{ template_week_id }} type="program" />
              {selectDay && <EditDayButton values={{ template_week_id }} type="program" />}
              <DeleteDayButton values={{ template_week_id }} type="program" />
              <Button
                variant="secondary"
                className="microcycle-header-group header-btn-col"
                onClick={() => questionnaireFormDisplayHandler()}
              >
                Questionnaire
              </Button>
            </Col>
          </Row>
        </Container>

        {!toggle ? (
          // default view - just show selected microcycle
          <div className="day-summaries-container">
            <p className="microcycle-id">Microcycle {position.current}</p>
            {selectDay && selectDays && selectDay.day !== 1 && (
              <TriangleFill className="day-reorder-btn-left" onClick={() => moveDay(selectDay, false)} />
            )}
            {selectDay && selectDays && selectDay.day !== selectDays.results.length && (
              <TriangleFill className="day-reorder-btn-right" onClick={() => moveDay(selectDay, true)} />
            )}

            <div className="day-summaries round">
              {selectDays &&
                selectDays.results.map((day) => (
                  <div
                    className="day-card current-summary round untoggle"
                    key={day.id}
                    onClick={() => {
                      var elems = document.querySelector(".day-table.selected");
                      elems && elems.classList.remove("selected");
                      document.getElementById(day.id).classList.add("selected");
                      setSelectDay(day);
                    }}
                  >
                    {selectDay && selectDay.id === day.id ? (
                      <DaySummaryTable
                        classname="day-table selected"
                        index={day.id}
                        result={day.items}
                        day={day.position}
                        name={day.name}
                      />
                    ) : (
                      <DaySummaryTable
                        classname="day-table"
                        index={day.id}
                        result={day.items}
                        day={day.position}
                        name={day.name}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : (
          //toggle view - show the same day of all microcycles
          <div className="day-summaries-container toggle">
            <div className="day-summaries round">
              {selectDay &&
                plan.weeks.map((sortedWeek, index) => {
                  return sortedWeek.days[selectDay.position - 1] ? (
                    <div
                      className="day-card toggle current-summary round"
                      key={index}
                      onClick={() => {
                        setSelectDay(sortedWeek.days[selectDay.position - 1]);
                        history.replace(`/program/${template_plan_id}/microcycles/${sortedWeek.id}`);
                      }}
                    >
                      <p className="microcycle-id-toggle">Microcycle {sortedWeek.position}</p>
                      {selectDay.id === sortedWeek.days[selectDay.position - 1].id ? (
                        <DaySummaryTable
                          classname="day-table-toggle selected"
                          index={index}
                          result={selectDay.items}
                          day={selectDay.position}
                          name={selectDay.name}
                        />
                      ) : (
                        <DaySummaryTable
                          classname="day-table-toggle"
                          index={index}
                          result={sortedWeek.days[selectDay.position - 1].items}
                          day={sortedWeek.days[selectDay.position - 1].position}
                          name={sortedWeek.days[selectDay.position - 1].name}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="day-card current-summary round toggle" key={index}>
                      <p className="microcycle-id-toggle">Microcycle {sortedWeek.position}</p>
                      <table className="day-table-toggle" id={index}>
                        <thead>
                          <tr>
                            <th>
                              <div className="exercise"></div>
                            </th>
                            <th className="exercise">S</th>
                            <th className="exercise">R</th>
                            <th className="exercise">I</th>
                          </tr>
                        </thead>
                        <tbody>
                          <i>no training day</i>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        <div className="summary-table-container container">
          <MicroSummary muscleGroupSets={summary.current} type="program" />
          <CurrentDayTable
            selectDay={selectDay}
            moveExercise={moveExercise}
            selectDays={selectDays}
            handleAddExerciseSubmit={handleAddExerciseSubmit}
            handleEditExerciseSubmit={handleEditExerciseSubmit}
            handleDeleteExerciseSubmit={handleDeleteExerciseSubmit}
            type="program"
          />
        </div>
      </div>
      {weeks && (
        <>
          {position.current > 1 && (
            <div className="microcycle-select-button left-btn" onClick={() => onClickArrowBtn(true)}>
              &#x276E;
            </div>
          )}
          {position.current < weeks.results.length ? (
            <div className="microcycle-select-button right-btn" onClick={() => onClickArrowBtn(false)}>
              &#x276F;
            </div>
          ) : (
            <div>
              <div className="microcycle-select-button right-btn" onClick={() => setShow(true)}>
                +
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EditProgram;
