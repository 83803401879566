import { Container, Col, Row, Card } from "react-bootstrap";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { update } from "../../utilities/api";
import useGetFetch from "../../utilities/useFetch";
import CustomForm from "../form/CustomForm";

const EditCoach = () => {
  const { id } = useParams();
  const { data: coachdata } = useGetFetch("/users/" + id);
  const history = useHistory();

  const columnsViewpoint = {
    md: {
      span: 10,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 7,
      offset: 2,
    },
  };

  const formLabelColumnViewpoint = {
    sm: 12,
    md: 3,
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true); // Avoid multiple submission

    const newData = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      mobile: values.mobile,
      dob: values.dob,
      type: values.type,
      coaches: [],
    };
    console.log(newData);

    update("/users/" + id, newData)
      .then((res) => {
        // Handle api success and redirect them
        history.push("/users/coaches");
      })
      .catch((err) => {
        setErrors({ apiError: "There was a problem processing your request" });
        setSubmitting(false);
      });
  };

  const generateFormSchema = () => {
    const formSchema = [
      {
        type: "text",
        name: "email",
        label: "Email:",
        initialValue: coachdata.email,
        validationSchema: Yup.string().required("Please enter the email address"),
      },
      {
        type: "text",
        name: "firstName",
        label: "First Name:",
        initialValue: coachdata.firstName,
        validationSchema: Yup.string().required("Please enter the first name"),
      },
      {
        type: "text",
        name: "lastName",
        label: "Last Name:",
        initialValue: coachdata.lastName,
        validationSchema: Yup.string().required("Please enter the last name"),
      },
      {
        type: "select",
        name: "gender",
        label: "Gender:",
        initialValue: coachdata.gender,
        validationSchema: Yup.object().required("Please select the gender"),
        selectOptions: [
          {
            value: "Male",
            label: "Male",
          },
          {
            value: "Female",
            label: "Female",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],
      },
      {
        type: "date",
        name: "dob",
        label: "Date of Birth:",
        initialValue: coachdata.dob,
        validationSchema: Yup.date().typeError("Invalid date was provided").required("Please provide the date of birth"),
      },
      {
        type: "text",
        name: "mobile",
        label: "Mobile:",
        initialValue: coachdata.mobile,
        validationSchema: Yup.number()
          .required("Please enter the mobile number")
          .positive("Invalid mobile number")
          .integer("Invalid mobile number"),
      },
      {
        type: "select",
        name: "type",
        label: "Type:",
        initialValue: coachdata.type,
        validationSchema: Yup.object().required("Please select the type of coach"),
        selectOptions: [
          {
            value: "Admin",
            label: "Admin",
          },
          {
            value: "Coach",
            label: "Coach",
          },
        ],
      },
    ];
    return formSchema;
  };

  return (
    <>
      {coachdata && (
        <Container fluid="md">
          <div className="p-5">
            <Row>
              <Col {...columnsViewpoint}>
                <h1>Edit Coach details</h1>
              </Col>
            </Row>
            <Row>
              <Col {...columnsViewpoint}>
                <Card className="shadow">
                  <Card.Body>
                    <CustomForm
                      labelColWidths={formLabelColumnViewpoint}
                      handleSubmit={handleSubmit}
                      formInputsSchema={generateFormSchema()}
                      submitLabel="Save"
                      cancelUrl="/users/coaches"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default EditCoach;
