import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useGetFetch from "../../utilities/useFetch";
import MicrocycleList from "../microcycle-list/MicrocycleList";

const TrainingHistoryMesocycle = () => {
  const { user_id, plan_id } = useParams();
  const { data, isPending } = useGetFetch(`/users/${user_id}/plans/${plan_id}/weeks?sortDirection=desc`);
  const name = new URLSearchParams(window.location.search).get("name");

  return (
    <>
      <Container fluid="true" className="me-3">
        <div className="pt-3 d-flex">
          <h2 className="font-anton training-plan-mesocycle-title">{name}</h2>
        </div>
        <hr />
        {!isPending && (
          <MicrocycleList
            data={data.results}
            nextPageBaseUrl={`/client/${user_id}/training-history/${plan_id}/microcycle`}
            mesocycleName={name}
            reverseOrder={true}
          />
        )}
      </Container>
    </>
  );
};

export default TrainingHistoryMesocycle;
