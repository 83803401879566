import moment from "moment";

export default class DateUtil {
    static fromApi(date) {
        return moment(date);
    }

    static fromApiToString(date) {
        return moment(date).format("DD/MM/YYYY");
    }

    static toApiFromUnixTimestamp(timestamp) {
        return moment(timestamp).format("YYYY-MM-DD");
    }

    static toApi(date) {
        return moment(date).format("YYYY-MM-DD");
    }

    static calculateDaysFromToday(timestamp) {
        const today = moment();
        return today.diff(this.fromApi(timestamp), "days");
    }
}
