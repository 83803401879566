import { Card, Container, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import React from "react";
import CustomForm from "../form/CustomForm";
import { toast } from "react-toastify";
import { create } from "../../utilities/api";

const ForgotPassword = () => {
    const columnsViewpoint = {
        md: {
            span: 10,
            offset: 1,
        },
        lg: {
            span: 8,
            offset: 2,
        },
        xl: {
            span: 7,
            offset: 2,
        },
    };

    const formLabelColumnViewpoint = {
        sm: 2,
        lg: 2,
    };

    const formSchema = [
        {
            type: "text",
            name: "email",
            label: "Email:",
            initialValue: "",
            validationSchema: Yup.string().required("Please enter the email address").email("Invalid email address"),
        },
    ];

    const handleSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(true); // Avoid multiple submission

        const data = {
            email: values.email,
        };

        create("/send-password-reset", data, true, false)
            .then((res) => {
                toast.info("An email with the password reset link will be sent to you.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSubmitting(false);
            })
            .catch((err) => {
                toast.error("Something went wrong processing this request", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                console.log(err);
                setSubmitting(false);
            });

        setSubmitting(false);
    };

    return (
        <>
            <Container fluid="md">
                <div className="p-5">
                    <Row>
                        <Col {...columnsViewpoint}>
                            <h1>Forgot Password</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col {...columnsViewpoint}>
                            <Card className="shadow">
                                <Card.Body>
                                    <CustomForm
                                        labelColWidths={formLabelColumnViewpoint}
                                        handleSubmit={handleSubmit}
                                        formInputsSchema={formSchema}
                                        submitLabel="Send Email"
                                        cancelUrl="/login"
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default ForgotPassword;
