const state = process.env.REACT_APP_STATE ? process.env.REACT_APP_STATE : "dev";

const settings = {
  dev: {
    state: state,
    apiUrl: "http://localhost:3001",
    firebase: {
      apiKey: "apikey",
      projectId: "jps-x-rmit-app-project",
    },
  },
  prod: {
    state: state,
    apiUrl: "https://api.coachwise.com.au",
    firebase: {
      apiKey: "AIzaSyC4EhzBhgQ99v3QnbfDnqY8wtWXbkqP1ao",
      authDomain: "jps-x-rmit-app-project.firebaseapp.com",
      projectId: "jps-x-rmit-app-project",
      storageBucket: "jps-x-rmit-app-project.appspot.com",
      messagingSenderId: "256038312373",
      appId: "1:256038312373:web:56f10268a6248b8ceaa1fc",
      measurementId: "G-92H07SF0X1",
    },
  },
  rmit: {
    state: state,
    apiUrl: "http://rmit-jps-api.ap-southeast-2.elasticbeanstalk.com",
    firebase: {
      apiKey: "AIzaSyAs_cLED9JzcTqPgci83f44ST_VeFtMEaQ",
      authDomain: "jpsxrmitapp-project-2.firebaseapp.com",
      projectId: "jpsxrmitapp-project-2",
      storageBucket: "jpsxrmitapp-project-2.appspot.com",
      messagingSenderId: "78746529126",
      appId: "1:78746529126:web:72b11295c594a9a4b32ca7",
    },
  },
  staging: {
    state: state,
    apiUrl: "https://api2.coachwise.com.au",
    firebase: {
      apiKey: "AIzaSyC4EhzBhgQ99v3QnbfDnqY8wtWXbkqP1ao",
      authDomain: "jps-x-rmit-app-project.firebaseapp.com",
      projectId: "jps-x-rmit-app-project",
      storageBucket: "jps-x-rmit-app-project.appspot.com",
      messagingSenderId: "256038312373",
      appId: "1:256038312373:web:56f10268a6248b8ceaa1fc",
      measurementId: "G-92H07SF0X1",
    },
  },
};

export default settings[state];
