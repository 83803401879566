import { useRef, useState } from "react";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { create } from "../../utilities/api";
import { Button } from "react-bootstrap";

const actionType = {
  enable: 1,
  disable: 2,
};

const useClientAutoMode = (clients, setClients) => {
  const [show, setShow] = useState(false);

  const title = useRef("");
  const body = useRef("");
  const action = useRef(null); // Whether we want to "enable" or "disable" account
  const userId = useRef(null);
  const isSubmitting = useRef(false);

  const enableAccount = (name) => {
    title.current = "Enable Auto Mode";
    body.current = `Are you sure you want to enable auto mode for ${name}?`;
    action.current = actionType.enable;
    setShow(true);
  };

  const disableAccount = (name) => {
    title.current = "Disable Auto Mode";
    body.current = `Are you sure you want to disable auto mode for ${name}?`;
    action.current = actionType.disable;
    setShow(true);
  };

  const changeAccountAction = (id, name, isClientAutoMode) => {
    userId.current = id;

    if (isClientAutoMode) {
      disableAccount(name);
    } else {
      enableAccount(name);
    }
  };

  const handleConfirm = async () => {
    if (isSubmitting.current) return; // Prevent spam

    // setIsSubmitting(true);
    isSubmitting.current = true;

    try {
      await create(`/users/${userId.current}/client-auto`);

      setClients([
        ...clients.map((client) => {
          if (client.id === userId.current) {
            client.isClientAutoMode = !client.isClientAutoMode;
          }

          return client;
        }),
      ]);

      setShow(false);
    } catch (e) {
    } finally {
      isSubmitting.current = false;
    }
  };

  const ClientAutoStatusButton = ({ userId, userName, isClientAutoMode }) => (
    <Button className="ms-2" onClick={() => changeAccountAction(userId, userName, isClientAutoMode)}>
      {isClientAutoMode ? "Disable Auto Mode" : "Enable Auto Mode"}
    </Button>
  );

  const ClientAutoStatusModal = () => (
    <ConfirmationModal
      show={show}
      title={title.current}
      body={body.current}
      handleCancel={() => setShow(false)}
      handleConfirmation={() => handleConfirm()}
    />
  );

  return { ClientAutoStatusButton, ClientAutoStatusModal };
};

export default useClientAutoMode;
