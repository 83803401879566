import { useState, useEffect } from "react";
import { get } from "./api";

const useGetFetch = (path, showLoading) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    get(path, showLoading)
      .then((data) => {
        setData(data);
        setIsPending(false);
        setError(null);
      })
      .catch((err) => {
        console.log(err);
        setIsPending(false);
        setError(err.message);
      });
  }, [path, refresh, showLoading]);

  return { data, isPending, error, setRefresh, setData };
};

export default useGetFetch;
