import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../sidebar/Sidebar";
import Microcycle from "../training-plan/microcycle/Microcycle";
import TrainingHistory from "../training-history/TrainingHistory";
import TrainingHistoryMesocycle from "../training-history-mesocycle/TrainingHistoryMesocycle";
import TrainingHistoryMicrocycle from "../training-history-microcycle/TrainingHistoryMicrocycle";
import TrainingPlanBoard from "../training-plan-board/TrainingPlanBoard";

const Client = () => {
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const [contentClass, setContentClass] = useState("client-content");
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
    setContentClass(sidebarOpen ? "client-content" : "client-content sidebar-open");
  };
  return (
    <Row className="client-content-container">
      <Sidebar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />
      <Col className={contentClass}>
        <Switch>
          <Route exact path="/client/:user_id/training-plans/:training_plan_id/microcycles/:microcycle_id/:microcycle_index">
            <Microcycle />
          </Route>
          <Route exact path="/client/:user_id/training-plans">
            <TrainingPlanBoard />
          </Route>
          <Route path="/client/:user_id/training-history/:plan_id/microcycle/:microcycle_id">
            <TrainingHistoryMicrocycle />
          </Route>
          <Route path="/client/:user_id/training-history/:plan_id">
            <TrainingHistoryMesocycle />
          </Route>
          <Route path="/client/:user_id/training-history">
            <TrainingHistory />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

export default Client;
